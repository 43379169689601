import moment from 'moment';

import type { ValidationRule } from './validation-rule';

export const regexpValidationRule = (regexp: RegExp, message: string | null = null): ValidationRule => ({
  function: (value: string) => regexp.test(value ?? ''),
  message: message ?? 'Value is incorrect.',
});

export const notNegativeValidationRule = (message: string | null = null): ValidationRule => ({
  function: (value: number) => value >= 0,
  message: message ?? `This field should be only a positive value.`,
});

export const stringLengthValidationRule = (
  from: number | null = null,
  to: number | null = null,
  message: string | null = null,
): ValidationRule => ({
  function: (value: string) => {
    if (from !== null && value.length < from) {
      return false;
    }

    if (to !== null && value.length > to) {
      return false;
    }

    return true;
  },
  message: message ?? `Length should be in range from ${from} to ${to}.`,
});

export const functionValidationRule = (
  func: (value: any) => boolean,
  message: string | null = null,
): ValidationRule => ({
  function: func,
  message: message ?? `Value is incorrect.`,
});

export const requiredValidationRule = (from: number | null = null, message: string | null = null): ValidationRule =>
  stringLengthValidationRule(from ?? 1, null, message ?? 'This field is required.');

export const maxLengthValidationRule = (to: number, message: string | null = null): ValidationRule =>
  stringLengthValidationRule(null, to, message ?? `Max length is ${to}.`);

export const minNumberValidationRule = (from: number, message: string | null = null): ValidationRule => ({
  function: (value: number) => {
    if (value < from) {
      return false;
    }
    return true;
  },
  message: message ?? `Invalid value`,
});

export const maxNumberValidationRule = (to: number, message: string | null = null): ValidationRule => ({
  function: (value: number) => {
    if (value > to) {
      return false;
    }
    return true;
  },
  message: message ?? `Invalid value`,
});

export const numberValidationRule = (message: string | null = null): ValidationRule => ({
  function: (value: number) => {
    if (Number.isNaN(value)) {
      return false;
    }
    return true;
  },
  message: message ?? `Invalid value`,
});

export const dateValidationRule = (message: string | null = null): ValidationRule => ({
  function: (value: string) => {
    if (value === 'Invalid date') {
      return false;
    }
    const data = moment(value).format('MM/DD/YYYY');
    const regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
    return regex.test(data ?? '');
  },
  message: message ?? `Invalid date`,
});

export const futureTimeValidationRule = (
  availableEndOfToday: boolean,
  message: string | null = null,
): ValidationRule => ({
  function: (value: string) => {
    const currentTime = `${moment(new Date()).format('YYYY-MM-DDTHH:mm')}:00.000Z`;
    return availableEndOfToday
      ? moment().endOf('day').isAfter(moment(value))
      : moment(currentTime).isAfter(moment(value));
  },
  message: message ?? `Invalid value`,
});

export const maxDateValidationRule = (maxDate: string, message: string | null = null): ValidationRule => ({
  function: (value: string) => {
    if (moment(value).isSameOrAfter(moment(maxDate))) {
      return false;
    }
    return true;
  },
  message: message ?? `Invalid value`,
});

export const minDateValidationRule = (minDate: string, message: string | null = null): ValidationRule => ({
  function: (value: string) => {
    if (moment(minDate).isAfter(moment(value))) {
      return false;
    }
    return true;
  },
  message: message ?? `Invalid value`,
});
