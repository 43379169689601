import clsx from 'clsx';

import { Box, IconButton } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { WithOptionalTooltip } from './WithOptionalTooltip';
import type { ColumnDefinition, RecordAction, RecordBase } from './types';

export const EXPAND_COLUMN_WIDTH = '62px';
export const ACTION_COLUMN_WIDTH = '110px';

export type Props<RT extends RecordBase, CKT extends string, SFT extends string> = {
  readonly record: RT;
  readonly isExpanded: boolean;
  readonly columns: ReadonlyArray<ColumnDefinition<CKT, SFT, RT>>;
  readonly ExpandedRecordComponent?: React.ComponentType<{ readonly record: RT }>;
  readonly getRecordActions?: (record: RT) => ReadonlyArray<RecordAction<RT>>;
  readonly onToggleExpanded: () => void;
};

export function StandardTableRow<RT extends RecordBase, CKT extends string, SFT extends string>({
  record,
  isExpanded,
  columns,
  ExpandedRecordComponent,
  getRecordActions,
  onToggleExpanded,
}: Props<RT, CKT, SFT>) {
  const isDeleted = 'deleted' in record ? Boolean(record.deleted) : false;

  return (
    <>
      <Box
        key={record.id}
        className={clsx(
          isExpanded ? 'border-y-2 border-main bg-cellHover' : 'border-b-4',
          'flex cursor-pointer hover:bg-cellHover ',
        )}
        data-record-id={record.id}>
        {ExpandedRecordComponent && (
          <Box className='my-auto p-4' style={{ minWidth: EXPAND_COLUMN_WIDTH }}>
            <IconButton aria-label='expand row' size='small' onClick={onToggleExpanded}>
              {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Box>
        )}
        {columns.map(({ key, width, extractCellContents }) => {
          const { primary, secondary } = extractCellContents(record);
          return (
            <Box key={key} className='my-auto break-words p-4' style={{ width }}>
              <WithOptionalTooltip tooltip={primary.tooltip}>
                <Box className={clsx('text-black1', isDeleted && 'italic text-black2 line-through')}>
                  {primary.text}
                </Box>
              </WithOptionalTooltip>
              {secondary && (
                <WithOptionalTooltip tooltip={secondary.tooltip}>
                  <Box className={clsx(isDeleted && 'italic line-through', 'text-black3')}>{secondary.text}</Box>
                </WithOptionalTooltip>
              )}
            </Box>
          );
        })}
        {getRecordActions && (
          <Box className='my-auto flex-1 p-4 text-center' style={{ minWidth: ACTION_COLUMN_WIDTH }}>
            <Box display='flex' alignItems='center' justifyContent='center' gridGap={18}>
              {getRecordActions(record).map(({ id, Icon, colorClassName, handler, tooltip }) => (
                <WithOptionalTooltip key={id} tooltip={tooltip}>
                  <Icon
                    className={`cursor-pointer ${colorClassName || 'text-black5'}`}
                    onClick={
                      handler &&
                      (() => {
                        handler(record);
                      })
                    }
                  />
                </WithOptionalTooltip>
              ))}
            </Box>
          </Box>
        )}
      </Box>
      {ExpandedRecordComponent && isExpanded && (
        <Box className='flex border-b-4'>
          <Box className='my-auto p-4' style={{ minWidth: EXPAND_COLUMN_WIDTH }} />
          <ExpandedRecordComponent record={record} />
        </Box>
      )}
    </>
  );
}
