import { makeStyles } from '@material-ui/core';

import { colors } from 'utils/constants/colors.constant';

export const useStyles = makeStyles(() => ({
  root: {
    borderColor: colors.gray3,
    borderRadius: 4,
    '& .MuiOutlinedInput-root': {
      minHeight: 45,
      borderColor: colors.gray3,
      borderRadius: 4,
      color: colors.black2,
      '&.Mui-focused fieldset': {
        borderColor: colors.main,
      },
      '& .MuiPickersDay-daySelected': {
        backgroundColor: colors.main,
      },
    },
    '& .MuiIconButton-root': {
      '& .MuiPickersDay-daySelected': {
        backgroundColor: colors.main,
      },
    },
  },
}));
