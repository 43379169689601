import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import {
  type AdditionalFilterState,
  MeetingSortField,
  type MeetingsSearchState,
} from 'services/models/meetingTimeEntry.model';
import type { SortColumn } from 'services/models/sorting.model';
import { SortingDirection } from 'services/models/sorting.model';

type Writeable<T> = { -readonly [P in keyof T]: Writeable<T[P]> };
type PracticeCollaborationTrackerState = Readonly<{
  isLoading: boolean;
  isCreateDialogOpen: boolean;
  isSelectPracticeDialogOpen: boolean;
  isSelectAttendeeDialogOpen: boolean;
  isDeleteDialogOpen: boolean;
  isAdditionalSearchDialogOpen: boolean;
  selectedId: string;
  searchKey: string;
  activeSortColumn: SortColumn<MeetingSortField>;
  meetingFilters: MeetingsSearchState;
  additionalFilters: AdditionalFilterState;
}>;

const initialState: PracticeCollaborationTrackerState = {
  isLoading: false,
  isCreateDialogOpen: false,
  isSelectPracticeDialogOpen: false,
  isSelectAttendeeDialogOpen: false,
  isDeleteDialogOpen: false,
  isAdditionalSearchDialogOpen: false,
  selectedId: '',
  searchKey: '',
  activeSortColumn: { field: MeetingSortField.createdDate, direction: SortingDirection.Desc },
  meetingFilters: {
    statementMonth: [],
    meetingCategories: [],
    practices: [],
    staffs: [],
  },
  additionalFilters: {
    enteredBy: [],
    entryCreatedStart: (() => {
      // this is a Hotfix for the limitation of bucket size in lambda function (ENG-1228) should be removed when we move to use pagination
      const date = new Date();
      date.setMonth(date.getMonth() - 2);
      return date.toISOString().split('T')[0];
    })(),
    entryCreatedEnd: new Date().toISOString().split('T')[0],
    meetingRangeStart: '',
    meetingRangeEnd: '',
    contractRoles: [],
    isShowDeletedEntry: false,
  },
};

const slice = createSlice({
  name: 'practiceCollaboration',
  initialState,
  reducers: {
    reset(state) {
      state.activeSortColumn = initialState.activeSortColumn as Writeable<SortColumn<MeetingSortField>>;
      state.meetingFilters = {
        statementMonth: [],
        meetingCategories: [],
        practices: [],
        staffs: [],
      };
      state.additionalFilters = {
        enteredBy: [],
        entryCreatedStart: '',
        entryCreatedEnd: '',
        meetingRangeStart: '',
        meetingRangeEnd: '',
        contractRoles: [],
        isShowDeletedEntry: false,
      };
    },
    updateLoading(state) {
      state.isLoading = !state.isLoading;
    },
    openCreateDialog(state, action: PayloadAction<{ meetingId: string }>) {
      const { meetingId } = action.payload;

      state.isCreateDialogOpen = true;
      state.selectedId = meetingId;
    },
    closeCreateDialog(state) {
      state.isCreateDialogOpen = false;
      state.selectedId = '';
    },
    openSelectPracticeDialog(state) {
      state.isSelectPracticeDialogOpen = true;
    },
    closeSelectPracticeDialog(state) {
      state.isSelectPracticeDialogOpen = false;
      state.searchKey = '';
    },
    openSelectAttendeeDialog(state) {
      state.isSelectAttendeeDialogOpen = true;
    },
    closeSelectAttendeeDialog(state) {
      state.isSelectAttendeeDialogOpen = false;
      state.searchKey = '';
    },
    updateSearchKey(state, action: PayloadAction<{ keyword: string }>) {
      const { keyword } = action.payload;

      state.searchKey = keyword;
    },
    openDeleteDialog(state, action: PayloadAction<{ meetingId: string }>) {
      const { meetingId } = action.payload;

      state.isDeleteDialogOpen = true;
      state.selectedId = meetingId;
    },
    closeDeleteDialog(state) {
      state.isDeleteDialogOpen = false;
    },
    openAdditionalSearchDialog(state) {
      state.isAdditionalSearchDialogOpen = true;
    },
    closeAdditionalSearchDialog(state) {
      state.isAdditionalSearchDialogOpen = false;
    },
    sortByColumn(state, action: PayloadAction<{ field: MeetingSortField; defaultSortDirection?: SortingDirection }>) {
      state.activeSortColumn = {
        field: action.payload.field,
        direction:
          action.payload.field === state.activeSortColumn.field
            ? state.activeSortColumn.direction === SortingDirection.Asc
              ? SortingDirection.Desc
              : SortingDirection.Asc
            : action.payload.defaultSortDirection || SortingDirection.Asc,
      };
    },
    setMeetingFilters(state, action: PayloadAction<{ value: MeetingsSearchState }>) {
      state.meetingFilters = action.payload.value;
    },
    setAdditionalFilters(state, action: PayloadAction<{ value: AdditionalFilterState }>) {
      state.additionalFilters = action.payload.value;
    },
  },
});

export const { reducer } = slice;

export const {
  reset,
  updateLoading,
  openCreateDialog,
  closeCreateDialog,
  openSelectPracticeDialog,
  closeSelectPracticeDialog,
  openSelectAttendeeDialog,
  closeSelectAttendeeDialog,
  updateSearchKey,
  openDeleteDialog,
  closeDeleteDialog,
  openAdditionalSearchDialog,
  closeAdditionalSearchDialog,
  sortByColumn,
  setMeetingFilters,
  setAdditionalFilters,
} = slice.actions;

export default slice.reducer;
