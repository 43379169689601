import moment from 'moment';
import {
  closeCreateDialog,
  closeDeleteDialog,
  openCreateDialog,
  openDeleteDialog,
  sortByColumn,
} from 'slices/practiceCollaboration';
import { useDispatch, useSelector } from 'stores';

import EditIcon from '@material-ui/icons/BorderColor';
import TrashIcon from '@material-ui/icons/DeleteOutline';
import LockIcon from '@material-ui/icons/Lock';

import { useAppPermissions } from 'services/auth.service';
import { useMeetingTimeEntries } from 'services/meeting-time-entry/meetingTimeEntry.service';
import { App } from 'services/models/auth.model';
import type { MeetingModel } from 'services/models/meetingTimeEntry.model';

import { AuthenticatedLayoutComponent } from 'components/AuthenticatedLayoutComponent';
import { BanzaiTable } from 'components/BanzaiTable';
import type { RecordAction } from 'components/BanzaiTable/types';
import { ConfirmationDialog } from 'components/ConfirmationDialog';

import { TitlesConstants } from 'utils/constants/title.constant';
import { formatTime } from 'utils/helpers/formatTime';

import { useFacade } from './PracticeCollaborationTrackerPage.hooks';
import { COLUMN_DEFINITIONS } from './columnDefinitions';
import { AdditionalSearchFilterDialog } from './components/AdditionalSearchFilterDialog';
import { CreateAndUpdateTimeEntryDialog } from './components/CreateAndUpdateTimeEntryDialog';
import { Deadline } from './components/Deadline';
import { ExpandedMeeting } from './components/ExpandedMeeting';
import { FilterToolbar } from './components/FilterToolbar';

export function PracticeCollaborationTrackerPage() {
  const dispatch = useDispatch();
  const { canCreate, canDelete, canUpdate } = useAppPermissions(App.PCT);

  const { isLoading, isCreateDialogOpen, isDeleteDialogOpen, activeSortColumn, selectedId } = useSelector(
    state => state.practiceCollaboration,
  );

  const [handleAddConsult, handleDeleteEntry] = useFacade();

  const { isLoading: isGetMeetings, meetings } = useMeetingTimeEntries();

  const getRecordActions = (meeting: MeetingModel): ReadonlyArray<RecordAction<MeetingModel>> => {
    const actions: RecordAction<MeetingModel>[] = [];

    if (!meeting.processingStatus && !meeting.deleted) {
      if (canUpdate) {
        actions.push({
          id: 'Edit',
          Icon: EditIcon,
          handler: (meeting: MeetingModel) => {
            dispatch(openCreateDialog({ meetingId: meeting.id }));
          },
        });
      }

      if (canDelete) {
        actions.push({
          id: 'Delete',
          Icon: TrashIcon,
          colorClassName: 'text-error',
          handler: (meeting: MeetingModel) => {
            dispatch(openDeleteDialog({ meetingId: meeting.id }));
          },
        });
      }
    } else if (meeting.deleted) {
      actions.push({
        id: 'Locked',
        Icon: LockIcon,
        tooltip: {
          text:
            `Deleted by ${meeting.updated.user.firstName} ${meeting.updated.user.lastName}` +
            `, ${moment(meeting.updated.timestamp).format('L')}` +
            ` at ${formatTime(new Date(meeting.updated.timestamp))}`,
          Icon: TrashIcon,
          colorClassName: 'text-error',
        },
      });
    } else {
      actions.push({
        id: 'Locked',
        Icon: LockIcon,
        tooltip: {
          text: `Submitted for ${moment(meeting.statementMonth).format('MMMM YYYY')} payment processing`,
          Icon: LockIcon,
        },
      });
    }
    return actions;
  };

  const renderFilterToolbar = () => <FilterToolbar isLoading={isLoading || isGetMeetings} />;

  return (
    <AuthenticatedLayoutComponent title={TitlesConstants.hourlyConsult}>
      <BanzaiTable
        tableId='meetings'
        pageHeading='Practice Collaboration Tracker'
        tableHeading='Meeting Time Entries'
        createRecord={{
          text: 'Create New Time Entry',
          enabled: canCreate && !isLoading,
          handler: () => {
            dispatch(openCreateDialog({ meetingId: '' }));
          },
        }}
        pluralRecordsText='Time Entries match Search'
        emptyTableText='Select search parameters and start search to view time entries'
        isLoading={isGetMeetings}
        records={meetings}
        columns={COLUMN_DEFINITIONS}
        sorting={{
          activeSortColumn,
          sortRecords: (field, defaultSortDirection) => {
            dispatch(sortByColumn({ field, defaultSortDirection }));
          },
        }}
        getRecordActions={getRecordActions}
        AboveHeaderComponent={Deadline}
        AboveTableComponent={renderFilterToolbar}
        ExpandedRecordComponent={ExpandedMeeting}
      />
      <CreateAndUpdateTimeEntryDialog
        isOpen={isCreateDialogOpen}
        isLoading={isLoading}
        editMeetingId={selectedId}
        handleCreate={handleAddConsult}
        handleClose={() => {
          dispatch(closeCreateDialog());
        }}
      />
      <ConfirmationDialog
        isOpen={isDeleteDialogOpen}
        isDisabled={isGetMeetings || isLoading}
        message='Are you sure you want to delete this time entry?'
        handleCancel={() => {
          dispatch(closeDeleteDialog());
        }}
        handleConfirm={handleDeleteEntry}
      />
      <AdditionalSearchFilterDialog isLoading={isGetMeetings || isLoading} />
    </AuthenticatedLayoutComponent>
  );
}
