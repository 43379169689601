/* eslint-disable @typescript-eslint/no-dynamic-delete */

/**
 * Provides general validator
 */
import type { IErrorState } from './error-state';
import type { ValidationRule } from './validation-rule';

export class GeneralValidator {
  private readonly map: Record<string, ValidationRule[]> = {};

  constructor(maps: Record<string, ValidationRule[]>) {
    this.map = maps;
  }

  public validate(field: string, value: any): string | null {
    const rules = this.map[field];
    if (!rules) {
      return null;
    }

    let error = null;
    rules.forEach(rule => {
      const isValid = rule.function(value ?? '');
      if (!isValid) {
        error = rule.message;
      }
    });

    return error;
  }

  public validateAndSetState<T extends IErrorState>(
    state: T,
    setState: (newState: T) => void,
    field: string,
    value: any,
    suffix?: string,
  ): void {
    const error = this.validate(field, value);

    if (error) {
      setState({ ...state, errors: GeneralValidator.addError(state, `${field}${suffix ?? ''}`, error) });
    } else {
      setState({ ...state, errors: GeneralValidator.removeError(state, `${field}${suffix ?? ''}`) });
    }
  }

  public validateObjectAndSetState<T extends IErrorState>(
    state: T,
    setState: (newState: T) => void,
    object: any,
    suffix?: string,
  ): void {
    Object.keys(object).forEach(field => {
      this.validateAndSetState(state, setState, field, object[field], suffix);
    });
  }

  public stateIsValid<T extends IErrorState>(state: T): boolean {
    return Object.keys(state.errors).length === 0;
  }

  public static addError<T extends IErrorState>(state: T, field: string, error: string): Record<string, string> {
    state.errors[field] = error;
    return { ...state.errors };
  }

  public static removeError<T extends IErrorState>(state: T, field: string): Record<string, string> {
    if (state.errors[field]) {
      delete state.errors[field];
    }
    return { ...state.errors };
  }
}
