import { combineReducers } from '@reduxjs/toolkit';
import { reducer as meetingCategoryReducer } from 'slices/meetingCategory';
import { reducer as practiceCollaborationReducer } from 'slices/practiceCollaboration';
import { reducer as programReducer } from 'slices/program';
import { reducer as roleAndPermissionReducer } from 'slices/roleAndPermission';
import { reducer as rosterReducer } from 'slices/roster';
import { reducer as snackReducer } from 'slices/snack';

const rootReducer = combineReducers({
  meetingCategory: meetingCategoryReducer,
  practiceCollaboration: practiceCollaborationReducer,
  roster: rosterReducer,
  roleAndPermissions: roleAndPermissionReducer,
  program: programReducer,
  snack: snackReducer,
});

export default rootReducer;
