import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

// Headers
axiosInstance.defaults.headers.common['Content-Type'] = 'application/json';
axiosInstance.defaults.headers.common.Accept = 'application/json';
const accessToken = localStorage.getItem('accessToken') ?? null;
if (accessToken) {
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
}

export default axiosInstance;
