import { useCallback } from 'react';
import { closeCreateStaffDialog, showStaffValidationError } from 'slices/roster';
import { showError, showSuccess } from 'slices/snack';
import { useDispatch, useSelector } from 'stores';

import { Box, Dialog, DialogContent, Divider, Grid } from '@material-ui/core';

import type { StaffValidationError } from 'services/roster';
import { useCreateStaffMutation, useEditStaffMutation } from 'services/roster';

import { Button } from 'components/Button';

import { InputFieldRow } from './InputFieldRow';
import { StaffValidationErrorInfo } from './StaffValidationErrorInfo';

export function CreateStaffDialog() {
  const dispatch = useDispatch();
  const {
    isOpen,
    existingStaff,
    inputValues,
    inputValidationErrors,
    apiValidationError,
    isOverrideWarnings,
    isInputValueDirty,
  } = useSelector(store => store.roster.createStaffDialogState);

  const cancelCreate = () => {
    dispatch(closeCreateStaffDialog());
  };

  const onSuccess = useCallback(() => {
    const message = `Staff Successfully ${existingStaff ? 'Updated' : 'Created'}.`;
    dispatch(showSuccess({ message }));
    dispatch(closeCreateStaffDialog());
  }, [dispatch, existingStaff]);

  const onError = useCallback(() => {
    const message = `An error occurred while ${existingStaff ? 'updating' : 'creating'} this Staff.`;
    dispatch(showError({ message }));
  }, [dispatch, existingStaff]);

  const onValidationError = useCallback(
    (error: StaffValidationError) => {
      dispatch(showStaffValidationError({ error }));
    },
    [dispatch],
  );

  const { isCreateStaffInProgress, createStaff } = useCreateStaffMutation({ onSuccess, onError, onValidationError });
  const { isEditStaffInProgress, editStaff } = useEditStaffMutation({ onSuccess, onError, onValidationError });
  const inProgress = isCreateStaffInProgress || isEditStaffInProgress;

  const validateAndSaveStaff = () => {
    const { practiceId, firstName, lastName, jobTitle, contractRoleId } = inputValues;
    if (practiceId && firstName && lastName && jobTitle && contractRoleId) {
      const validatedInputValues = {
        firstName,
        lastName,
        jobTitle,
        contractRoleId,
        overrideWarnings: isOverrideWarnings,
        ...inputValues,
      };
      if (existingStaff) {
        editStaff({
          ...validatedInputValues,
          id: existingStaff.id,
          sourceId: existingStaff.sourceId,
        });
      } else {
        createStaff(validatedInputValues);
      }
    }
  };

  const isValidToCreate =
    (isInputValueDirty || isOverrideWarnings) &&
    Boolean(inputValues.practiceId) &&
    Boolean(inputValues.contractRoleId) &&
    Boolean(inputValues.firstName) &&
    Boolean(inputValues.lastName) &&
    Boolean(inputValues.jobTitle) &&
    Object.values(inputValidationErrors).filter(error => error).length === 0;

  return (
    <Dialog
      maxWidth={apiValidationError ? 'lg' : 'sm'}
      fullWidth
      open={isOpen}
      onClose={(event: object, reason: string) => {
        reason !== 'backdropClick' && cancelCreate();
      }}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'>
      <DialogContent className='p-8'>
        <Box className='xl:flex' justifyContent='space-between' alignItems='center'>
          <Box pr={3}>
            <span className='text-3xl font-semibold text-black'>
              {existingStaff ? 'Editing Staff' : 'Create a New Staff'}
            </span>
          </Box>
        </Box>
        <Grid container item direction='row'>
          <Grid item xs={apiValidationError ? 5 : 12}>
            <InputFieldRow field='displayName' disabled={inProgress} />
            <InputFieldRow field='firstName' disabled={inProgress} />
            <InputFieldRow field='middleName' disabled={inProgress} />
            <InputFieldRow field='lastName' disabled={inProgress} />
            <InputFieldRow field='prefix' disabled={inProgress} />
            <InputFieldRow field='suffix' disabled={inProgress} />
            <InputFieldRow field='npi' disabled={inProgress} />
            <InputFieldRow field='jobTitle' disabled={inProgress} />
            <InputFieldRow field='contractRoleId' disabled={inProgress} />
          </Grid>
          {apiValidationError && (
            <Grid item xs={7}>
              <Box className='flex h-full'>
                <Divider orientation='vertical' className='mx-8 hidden border-divider xl:block' />
                <StaffValidationErrorInfo />
              </Box>
            </Grid>
          )}
        </Grid>
        <Box mt={5}>
          <Divider />
          <Box mt={5} display='flex' justifyContent='center' alignItems='center'>
            <Box mx={2}>
              <Button
                id='dialog-close-button'
                onClick={cancelCreate}
                disabled={inProgress}
                color='tertiary'
                size='medium'
                borderRadius={50}>
                Cancel
              </Button>
            </Box>
            <Box>
              <Button
                id='add-staff-button'
                size='medium'
                disabled={inProgress || !isValidToCreate}
                loading={inProgress}
                onClick={validateAndSaveStaff}
                borderRadius={50}>
                {existingStaff ? 'Save Changes' : 'Create Staff'}
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
