import { type PracticeInputFieldName, practiceInputValueChanged } from 'slices/roster';
import { useDispatch, useSelector } from 'stores';

import { Box, FormControl, FormHelperText, TextField } from '@material-ui/core';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { KeyboardDatePicker } from '@material-ui/pickers';

import { SelectProgramDropDown } from 'components/dropdowns/SelectProgramDropDown';

import { useStyles } from '../styles';

type Props = Readonly<{
  field: PracticeInputFieldName;
  disabled: boolean;
}>;

const LabelForField: Readonly<Record<Exclude<PracticeInputFieldName, 'programId'>, string>> = {
  tin: 'TIN',
  displayName: 'Display Name',
  legalBusinessName: 'Legal Business Name',
  doingBusinessAsName: 'Doing Business As',
  programStartDate: 'Start Date in Program',
};

// This is not intended as a general purpose Input Field row, but instead is specific to
// the CreatePracticeDialog, to limit code repetition
export function InputFieldRow({ field, disabled }: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { value, placeholder, errorMessage } = useSelector(store => ({
    value: store.roster.createPracticeDialog.inputValues[field],
    placeholder:
      field === 'tin' || field === 'legalBusinessName'
        ? 'required'
        : field === 'displayName'
          ? store.roster.createPracticeDialog.inputValues.doingBusinessAsName ||
            store.roster.createPracticeDialog.inputValues.legalBusinessName
          : undefined,
    errorMessage: store.roster.createPracticeDialog.inputValidationErrors[field],
  }));

  if (field === 'programId') {
    return (
      <SelectProgramDropDown
        selectedId={value}
        errorMessage={errorMessage}
        disabled={disabled}
        onSelected={value => {
          dispatch(practiceInputValueChanged({ field, value }));
        }}
      />
    );
  }

  const showAsDatePicker = field === 'programStartDate';

  return (
    <Box mt={3}>
      <Box className='text-base font-semibold text-black1' mb={0.5}>
        {LabelForField[field]}
      </Box>
      <FormControl variant='outlined' size='medium' fullWidth classes={{ root: classes.root }}>
        {showAsDatePicker ? (
          <KeyboardDatePicker
            disableToolbar
            variant='inline'
            fullWidth
            size='small'
            inputVariant='outlined'
            format='MM/DD/yyyy'
            placeholder='MM/DD/YYYY'
            id={field}
            error={Boolean(errorMessage)}
            helperText={null}
            value={value || null}
            disabled={disabled}
            onChange={date => {
              dispatch(practiceInputValueChanged({ field, value: date?.format('YYYY-MM-DD') || '' }));
            }}
            KeyboardButtonProps={{
              'aria-label': 'change date',
              edge: 'end',
            }}
            keyboardIcon={<CalendarTodayIcon />}
          />
        ) : (
          <TextField
            id={field}
            fullWidth
            size='medium'
            InputProps={{ className: 'input' }}
            variant='outlined'
            disabled={disabled}
            placeholder={placeholder || ''}
            value={value || ''}
            error={Boolean(errorMessage)}
            onChange={event => {
              dispatch(practiceInputValueChanged({ field, value: event.target.value }));
            }}
          />
        )}
        <FormHelperText error>{errorMessage}</FormHelperText>
      </FormControl>
    </Box>
  );
}
