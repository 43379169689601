import { useCallback } from 'react';
import { clearStaffToDelete } from 'slices/roster';
import { showError, showSuccess } from 'slices/snack';
import { useDispatch, useSelector } from 'stores';

import type { StaffDeleteInput } from 'services/models/roster.model';
import { useDeleteStaffMutation } from 'services/roster';

import { ConfirmationDialog } from 'components/ConfirmationDialog';

export function DeleteStaffDialog() {
  const dispatch = useDispatch();
  const { staffToDelete } = useSelector(store => ({
    staffToDelete: store.roster.staffToDelete,
  }));

  const onSuccess = useCallback(() => {
    dispatch(clearStaffToDelete());
    dispatch(showSuccess({ message: 'Staff Successfully Deleted' }));
  }, [dispatch]);

  const onError = useCallback(() => {
    dispatch(clearStaffToDelete());
    dispatch(showError({ message: 'An error occurred while deleting this Staff.' }));
  }, [dispatch]);

  const { isDeleteStaffInProgress, deleteStaff } = useDeleteStaffMutation({ onSuccess, onError });

  const onCancel = () => {
    dispatch(clearStaffToDelete());
  };

  const onConfirm = () => {
    deleteStaff(staffToDelete as StaffDeleteInput);
  };

  return (
    <ConfirmationDialog
      isOpen={Boolean(staffToDelete)}
      isDisabled={isDeleteStaffInProgress}
      message='Are you sure you want to delete this staff?'
      handleCancel={onCancel}
      handleConfirm={onConfirm}
    />
  );
}
