import { Box, FormControl, FormHelperText, MenuItem, Select } from '@material-ui/core';

import { useStyles } from './styles';

type Props = Readonly<{
  id: string;
  label: string;
  placeholder: string;
  items?: ReadonlyArray<Readonly<{ id: string; name: string }>>;
  value?: string;
  errorMessage?: string | null;
  disabled?: boolean;
  onSelected: (value: string) => void;
}>;

export function BanzaiDropDown({ id, label, placeholder, items, value, errorMessage, disabled, onSelected }: Props) {
  const classes = useStyles();

  return (
    <Box mt={3}>
      <Box className='text-base font-semibold text-black1' mb={0.5}>
        {label}
      </Box>
      <FormControl variant='outlined' size='medium' fullWidth classes={{ root: classes.root }}>
        <Select
          classes={{
            root: `${classes.root} ${disabled ? 'bg-disabled' : ''}`,
          }}
          value={value}
          renderValue={() => {
            if (!value) {
              return <Box className='text-base font-normal text-black2'>{placeholder}</Box>;
            }

            return <Box className='text-base font-normal text-black2'>{items?.find(c => c.id === value)?.name}</Box>;
          }}
          displayEmpty
          disabled={disabled}
          onChange={event => {
            onSelected(event.target.value as string);
          }}>
          {items?.map(item => (
            <MenuItem key={`${id}-${item.id}`} value={item.id}>
              <Box display='flex' alignItems='center' justifyContent='space-between' width={1}>
                <Box>{item.name}</Box>
              </Box>
            </MenuItem>
          ))}
        </Select>
        <FormHelperText error>{errorMessage}</FormHelperText>
      </FormControl>
    </Box>
  );
}
