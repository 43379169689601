/* eslint-disable react/prefer-stateless-function */
import { Component } from 'react';

import { LinearProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { colors } from 'utils/constants/colors.constant';

class LoadingLinearProgress extends Component<any> {
  render() {
    const { classes } = this.props;
    return (
      <LinearProgress
        {...this.props}
        classes={{
          colorPrimary: classes.colorPrimary,
          barColorPrimary: classes.barColorPrimary,
        }}
      />
    );
  }
}

const styles = () => ({
  colorPrimary: {
    backgroundColor: '#bbd4e3',
  },
  barColorPrimary: {
    backgroundColor: colors.main,
  },
});

export default withStyles(styles)(LoadingLinearProgress);
