import { createStyles, makeStyles } from '@material-ui/core';

import { colors } from 'utils/constants/colors.constant';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      borderRadius: 2,
      textTransform: 'none',
      boxSizing: 'border-box',
    },
    default: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '16.41px',
      height: 34,
      minWidth: 83,
      paddingRight: 17.5,
      paddingLeft: 17.5,
    },
    large: {
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '19px',
      height: 48,
      paddingRight: 28,
      paddingLeft: 28,
    },
    medium: {
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '19px',
      height: 40,
      paddingRight: 24,
      paddingLeft: 24,
    },
    small: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '16px',
      height: 28,
      paddingRight: 20,
      paddingLeft: 20,
    },
    primary: {
      border: `1px solid ${colors.main}`,
      background: colors.main,
      color: colors.white,
      '&:hover': {
        background: colors.main,
        '&:disabled': {
          backgroundColor: colors.mainDisabled,
        },
      },
      '&:disabled': {
        color: colors.white,
        backgroundColor: colors.mainDisabled,
        cursor: 'not-allowed',
        border: 'none',
      },
    },
    secondary: {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: colors.main,
      color: colors.main,
      background: colors.white,
      '& svg': {
        fill: colors.main,
      },
      '&:hover': {
        background: colors.main,
        color: colors.white,
        '& svg': {
          fill: colors.white,
        },
      },
      '&:disabled': {
        color: colors.gray2,
        borderColor: colors.stroke,
        backgroundColor: colors.white,
        cursor: 'not-allowed',
        '& svg': {
          fill: colors.stroke,
        },
      },
    },
    tertiary: {
      border: 'none',
      color: colors.main,
      backgroundColor: colors.gray3,
      '&:hover': {
        backgroundColor: colors.stroke,
        '&:disabled': {
          backgroundColor: colors.disabled,
        },
      },
      '&:disabled': {
        color: colors.gray2,
        backgroundColor: colors.disabled,
        cursor: 'not-allowed',
      },
    },
    danger: {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: colors.error,
      color: colors.error,
      background: colors.white,
      '& svg': {
        fill: colors.error,
      },
      '&:hover': {
        backgroundColor: colors.error,
        color: colors.white,
        '& svg': {
          fill: colors.white,
        },
        '&:disabled': {
          backgroundColor: colors.disabled,
        },
      },
      '&:disabled': {
        color: colors.gray2,
        backgroundColor: colors.disabled,
        cursor: 'not-allowed',
      },
    },
    white: {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: colors.black5,
      color: colors.black5,
      background: colors.white,
      '& svg': {
        fill: colors.black5,
      },
      '&:disabled': {
        color: colors.gray2,
        borderColor: colors.stroke,
        backgroundColor: colors.stroke,
        cursor: 'not-allowed',
        '& svg': {
          fill: colors.gray2,
        },
      },
    },
    black: {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: colors.white,
      color: colors.white,
      background: colors.black5,
      '& svg': {
        fill: colors.white,
      },
      '&:hover': {
        background: colors.white,
        color: colors.black5,
        '& svg': {
          fill: colors.black5,
        },
      },
      '&:disabled': {
        color: colors.gray2,
        borderColor: colors.stroke,
        backgroundColor: colors.stroke,
        cursor: 'not-allowed',
        '& svg': {
          fill: colors.gray2,
        },
      },
    },
    gray: {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: colors.white,
      color: colors.white,
      background: colors.gray4,
      '& svg': {
        fill: colors.white,
      },
      '&:hover': {
        background: colors.black5,
        color: colors.white,
        '& svg': {
          fill: colors.white,
        },
      },
      '&:disabled': {
        color: colors.gray2,
        borderColor: colors.stroke,
        backgroundColor: colors.stroke,
        cursor: 'not-allowed',
        '& svg': {
          fill: colors.gray2,
        },
      },
    },
    none: {
      border: 'none',
    },
    linkButton: {
      textDecoration: 'none',
      color: colors.main,
      cursor: 'pointer',
    },
  }),
);
