import { AutoSizer, CellMeasurer, CellMeasurerCache, List, type ListRowProps } from 'react-virtualized';

import { Box } from '@material-ui/core';

import type { UpdateHourlyConsultModel } from 'services/models/meetingTimeEntry.model';

import LoadingLinearProgress from 'components/LoadingLinearProgress';

import { ACTION_COLUMN_WIDTH, type Props as RowProps } from './PickerTableRow';
import { PickerTableRow } from './PickerTableRow';
import type { RecordBase } from './types';

export * from './types';

export type Props<RT extends RecordBase, CKT extends string, SFT extends string> = Pick<
  RowProps<RT, CKT, SFT>,
  'columns' | 'handleSelect'
> & {
  readonly isLoading?: boolean;
  readonly practiceId: string;
  readonly params: Readonly<UpdateHourlyConsultModel>;
  readonly emptyText: string;
  readonly records?: ReadonlyArray<RT>;
};

export function PickerTable<RT extends RecordBase, CKT extends string, SFT extends string>({
  records,
  practiceId,
  columns,
  params,
  isLoading,
  emptyText,
  handleSelect,
}: Props<RT, CKT, SFT>) {
  const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 45,
  });

  if (isLoading) {
    return <LoadingLinearProgress />;
  }

  if (!records || !records.length) {
    return (
      <>
        <Box py={1.5} className='flex rounded-t-xl bg-divider'>
          {columns.map(column => (
            <Box key={column.title} px={2} className='font-semibold text-black' width={column.width}>
              {column.title}
            </Box>
          ))}
          <Box px={2} width={ACTION_COLUMN_WIDTH} className='font-semibold text-black' />
        </Box>
        <Box py={1.5} className='w-full bg-gray4 text-center italic text-black4'>
          {emptyText}
        </Box>
      </>
    );
  }

  const renderRow = ({ index, key, style, parent }: ListRowProps) => (
    <CellMeasurer key={key} cache={cache} parent={parent} columnIndex={0} rowIndex={index}>
      {({ registerChild }) => (
        <div style={style} ref={registerChild as React.LegacyRef<HTMLDivElement>} className='w-full'>
          <PickerTableRow
            record={records[index]}
            practiceId={practiceId}
            params={params}
            columns={columns}
            handleSelect={handleSelect}
          />
        </div>
      )}
    </CellMeasurer>
  );

  return (
    <>
      <Box py={1.5} className='flex rounded-t-xl bg-divider'>
        {columns.map(column => (
          <Box key={column.title} px={2} className='font-semibold text-black' width={column.width}>
            {column.title}
          </Box>
        ))}
        <Box px={2} width={ACTION_COLUMN_WIDTH} className='font-semibold text-black' />
      </Box>
      <Box className='h-80'>
        {/* eslint-disable react/no-unstable-nested-components */}
        <AutoSizer>
          {({ width, height }) => (
            <List
              width={width}
              height={height}
              deferredMeasurementCache={cache}
              rowHeight={cache.rowHeight}
              rowRenderer={renderRow}
              rowCount={records.length || 0}
              overscanRowCount={3}
              className='flat-scroll'
            />
          )}
        </AutoSizer>
        {/* eslint-enable react/no-unstable-nested-components */}
      </Box>
    </>
  );
}
