import * as Sentry from '@sentry/react';
import { useCallback } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import type {
  AddMeetingCategoryToProgramInput,
  AddPracticeToProgramInput,
  EditMeetingCategoryInProgramInput,
  EditPracticeInProgramInput,
  ProgramAndRelatedItemsModel,
  ProgramMeetingCategoryModel,
  ProgramPracticeModel,
  RemoveMeetingCategoryFromProgramInput,
  RemovePracticeFromProgramInput,
} from 'services/models/program.model';

import API from 'utils/helpers/axiosInstance';

import type { GenericMutationCallbacks } from './rosterServiceUtils';

type MutationMeetingCategoryCallbacks = GenericMutationCallbacks<ProgramMeetingCategoryModel>;
type MutationPracticeCallbacks = GenericMutationCallbacks<ProgramPracticeModel>;

async function getProgramsApi() {
  const result = await API.get<ReadonlyArray<ProgramAndRelatedItemsModel>>(`programs`);
  return result.data;
}

async function addMeetingCategoryToProgramApi(
  input: AddMeetingCategoryToProgramInput,
): Promise<ProgramMeetingCategoryModel> {
  const result = await API.post<ProgramMeetingCategoryModel>('program/meetingCategory', input);
  return result.data;
}

async function editMeetingCategoryInProgramApi(
  input: EditMeetingCategoryInProgramInput,
): Promise<ProgramMeetingCategoryModel> {
  const { programMeetingCategoryId, ...body } = input;
  const result = await API.put<ProgramMeetingCategoryModel>(
    `program/meetingCategory/${programMeetingCategoryId}`,
    body,
  );
  return result.data;
}

async function removeMeetingCategoryFromProgramApi(
  input: RemoveMeetingCategoryFromProgramInput,
): Promise<ProgramMeetingCategoryModel> {
  const { programMeetingCategoryId, ...data } = input;
  const result = await API.delete<ProgramMeetingCategoryModel>(`program/meetingCategory/${programMeetingCategoryId}`, {
    data,
  });
  return result.data;
}

async function addPracticeToProgramApi(input: AddPracticeToProgramInput): Promise<ProgramPracticeModel> {
  const result = await API.post<ProgramPracticeModel>('program/practice', input);
  return result.data;
}

async function editPracticeInProgramApi(input: EditPracticeInProgramInput): Promise<ProgramPracticeModel> {
  const { programPracticeId, ...body } = input;
  const result = await API.put<ProgramPracticeModel>(`program/practice/${programPracticeId}`, body);
  return result.data;
}

async function removePracticeFromProgramApi(input: RemovePracticeFromProgramInput): Promise<ProgramPracticeModel> {
  const { programPracticeId, ...data } = input;
  const result = await API.delete<ProgramPracticeModel>(`program/practice/${programPracticeId}`, { data });
  return result.data;
}

export function useProgramList() {
  const { isLoading, isRefetching, data: programs } = useQuery(['programs'], getProgramsApi);
  return { isLoading: isLoading || isRefetching, programs };
}

export function useAddMeetingCategoryToProgramMutation({ onSuccess, onError }: MutationMeetingCategoryCallbacks) {
  const queryClient = useQueryClient();
  const { isLoading: isAdding, mutate } = useMutation(addMeetingCategoryToProgramApi);
  const addMeetingCategoryToProgram = useCallback(
    (input: AddMeetingCategoryToProgramInput) => {
      mutate(input, {
        onSuccess: programMeetingCategory => {
          queryClient.invalidateQueries('programs');
          onSuccess(programMeetingCategory);
        },
        onError: (error: unknown) => {
          Sentry.captureException(error);
          onError(error);
        },
      });
    },
    [mutate, queryClient, onSuccess, onError],
  );

  return { isAdding, addMeetingCategoryToProgram };
}

export function useEditMeetingCategoryInProgramMutation({ onSuccess, onError }: MutationMeetingCategoryCallbacks) {
  const queryClient = useQueryClient();
  const { isLoading: isUpdating, mutate } = useMutation(editMeetingCategoryInProgramApi);
  const editMeetingCategoryInProgram = useCallback(
    (input: EditMeetingCategoryInProgramInput) => {
      mutate(input, {
        onSuccess: programMeetingCategory => {
          queryClient.invalidateQueries('programs');
          onSuccess(programMeetingCategory);
        },
        onError: (error: unknown) => {
          Sentry.captureException(error);
          onError(error);
        },
      });
    },
    [mutate, queryClient, onSuccess, onError],
  );

  return { isUpdating, editMeetingCategoryInProgram };
}

export function useRemoveMeetingCategoryFromProgramMutation({ onSuccess, onError }: MutationMeetingCategoryCallbacks) {
  const queryClient = useQueryClient();
  const { isLoading, mutate } = useMutation(removeMeetingCategoryFromProgramApi);
  const removeMeetingCategoryFromProgram = useCallback(
    (input: RemoveMeetingCategoryFromProgramInput) => {
      mutate(input, {
        onSuccess: programMeetingCategory => {
          queryClient.invalidateQueries('programs');
          onSuccess(programMeetingCategory);
        },
        onError: (error: unknown) => {
          Sentry.captureException(error);
          onError(error);
        },
      });
    },
    [mutate, queryClient, onSuccess, onError],
  );
  return { isLoading, removeMeetingCategoryFromProgram };
}

export function useAddPracticeToProgramMutation({ onSuccess, onError }: MutationPracticeCallbacks) {
  const queryClient = useQueryClient();
  const { isLoading: isAdding, mutate } = useMutation(addPracticeToProgramApi);
  const addPracticeToProgram = useCallback(
    (input: AddPracticeToProgramInput) => {
      mutate(input, {
        onSuccess: programPractice => {
          queryClient.invalidateQueries('programs');
          onSuccess(programPractice);
        },
        onError: (error: unknown) => {
          Sentry.captureException(error);
          onError(error);
        },
      });
    },
    [mutate, queryClient, onSuccess, onError],
  );

  return { isAdding, addPracticeToProgram };
}

export function useEditPracticeInProgramMutation({ onSuccess, onError }: MutationPracticeCallbacks) {
  const queryClient = useQueryClient();
  const { isLoading: isUpdating, mutate } = useMutation(editPracticeInProgramApi);
  const editPracticeInProgram = useCallback(
    (input: EditPracticeInProgramInput) => {
      mutate(input, {
        onSuccess: programPractice => {
          queryClient.invalidateQueries('programs');
          onSuccess(programPractice);
        },
        onError: (error: unknown) => {
          Sentry.captureException(error);
          onError(error);
        },
      });
    },
    [mutate, queryClient, onSuccess, onError],
  );

  return { isUpdating, editPracticeInProgram };
}

export function useRemovePracticeFromProgramMutation({ onSuccess, onError }: MutationPracticeCallbacks) {
  const queryClient = useQueryClient();
  const { isLoading, mutate } = useMutation(removePracticeFromProgramApi);
  const removePracticeFromProgram = useCallback(
    (input: RemovePracticeFromProgramInput) => {
      mutate(input, {
        onSuccess: programPractice => {
          queryClient.invalidateQueries('programs');
          onSuccess(programPractice);
        },
        onError: (error: unknown) => {
          Sentry.captureException(error);
          onError(error);
        },
      });
    },
    [mutate, queryClient, onSuccess, onError],
  );
  return { isLoading, removePracticeFromProgram };
}
