import moment from 'moment';
import {
  openAddMeetingCategoryDialog,
  openAddPracticeDialog,
  openEditMeetingCategoryDialog,
  openEditPracticeDialog,
  setMeetingCategoryToDelete,
  setPracticeToDelete,
} from 'slices/program';
import { useDispatch } from 'stores';

import { Box, Grid, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/BorderColor';
import TrashIcon from '@material-ui/icons/DeleteOutline';

import type { ProgramAndRelatedItemsModel } from 'services/models/program.model';

import { Button } from 'components/Button';

import { useStyles } from './styles';

type Props = {
  readonly record: ProgramAndRelatedItemsModel;
};

export function ExpandedProgram({ record }: Props) {
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <Box className='my-auto flex-1 p-4'>
      <Grid container item direction='row'>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Box px={2} display='flex' flexDirection='column' className={classes.practiceSection}>
            <Box display='flex' alignItems='center' justifyContent='space-between' width={1}>
              <Box px={2} className='text-xl text-black1'>
                Practices
              </Box>
              <Button
                id='open-add-practice-dialog-button'
                color='primary'
                borderRadius={50}
                size='medium'
                onClick={() => {
                  dispatch(openAddPracticeDialog({ programId: record.id }));
                }}>
                <Box display='flex' alignItems='center'>
                  <AddIcon />
                  <span className='ml-1'>Add Practice</span>
                </Box>
              </Button>
            </Box>
            <Box mt={2}>
              <Box display='flex' py={1.5} className='border-y-2 border-divider'>
                <Box px={2} width={0.45} className='font-semibold text-black1'>
                  Name
                </Box>
                <Box px={2} width={0.2} className='font-semibold text-black1'>
                  Start Date
                </Box>
                <Box px={2} width={0.2} className='font-semibold text-black1'>
                  End Date
                </Box>
                <Box width={0.15} />
              </Box>
              {record.practices.length > 0 ? (
                record.practices.map(practice => (
                  <Box
                    key={practice.id}
                    display='flex'
                    py={1.5}
                    alignItems='center'
                    className='border-b-2 border-divider'>
                    <Box px={2} width={0.45} className='break-words font-normal'>
                      {practice.displayName}
                    </Box>
                    <Box px={2} width={0.2} className='break-words font-normal'>
                      {moment(new Date(practice.startDate).toISOString().slice(0, 10)).format('L')}
                    </Box>
                    <Box px={2} width={0.2} className='break-words font-normal'>
                      {practice.endDate
                        ? moment(new Date(practice.endDate).toISOString().slice(0, 10)).format('L')
                        : '-'}
                    </Box>
                    <Box width={0.15} display='flex' justifyContent='flex-end'>
                      <Box width='70px' display='flex' alignItems='center' justifyContent='center' gridGap={8}>
                        <IconButton
                          aria-label='edit-button'
                          size='small'
                          onClick={() => {
                            dispatch(openEditPracticeDialog({ existingProgramPractice: practice }));
                          }}>
                          <EditIcon className='cursor-pointer text-black5' />
                        </IconButton>
                        {practice.allowedActions.delete && (
                          <IconButton
                            aria-label='delete-button'
                            size='small'
                            onClick={() => {
                              dispatch(
                                setPracticeToDelete({
                                  programPracticeId: practice.programPracticeId,
                                  sourceId: practice.sourceId,
                                }),
                              );
                            }}>
                            <TrashIcon className='cursor-pointer text-error' />
                          </IconButton>
                        )}
                      </Box>
                    </Box>
                  </Box>
                ))
              ) : (
                <Box className='my-auto flex-1 p-4'>This program does not have any practices associated with it.</Box>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Box px={2} display='flex' flexDirection='column' className={classes.practiceSection}>
            <Box display='flex' alignItems='center' justifyContent='space-between' width={1}>
              <Box px={2} className='text-xl text-black1'>
                Meeting Categories
              </Box>
              <Button
                id='open-add-meeting-category-dialog-button'
                color='primary'
                borderRadius={50}
                size='medium'
                onClick={() => {
                  dispatch(openAddMeetingCategoryDialog({ programId: record.id }));
                }}>
                <Box display='flex' alignItems='center'>
                  <AddIcon />
                  <span className='ml-1'>Add Meeting Category</span>
                </Box>
              </Button>
            </Box>
            <Box mt={2}>
              <Box display='flex' py={1.5} className='border-y-2 border-divider'>
                <Box px={2} width={0.45} className='font-semibold text-black1'>
                  Name
                </Box>
                <Box px={2} width={0.2} className='font-semibold text-black1'>
                  Start Date
                </Box>
                <Box px={2} width={0.2} className='font-semibold text-black1'>
                  End Date
                </Box>
                <Box width={0.15} />
              </Box>
              {record.meetingCategories.length > 0 ? (
                record.meetingCategories.map(meetingCategory => (
                  <Box
                    key={meetingCategory.id}
                    display='flex'
                    py={1.5}
                    alignItems='center'
                    className='border-b-2 border-divider'>
                    <Box px={2} width={0.45} className='break-words font-normal'>
                      {meetingCategory.name}
                    </Box>
                    <Box px={2} width={0.2} className='break-words font-normal'>
                      {moment(new Date(meetingCategory.startDate).toISOString().slice(0, 10)).format('L')}
                    </Box>
                    <Box px={2} width={0.2} className='break-words font-normal'>
                      {meetingCategory.endDate
                        ? moment(new Date(meetingCategory.endDate).toISOString().slice(0, 10)).format('L')
                        : '-'}
                    </Box>
                    <Box width={0.15} display='flex' justifyContent='flex-end'>
                      <Box width='70px' display='flex' alignItems='center' justifyContent='center' gridGap={8}>
                        <IconButton
                          aria-label='edit-button'
                          size='small'
                          onClick={() => {
                            dispatch(
                              openEditMeetingCategoryDialog({
                                existingProgramMeetingCategory: meetingCategory,
                              }),
                            );
                          }}>
                          <EditIcon className='cursor-pointer text-black5' />
                        </IconButton>
                        {meetingCategory.allowedActions.delete && (
                          <IconButton
                            aria-label='delete-button'
                            size='small'
                            onClick={() => {
                              dispatch(
                                setMeetingCategoryToDelete({
                                  programMeetingCategoryId: meetingCategory.programMeetingCategoryId,
                                  sourceId: meetingCategory.sourceId,
                                }),
                              );
                            }}>
                            <TrashIcon className='cursor-pointer text-error' />
                          </IconButton>
                        )}
                      </Box>
                    </Box>
                  </Box>
                ))
              ) : (
                <Box className='my-auto flex-1 p-4'>
                  This program does not have any meeting categories associated with it.
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
