import { sortByColumn } from 'slices/program';
import { useDispatch, useSelector } from 'stores';

import { useProgramList } from 'services/roster';

import { AuthenticatedLayoutComponent } from 'components/AuthenticatedLayoutComponent';
import { BanzaiTable } from 'components/BanzaiTable';

import { TitlesConstants } from 'utils/constants/title.constant';

import { AddMeetingCategoryToProgramDialog } from './AddMeetingCategoryToProgramDialog';
import { AddPracticeToProgramDialog } from './AddPracticeToProgramDialog';
import { ExpandedProgram } from './ExpandedProgram';
import { RemoveMeetingCategoryDialog } from './RemoveMeetingCategoryDialog';
import { RemovePracticeDialog } from './RemovePracticeDialog';
import { PROGRAM_COLUMN_DEFINITIONS } from './columnDefinitions';

export function ProgramPage() {
  const dispatch = useDispatch();
  const { activeSortColumn } = useSelector(store => ({
    activeSortColumn: store.program.activeSortColumn,
  }));
  const { isLoading, programs } = useProgramList();

  return (
    <AuthenticatedLayoutComponent title={TitlesConstants.rosterSupport}>
      <BanzaiTable
        tableId='programs'
        pageHeading='Roster Support'
        tableHeading='Programs'
        pluralRecordsText='matching Programs'
        isLoading={isLoading}
        records={programs}
        columns={PROGRAM_COLUMN_DEFINITIONS}
        sorting={{
          activeSortColumn,
          sortRecords: (field, defaultSortDirection) => {
            dispatch(sortByColumn({ field, defaultSortDirection }));
          },
        }}
        emptyTableText='No Programs'
        ExpandedRecordComponent={ExpandedProgram}
      />
      <AddPracticeToProgramDialog />
      <AddMeetingCategoryToProgramDialog />
      <RemoveMeetingCategoryDialog />
      <RemovePracticeDialog />
    </AuthenticatedLayoutComponent>
  );
}
