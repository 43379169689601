import clsx from 'clsx';
import { useAuth } from 'contexts/AuthContext';
import { useMemo, useState } from 'react';
import { closeSelectAttendeeDialog, updateSearchKey } from 'slices/practiceCollaboration';
import { useDispatch, useSelector } from 'stores';

import { Box, Dialog, DialogContent } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import type { PracticeModel, StaffModel, UpdateHourlyConsultModel } from 'services/models/meetingTimeEntry.model';
import { SortingDirection } from 'services/models/sorting.model';

import { Button } from 'components/Button';
import { SearchComponent } from 'components/SearchComponent';

import { handleCompare } from 'utils/helpers/handleCompare';

interface SelectAttendeesDialogProps {
  params: UpdateHourlyConsultModel;
  practices?: ReadonlyArray<PracticeModel>;
  handleAttendeeChanges: (selectedAttendees: StaffModel[]) => void;
}

export function SelectAttendeesDialog(props: SelectAttendeesDialogProps) {
  const { isOpenCreateDialog } = useAuth();
  const dispatch = useDispatch();
  const { isSelectAttendeeDialogOpen, searchKey } = useSelector(state => state.practiceCollaboration);
  const { params, practices, handleAttendeeChanges } = props;
  const [attendees, setAttendees] = useState<StaffModel[]>([]);

  const staffs = useMemo(() => {
    const initPractice = practices?.find(p => p.id === params.practiceId);
    const initStaffs = initPractice?.staff
      ? [...initPractice.staff].sort((s1, s2) => handleCompare(s1, s2, SortingDirection.Asc, 'displayName'))
      : [];
    if (searchKey) {
      const trimedSearch = decodeURIComponent(searchKey).trim().toLowerCase();
      const arrayTrimSearchKey = trimedSearch.split(' ');

      const filteredStaffs = initStaffs?.filter(
        p => arrayTrimSearchKey.find(item => p?.displayName?.toLowerCase().includes(item)) !== undefined,
      );
      return filteredStaffs;
    }
    return initStaffs;
  }, [practices, params, searchKey]);

  const handleCancel = () => {
    dispatch(closeSelectAttendeeDialog());
    setAttendees([]);
  };

  const handleSelectAttendee = (id: string) => {
    let selectedAttendees = [...attendees];

    if (selectedAttendees.find(a => a.id === id)) {
      selectedAttendees.splice(
        selectedAttendees.findIndex(a => a.id === id),
        1,
      );
    } else {
      const staffs = practices?.find(p => p.id === params.practiceId)?.staff;
      if (staffs && staffs.length) {
        const item = staffs.find(a => a.id === id);
        if (item) {
          selectedAttendees = [...selectedAttendees, { ...item }];
        }
      }
    }
    setAttendees(selectedAttendees);
  };

  const handleAdd = () => {
    handleAttendeeChanges(params.attendees.concat(attendees));
    handleCancel();
  };

  const emptyText = searchKey ? 'No attendees match the search key' : 'No attendees currently exist in this practice';

  return (
    <Dialog
      maxWidth='md'
      fullWidth
      fullScreen={isOpenCreateDialog}
      open={isSelectAttendeeDialogOpen}
      onClose={(e, reason: string) => {
        reason !== 'backdropClick' && handleCancel();
      }}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'>
      <DialogContent className='p-3 sm:p-8'>
        <Box>
          <span className='text-3xl font-semibold text-black'>
            {params.id ? 'Update Time Entry' : 'Create Time Entry'}
          </span>
        </Box>
        <Box className='mt-4 text-2xl font-normal text-black sm:mt-10'>Add Attendees</Box>
        <Box className='mt-4 sm:mt-10 md:flex' alignItems='center' justifyContent='space-between'>
          <Box flex={1} mr={2} width={1}>
            <SearchComponent
              placeholder='Type Attendee name to filter list'
              data-cy='attendeeSearchBox'
              defaultValue={searchKey}
              handleSearch={value => {
                dispatch(updateSearchKey({ keyword: value }));
              }}
              handleChanges={value => {
                dispatch(updateSearchKey({ keyword: value }));
              }}
            />
          </Box>
          <Box className='mt-2 md:mt-0' display='flex' alignItems='center' justifyContent='flex-end'>
            <Box mx={2}>
              <Button
                id='dialog-close-button'
                data-cy='selectAttendeesDialogCloseButton'
                onClick={handleCancel}
                color='tertiary'
                size='medium'
                borderRadius={50}>
                Cancel
              </Button>
            </Box>
            <Box>
              <Button
                id='add-attendee-button'
                size='medium'
                onClick={handleAdd}
                borderRadius={50}
                disabled={!attendees.length}
                data-cy='addAttendeesButton'>
                Add
              </Button>
            </Box>
          </Box>
        </Box>
        <Box className='mt-4 sm:mt-10'>
          <Box mt={1.5} pb={5}>
            <Box display='flex' py={1.5} className='rounded-t-xl bg-divider'>
              <Box px={2} width={0.3} className='font-semibold text-black'>
                Attendee
              </Box>
              <Box px={2} width={0.2} className='font-semibold text-black'>
                Job Title
              </Box>
              <Box px={2} width={0.3} className='font-semibold text-black'>
                Contract Role
              </Box>
              <Box px={2} width={0.2} className='font-semibold text-black' />
            </Box>
            {!staffs || !staffs?.length ? (
              <Box py={1.5} className='w-full bg-gray4 text-center italic text-black4'>
                {emptyText}
              </Box>
            ) : (
              staffs?.map(item => (
                <Box
                  key={`attendee-id-${item.id}`}
                  display='flex'
                  alignItems='center'
                  py={1.5}
                  className={clsx(
                    'cursor-pointer hover:rounded-lg hover:bg-cellHover',
                    attendees.find(x => x.id === item.id) && 'rounded-lg bg-cellHover',
                  )}
                  onClick={() => {
                    if (params.attendees.find(x => x.id === item.id)) return;
                    handleSelectAttendee(item.id);
                  }}>
                  <Box px={2} width={0.3} className='font-normal'>
                    <Box>{item?.displayName}</Box>
                  </Box>
                  <Box px={2} width={0.2} className='font-normal'>
                    <Box>{item.jobTitle}</Box>
                  </Box>
                  <Box px={2} width={0.3} className='font-normal'>
                    <Box>{item.contractRole.name}</Box>
                  </Box>
                  <Box px={2} width={0.2} className='text-center font-normal'>
                    {params.attendees.find(x => x.id === item.id) ? (
                      <Box className='italic text-black3'>Already Added</Box>
                    ) : (
                      <Box className='cursor-pointer'>
                        {attendees.find(x => x.id === item.id) ? (
                          <CheckCircleIcon className='text-main' data-cy='attendee-checked' />
                        ) : (
                          <AddCircleOutlineIcon className='text-main' data-cy='attendee-unchecked' />
                        )}
                      </Box>
                    )}
                  </Box>
                </Box>
              ))
            )}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
