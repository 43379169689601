import { meetingCategoryInputValueChanged } from 'slices/program';
import { useDispatch, useSelector } from 'stores';

import { Box, FormControl, FormHelperText } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { KeyboardDatePicker } from '@material-ui/pickers';

import type { MeetingCategoryToProgramInput } from 'services/models/program.model';

import { SelectMeetingCategoryDropDown } from 'components/dropdowns/SelectMeetingCategoryDropDown';

import { useStyles } from '../styles';

type FieldName = keyof Omit<MeetingCategoryToProgramInput, 'overrideWarnings'>;

type Props = Readonly<{
  field: FieldName;
  disabled: boolean;
}>;

const LabelForField: Readonly<Record<Exclude<FieldName, 'meetingCategoryId'>, string>> = {
  startDate: 'Start Date',
  endDate: 'End Date',
  paid: 'Paid',
};

export function InputFieldRow({ field, disabled }: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { value, errorMessage } = useSelector(store => ({
    value: store.program.addMeetingCategoryDialog.inputValues[field],
    errorMessage: store.program.addMeetingCategoryDialog.inputValidationErrors[field],
  }));

  if (field === 'meetingCategoryId') {
    return (
      <SelectMeetingCategoryDropDown
        selectedId={value as string}
        errorMessage={errorMessage}
        disabled={disabled}
        onSelected={value => {
          dispatch(meetingCategoryInputValueChanged({ field, value }));
        }}
      />
    );
  }

  const placeholder = field === 'startDate' ? 'required' : undefined;

  return (
    <Box mt={3}>
      <Box className='text-base font-semibold text-black1' mb={0.5}>
        {LabelForField[field]}
      </Box>
      <FormControl variant='outlined' size='medium' fullWidth classes={{ root: classes.root }}>
        {field === 'paid' ? (
          <FormControlLabel
            control={
              <Checkbox
                disabled={disabled}
                checked={Boolean(value)}
                onChange={event => {
                  dispatch(meetingCategoryInputValueChanged({ field, value: event.target.checked }));
                }}
              />
            }
            label='Paid'
          />
        ) : (
          <KeyboardDatePicker
            disableToolbar
            variant='inline'
            fullWidth
            size='small'
            inputVariant='outlined'
            format='MM/DD/yyyy'
            placeholder={placeholder || 'MM/DD/YYYY'}
            id={field}
            error={Boolean(errorMessage)}
            helperText={null}
            value={(value as string) || null}
            disabled={disabled}
            onChange={momentDate => {
              dispatch(
                meetingCategoryInputValueChanged({
                  field,
                  value: momentDate === null ? '' : momentDate.format('YYYY-MM-DD'),
                }),
              );
            }}
            KeyboardButtonProps={{
              'aria-label': 'change date',
              edge: 'end',
            }}
            keyboardIcon={<CalendarTodayIcon />}
          />
        )}
        <FormHelperText error>{errorMessage}</FormHelperText>
      </FormControl>
    </Box>
  );
}
