import { openCreateMeetingCategoryDialog, openEditMeetingCategoryDialog } from 'slices/meetingCategory';
import { useDispatch, useSelector } from 'stores';

import EditIcon from '@material-ui/icons/BorderColor';

import { useAppPermissions } from 'services/auth.service';
import { useMeetingCategoriesQuery } from 'services/meetingCategories.service';
import { App } from 'services/models/auth.model';
import type { MeetingCategoryFullModel } from 'services/models/meetingTimeEntry.model';

import { AuthenticatedLayoutComponent } from 'components/AuthenticatedLayoutComponent';
import { BanzaiTable } from 'components/BanzaiTable';
import type { RecordAction } from 'components/BanzaiTable/types';

import { TitlesConstants } from 'utils/constants/title.constant';

import { CreateMeetingCategoryDialog } from './CreateMeetingCategoryDialog';
import { COLUMN_DEFINITIONS } from './columnDefinitions';

export function MeetingCategoryPage() {
  const { isCreateEditDialogOpen } = useSelector(({ meetingCategory }) => ({
    isCreateEditDialogOpen: meetingCategory.createEditDialog.isOpen,
  }));
  const { isLoading, meetingCategories } = useMeetingCategoriesQuery();
  const { canCreate, canUpdate } = useAppPermissions(App.MEETING_CATEGORIES);
  const dispatch = useDispatch();

  const getMeetingCategoryActions = (meetingCategory: MeetingCategoryFullModel) => {
    const actions: RecordAction<MeetingCategoryFullModel>[] = [];
    if (canUpdate && meetingCategory.allowedActions.edit) {
      actions.push({
        id: 'Edit',
        Icon: EditIcon,
        handler: meetingCategory => {
          dispatch(openEditMeetingCategoryDialog({ meetingCategory }));
        },
      });
    }
    return actions;
  };

  return (
    <AuthenticatedLayoutComponent title={TitlesConstants.meetingCategories}>
      <BanzaiTable
        tableId='meetingCategories'
        pageHeading='Meeting Categories'
        tableHeading='Meeting Categories'
        createRecord={{
          text: 'Create New Meeting Category',
          enabled: canCreate && !isLoading && !isCreateEditDialogOpen,
          handler: () => {
            dispatch(openCreateMeetingCategoryDialog());
          },
        }}
        pluralRecordsText='Meeting Categories'
        isLoading={isLoading}
        records={meetingCategories}
        columns={COLUMN_DEFINITIONS}
        getRecordActions={getMeetingCategoryActions}
        emptyTableText='No meeting categories are currently defined'
      />
      <CreateMeetingCategoryDialog />
    </AuthenticatedLayoutComponent>
  );
}
