import clsx from 'clsx';
import { closeSnack } from 'slices/snack';
import { useDispatch, useSelector } from 'stores';

import { Snackbar } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InfoIcon from '@material-ui/icons/Info';
import { Alert } from '@material-ui/lab';

export function CommonSnack() {
  const dispatch = useDispatch();
  const { isOpen, type, duration, message, vertical, horizontal } = useSelector(state => state.snack);

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={duration}
      anchorOrigin={{ vertical, horizontal }}
      onClose={() => {
        dispatch(closeSnack());
      }}>
      <Alert
        severity={type}
        variant='filled'
        icon={type === 'success' ? <CheckCircleIcon /> : <InfoIcon />}
        className={clsx(
          type === 'success' && 'bg-main',
          type === 'warning' && 'bg-error',
          'flex w-[954px] justify-center rounded-xl py-1',
        )}>
        {message}
      </Alert>
    </Snackbar>
  );
}
