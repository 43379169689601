import { useCallback } from 'react';
import { closeCreateEditMeetingCategoryDialog } from 'slices/meetingCategory';
import { showError, showSuccess } from 'slices/snack';
import { useDispatch, useSelector } from 'stores';

import { Box, Dialog, DialogContent, Divider, Grid } from '@material-ui/core';

import { useCreateMeetingCategoryMutation, useEditMeetingCategoryMutation } from 'services/meetingCategories.service';

import { Button } from 'components/Button';

import { InputFieldRow } from './InputFieldRow';

export function CreateMeetingCategoryDialog() {
  const dispatch = useDispatch();
  const { isOpen, existingMeetingCategory, inputValues, inputValidationErrors, isInputValueDirty } = useSelector(
    store => store.meetingCategory.createEditDialog,
  );

  const cancel = () => {
    dispatch(closeCreateEditMeetingCategoryDialog());
  };

  const onSuccess = useCallback(() => {
    const message = `Meeting Category Successfully ${existingMeetingCategory ? 'Updated' : 'Created'}.`;
    dispatch(closeCreateEditMeetingCategoryDialog());
    dispatch(showSuccess({ message }));
  }, [dispatch, existingMeetingCategory]);

  const onError = useCallback(() => {
    const message = `An error occurred while ${
      existingMeetingCategory ? 'updating' : 'creating'
    } this Meeting Category.`;
    dispatch(showError({ message }));
  }, [dispatch, existingMeetingCategory]);

  const { inProgress: isCreateInProgress, createMeetingCategory } = useCreateMeetingCategoryMutation({
    onSuccess,
    onError,
  });
  const { inProgress: isEditInProgress, editMeetingCategory } = useEditMeetingCategoryMutation({ onSuccess, onError });
  const inProgress = isCreateInProgress || isEditInProgress;

  const validateAndSave = () => {
    const { id, name, description } = inputValues;
    if (id && name && description) {
      if (existingMeetingCategory) {
        editMeetingCategory({ id, name, description, sourceId: existingMeetingCategory.sourceId });
      } else {
        createMeetingCategory({ id, name, description });
      }
    }
  };

  const isValidToCreate =
    isInputValueDirty &&
    Boolean(inputValues.id) &&
    Boolean(inputValues.name) &&
    Boolean(inputValues.description) &&
    Object.values(inputValidationErrors).filter(error => error).length === 0;

  return (
    <Dialog
      maxWidth='sm'
      fullWidth
      open={isOpen}
      onClose={(event: object, reason: string) => {
        reason !== 'backdropClick' && cancel();
      }}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'>
      <DialogContent className='p-8'>
        <Box className='xl:flex' justifyContent='space-between' alignItems='center'>
          <Box pr={3}>
            <span className='text-3xl font-semibold text-black'>
              {existingMeetingCategory ? 'Editing Meeting Category' : 'Create a New Meeting Category'}
            </span>
          </Box>
        </Box>
        <Grid container item direction='row'>
          <Grid item xs={12}>
            <InputFieldRow field='id' disabled={inProgress} />
            <InputFieldRow field='name' disabled={inProgress} />
            <InputFieldRow field='description' disabled={inProgress} />
          </Grid>
        </Grid>
        <Box mt={5}>
          <Divider />
          <Box mt={5} display='flex' justifyContent='center' alignItems='center'>
            <Box mx={2}>
              <Button
                id='dialog-close-button'
                onClick={cancel}
                disabled={inProgress}
                color='tertiary'
                size='medium'
                borderRadius={50}>
                Cancel
              </Button>
            </Box>
            <Box>
              <Button
                id='add-practice-button'
                size='medium'
                disabled={inProgress || !isValidToCreate}
                loading={inProgress}
                onClick={validateAndSave}
                borderRadius={50}>
                {existingMeetingCategory ? 'Save Changes' : 'Create Meeting Category'}
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
