import { createStyles, makeStyles } from '@material-ui/core';
import type { Theme } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    search: {
      position: 'relative',
      border: '1px solid rgba(0, 0, 0, 0.23)',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginLeft: 0,
      width: '100%',
      // [theme.breakpoints.up('sm')]: {
      //     width: 'auto',
      // },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      justifyContent: 'center',
      pointerEvents: 'none',
      position: 'absolute',
      alignItems: 'center',
      display: 'flex',
      height: '100%',
    },
    inputRoot: {
      color: 'inherit',
      background: '#fff',
    },
    inputField: {
      transition: theme.transitions.create('width'),
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `10px`,
      borderRadius: theme.shape.borderRadius,
      background: '#fff',
      height: 24,
    },
  }),
);
