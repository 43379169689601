import clsx from 'clsx';
import { useAuth } from 'contexts/AuthContext';
import { useHistory, useLocation } from 'react-router-dom';

import { Box } from '@material-ui/core';

import type { App } from 'services/models/auth.model';
import { navigationService } from 'services/navigation/navigation.service';

import type { RoutePath } from 'utils/constants/route.constant';

type Props = Readonly<{
  routePath: RoutePath;
  label: string;
  app: App;
  Icon: React.ComponentType;
}>;

export function AppNavLink({ routePath, label, app, Icon }: Props) {
  const history = useHistory();
  const location = useLocation();
  const { user } = useAuth();

  if (!user?.appRoles[app]) {
    return null;
  }

  return (
    <Box
      display='flex'
      alignItems='center'
      mb={4}
      className={clsx('cursor-pointer', location.pathname === routePath && 'font-bold')}
      onClick={() => {
        navigationService.toRoute(history, routePath);
      }}>
      <Icon />
      <Box ml={2}>{label}</Box>
    </Box>
  );
}
