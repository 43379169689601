export enum ProgramColumnKey {
  Name = 'Name',
  PracticeCount = 'PracticeCount',
  MeetingCategoryCount = 'MeetingCategoryCount',
}

export enum ProgramSortField {
  name = 'name',
  practiceCount = 'practiceCount',
  meetingCategoryCount = 'meetingCategoryCount',
}

export type AllowedProgramActions = Readonly<{
  delete: boolean;
  edit: Readonly<{
    startDate: boolean;
    endDate: boolean;
  }>;
}>;

export type ProgramMeetingCategoryModel = Readonly<{
  programMeetingCategoryId: string;
  id: string;
  name: string;
  description: string | null;
  startDate: string; // Will be a date
  endDate: string | null; // Will be a date or null
  paid: boolean;
  sourceId: string;
  status: string;
  allowedActions: AllowedProgramActions;
}>;

export type ProgramPracticeModel = Readonly<{
  programPracticeId: string;
  id: string;
  displayName: string;
  legalBusinessName: string;
  doingBusinessAsName: string;
  tin: string;
  startDate: string; // Will be a date
  endDate: string | null; // Will be a date or null
  sourceId: string;
  status: string;
  allowedActions: AllowedProgramActions;
}>;

export type ProgramModel = Readonly<{
  id: string;
  name: string;
}>;

export type ProgramAndRelatedItemsModel = ProgramModel &
  Readonly<{
    meetingCategories: ReadonlyArray<ProgramMeetingCategoryModel>;
    practices: ReadonlyArray<ProgramPracticeModel>;
  }>;

export type MeetingCategoryToProgramInput = Readonly<{
  meetingCategoryId?: string;
  startDate: string;
  endDate?: string | null;
  paid: boolean;
}>;

export type EditMeetingCategoryInProgramInput = MeetingCategoryToProgramInput &
  Readonly<{
    programMeetingCategoryId: string;
    sourceId: string;
  }>;

export type RemoveMeetingCategoryFromProgramInput = Readonly<{
  programMeetingCategoryId: string;
  sourceId: string;
}>;

export type AddMeetingCategoryToProgramInput = MeetingCategoryToProgramInput &
  Readonly<{
    programId?: string;
  }>;

export type PracticeToProgramInput = Readonly<{
  practiceId?: string;
  startDate: string;
  endDate?: string | null;
}>;

export type EditPracticeInProgramInput = PracticeToProgramInput &
  Readonly<{
    programPracticeId: string;
    sourceId: string;
  }>;

export type RemovePracticeFromProgramInput = Readonly<{
  programPracticeId: string;
  sourceId: string;
}>;

export type AddPracticeToProgramInput = PracticeToProgramInput &
  Readonly<{
    programId?: string;
  }>;
