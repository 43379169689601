export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MSAL_CLIENTID ?? '', // Replace with your Application (client) ID
    authority: process.env.REACT_APP_MSAL_AUTHORITY ?? '', // Replace with your Directory (tenant) ID
    redirectUri: process.env.REACT_APP_MSAL_REDIRECT_URL ?? '', // Replace with your redirect URI
  },
  cache: {
    cacheLocation: 'localStorage', // This line sets the cache location to localStorage
    storeAuthStateInCookie: false, // set to true for IE 11
  },
  system: {
    iframeHashTimeout: 20000,
    allowRedirectInIframe: true,
  },
};

export const loginRequest = {
  scopes: [`${process.env.REACT_APP_MSAL_CLIENTID as string}/.default`],
};

export const refreshloginRequest = {
  scopes: [`${process.env.REACT_APP_MSAL_CLIENTID as string}/.default`],
  prompt: 'no_session',
};

export const graphApiRequest = {
  scopes: ['User.Read'],
  authority: `${process.env.REACT_APP_MSAL_AUTHORITY as string}`,
};
