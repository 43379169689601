import axios from 'axios';

import type { ValidationError } from 'services/models/validation.model';

export type GenericMutationCallbacks<T> = Readonly<{
  onSuccess: (result: T) => void;
  onError: (error: unknown) => void;
}>;

export type GenericMutationCallbacksWithValidation<
  IssueType extends string,
  ItemType extends object,
> = GenericMutationCallbacks<ItemType> &
  Readonly<{
    onValidationError: (error: ValidationError<IssueType, ItemType>) => void;
  }>;

export function asGenericValidationError<IssueType extends string, ItemType extends object>(
  error: unknown,
): ValidationError<IssueType, ItemType> | undefined {
  if (
    error &&
    axios.isAxiosError(error) &&
    typeof error?.response?.data?.message === 'string' &&
    typeof error.response.data.type === 'string' &&
    ['Error', 'Warning'].includes(error.response.data.severity) &&
    (!error.response.data.relatedItems || Array.isArray(error.response.data.relatedItems))
  ) {
    const { message, type, severity, relatedItems } = error.response.data;
    return { message, type, severity, relatedItems };
  }

  return undefined;
}
