import axios from 'axios';
import { useAuth } from 'contexts/AuthContext';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useGate } from 'statsig-react';

import { usePracticesList } from 'services/listItems.service';
import { getMeetingCategories, useMeetingTimeEntries } from 'services/meeting-time-entry/meetingTimeEntry.service';
import type {
  CreateEntryModel,
  MeetingModel,
  ProgramMeetingCategloryModel,
  StaffModelWithDuration,
  UpdateHourlyConsultModel,
} from 'services/models/meetingTimeEntry.model';

import type { IErrorState } from 'utils/validation/error-state';
import { GeneralValidator } from 'utils/validation/general-validator';

import { createPracticeMeetingTimeEntryValidator } from './CreatePracticeMeetingTimeEntry.validation';

interface CreateAndUpdateTimeEntryDialogState extends IErrorState {
  isOpenConfirmPractice: boolean;
  createConsultParams: UpdateHourlyConsultModel;
  selectedAttendees: StaffModelWithDuration[];
  selectedId: string;
}

const initialParmas: UpdateHourlyConsultModel = {
  id: '',
  meetingCategoryId: '',
  otherCategory: '',
  meetingDate: new Date().toISOString().slice(0, 10),
  startTime: '',
  endTime: '',
  note: '',
  practiceId: '',
  practiceTin: '',
  attendees: [],
  isChanged: false,
};

export function useFacade(
  isOpen: boolean,
  editMeetingId: string,
  handleCreate: (entry: CreateEntryModel, meetingId: string) => void,
): [
  CreateAndUpdateTimeEntryDialogState,
  ProgramMeetingCategloryModel[],
  (field: string, value: any) => void,
  (selectedAttendees: StaffModelWithDuration[]) => void,
  (id: string) => void,
  (id: string, value: any) => void,
  () => boolean,
  (id: string) => void,
  () => void,
] {
  const [state, setState] = useState({
    isOpenConfirmPractice: false,
    createConsultParams: initialParmas,
    selectedAttendees: [],
    selectedId: '',
    errors: {},
  } as CreateAndUpdateTimeEntryDialogState);

  const [practiceId, setPracticeId] = useState('');
  const [meetingDate, setMeetingDate] = useState(new Date().toISOString().slice(0, 10));
  const { value: isFeatureEnabled } = useGate('pct-aco3-meeting-categories');
  const { isAuthenticated, isOpenCreateDialog, practiceTin, handleSignOut } = useAuth();
  const { meetings } = useMeetingTimeEntries();
  const { practices } = usePracticesList();
  const {
    data: meetingCategoriesResult,
    error: meetingCategoriesError,
    refetch,
  } = useQuery(
    ['get-categories', practiceId, meetingDate],
    () => {
      if (practiceId && meetingDate) {
        return getMeetingCategories(practiceId, meetingDate);
      }
      return [];
    },
    {
      enabled: !!practiceId && !!meetingDate && isFeatureEnabled,
    },
  );

  const meetingCategoriesAndPrograms = useMemo(() => meetingCategoriesResult ?? [], [meetingCategoriesResult]);

  const otherCategoryIsEntered = () => {
    if (state.createConsultParams.meetingCategoryId === 'OTHER' && !state.createConsultParams.otherCategory) {
      setState(state => ({
        ...state,
        errors: GeneralValidator.addError(state, 'otherCategory', 'Please enter a specific meeting name.'),
      }));

      return false;
    }
    return true;
  };

  const isCheckedForCreating = () =>
    Boolean(
      state.createConsultParams.meetingCategoryId &&
        state.createConsultParams.attendees.length &&
        state.createConsultParams.attendees.filter(x => x.duration).length ===
          state.createConsultParams.attendees.length &&
        state.createConsultParams.meetingDate &&
        state.createConsultParams.startTime &&
        state.createConsultParams.endTime &&
        state.createConsultParams.practiceId &&
        Object.keys(state.errors).length === 0 &&
        state.createConsultParams.isChanged &&
        (!isFeatureEnabled || (isFeatureEnabled && meetingCategoriesAndPrograms.length > 0)),
    ) && otherCategoryIsEntered();

  const isWithDuration = () => {
    let withDuration = true;
    state.createConsultParams.attendees.forEach(attendee => {
      const error = createPracticeMeetingTimeEntryValidator.validate('duration', (attendee.duration as any) ?? '');
      if (error) {
        withDuration = false;
        setState(state => ({ ...state, errors: GeneralValidator.addError(state, `duration-${attendee.id}`, error) }));
      } else {
        setState(state => ({ ...state, errors: GeneralValidator.removeError(state, `duration-${attendee.id}`) }));
      }
    });
    return withDuration;
  };

  const updateTimeValidationState = (startTime: string, endTime: string) => {
    const startDateTime = `${moment(new Date()).format('YYYY-MM-DDT')}${startTime}:00.000Z`;
    const endDateTime = `${moment(new Date()).format('YYYY-MM-DDT')}${endTime}:00.000Z`;
    if (moment(startDateTime).isSameOrAfter(moment(endDateTime))) {
      setState(state => ({
        ...state,
        errors: {
          ...state.errors,
          endTime: 'End time cannot be same as, or before, Meeting Start Time',
        },
      }));
    } else if (state.errors.endTime === 'End time cannot be same as, or before, Meeting Start Time') {
      setState(state => ({
        ...state,
        errors: GeneralValidator.removeError(state, 'endTime'),
      }));
    }
  };

  const handleChanges = (field: string, value: any) => {
    createPracticeMeetingTimeEntryValidator.validateAndSetState(state, setState, field, value);
    if (field === 'meetingDate') {
      if (value && value !== 'Invalid date') {
        state.createConsultParams.startTime &&
          createPracticeMeetingTimeEntryValidator.validateAndSetState(
            state,
            setState,
            'startTime',
            `${value}T${state.createConsultParams.startTime}:00.000Z`,
          );
        state.createConsultParams.endTime &&
          createPracticeMeetingTimeEntryValidator.validateAndSetState(
            state,
            setState,
            'endTime',
            `${value}T${state.createConsultParams.endTime}:00.000Z`,
          );
        setMeetingDate(value);
      } else {
        state.createConsultParams.startTime &&
          createPracticeMeetingTimeEntryValidator.validateAndSetState(
            state,
            setState,
            'startTime',
            `${moment().add(-1, 'days').format('YYYY-MM-DD')}T${state.createConsultParams.startTime}:00.000Z`,
          );
        state.createConsultParams.endTime &&
          createPracticeMeetingTimeEntryValidator.validateAndSetState(
            state,
            setState,
            'endTime',
            `${moment().add(-1, 'days').format('YYYY-MM-DD')}T${state.createConsultParams.endTime}:00.000Z`,
          );
      }
      if (state.createConsultParams.startTime && state.createConsultParams.endTime) {
        updateTimeValidationState(state.createConsultParams.startTime, state.createConsultParams.endTime);
      }
    }
    if (field === 'startTime' || field === 'endTime') {
      if (state.createConsultParams.meetingDate && state.createConsultParams.meetingDate !== 'Invalid date') {
        createPracticeMeetingTimeEntryValidator.validateAndSetState(
          state,
          setState,
          field,
          `${state.createConsultParams.meetingDate}T${value}:00.000Z`,
        );
      } else {
        createPracticeMeetingTimeEntryValidator.validateAndSetState(
          state,
          setState,
          field,
          `${moment().add(-1, 'days').format('YYYY-MM-DD')}T${value}:00.000Z`,
        );
      }
      const startTime = field === 'startTime' ? value : state.createConsultParams.startTime;
      const endTime = field === 'endTime' ? value : state.createConsultParams.endTime;
      if (startTime && endTime) {
        updateTimeValidationState(startTime, endTime);
      }
    }
    if (field === 'practiceId') {
      createPracticeMeetingTimeEntryValidator.validateAndSetState(state, setState, 'attendees', []);
      const item = practices?.find(i => i.id === value);
      if (item) {
        setState(state => ({
          ...state,
          createConsultParams: { ...state.createConsultParams, practiceTin: item?.tin },
        }));
      }
      setState(state => ({
        ...state,
        selectedAttendees: [],
        createConsultParams: {
          ...state.createConsultParams,
          attendees: [],
          meetingCategoryId: isFeatureEnabled ? '' : state.createConsultParams.meetingCategoryId,
        },
        isOpenConfirmPractice: false,
      }));
      setPracticeId(value);
    }
    if (field === 'meetingCategoryId') {
      setState(state => ({
        ...state,
        errors: GeneralValidator.removeError(state, 'otherCategory'),
      }));
    }
    setState(state => ({
      ...state,
      createConsultParams: {
        ...state.createConsultParams,
        [field]: value,
        isChanged:
          state.createConsultParams[field as keyof UpdateHourlyConsultModel] !== value ||
          state.createConsultParams.isChanged,
      },
    }));
  };

  const handleResetCreateParams = useCallback(
    (id: string) => {
      const item: MeetingModel | undefined = meetings?.find((x: MeetingModel) => x.id === id);
      if (item) {
        const selectedEntry = { ...initialParmas };
        const practice = practices?.find(p => p.id === item.practiceId);
        selectedEntry.id = item.id;
        selectedEntry.meetingCategoryId = item.meetingCategoryId;
        selectedEntry.otherCategory = item.otherCategory ?? '';
        selectedEntry.meetingDate = new Date(item.startedAt).toISOString().slice(0, 10);
        selectedEntry.startTime = moment(new Date(item.startedAt)).format('HH:mm');
        selectedEntry.endTime = moment(new Date(item.endedAt)).format('HH:mm');
        selectedEntry.practiceId = item.practiceId;
        selectedEntry.practiceTin = practice ? practice.tin : '';
        selectedEntry.note = item.description ?? '';
        setPracticeId(item.practiceId);
        setMeetingDate(new Date(item.startedAt).toISOString().slice(0, 10));
        if (practice && practice.staff.length) {
          const attendees = practice.staff
            .filter(s => item.meetingAttendees.find(a => s.id === a.staffId) !== undefined)
            .map(x => {
              const attendee = item.meetingAttendees.find(a => a.staffId === x.id);
              return {
                ...x,
                duration: attendee?.minutes,
              };
            });
          selectedEntry.attendees = [...attendees];
          setState(state => ({
            ...state,
            selectedAttendees: [...attendees],
          }));
        }
        setState(state => ({
          ...state,
          createConsultParams: { ...selectedEntry },
          errors: {},
        }));
        updateTimeValidationState(selectedEntry.startTime, selectedEntry.endTime);
      } else {
        const practice = practices?.find(i => i.tin === practiceTin);
        const initialEntry = { ...initialParmas };
        if (isOpenCreateDialog && practiceTin && practice) {
          initialEntry.practiceId = practice.id;
          initialEntry.practiceTin = practice ? practice.tin : '';
          setPracticeId(practice.id);
        } else {
          setPracticeId('');
        }
        setState(state => ({
          ...state,
          createConsultParams: { ...initialEntry },
          selectedAttendees: [],
          errors: {},
        }));
        refetch();
      }
    },
    [isOpenCreateDialog, meetings, practices, practiceTin, refetch], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleToggleConfirmPracticeDialog = (id: string) => {
    if (state.createConsultParams.attendees.length > 0) {
      setState(state => ({ ...state, isOpenConfirmPractice: !state.isOpenConfirmPractice, selectedId: id }));
    } else {
      handleChanges('practiceId', id);
    }
  };

  const handleSubmit = () => {
    // ENG-821: if the user not authenticated and try to create a meeting sign out
    if (!isAuthenticated) {
      handleSignOut();
      return;
    }
    createPracticeMeetingTimeEntryValidator.validateObjectAndSetState(state, setState, state.createConsultParams);
    createPracticeMeetingTimeEntryValidator.validateAndSetState(
      state,
      setState,
      'startTime',
      `${state.createConsultParams.meetingDate}T${state.createConsultParams.startTime}:00.000Z`,
    );
    createPracticeMeetingTimeEntryValidator.validateAndSetState(
      state,
      setState,
      'endTime',
      `${state.createConsultParams.meetingDate}T${state.createConsultParams.endTime}:00.000Z`,
    );
    updateTimeValidationState(state.createConsultParams.startTime, state.createConsultParams.endTime);
    const isOtherCategory = state.createConsultParams.meetingCategoryId === 'OTHER';

    if (!otherCategoryIsEntered()) {
      return;
    }

    if (!isWithDuration()) {
      return;
    }

    if (!createPracticeMeetingTimeEntryValidator.stateIsValid(state)) {
      return;
    }
    const item: MeetingModel | undefined = meetings?.find((x: MeetingModel) => x.id === state.createConsultParams.id);

    const entry: CreateEntryModel = item
      ? {
          sourceId: item.sourceId,
          meetingCategoryId: state.createConsultParams.meetingCategoryId,
          otherCategory: isOtherCategory ? state.createConsultParams.otherCategory : '',
          practiceId: state.createConsultParams.practiceId,
          startedAt: new Date(
            `${state.createConsultParams.meetingDate}T${state.createConsultParams.startTime}`,
          ).toISOString(),
          endedAt: new Date(
            `${state.createConsultParams.meetingDate}T${state.createConsultParams.endTime}`,
          ).toISOString(),
          description: state.createConsultParams.note,
          meetingAttendees: state.createConsultParams.attendees.map(a => {
            const attendee = item ? item.meetingAttendees.find(x => x.staffId === a.id) : null;
            return attendee
              ? {
                  id: attendee.id,
                  staffId: a.id,
                  minutes: a.duration ? Number(a.duration) : 0,
                }
              : {
                  staffId: a.id,
                  minutes: a.duration ? Number(a.duration) : 0,
                };
          }),
        }
      : {
          meetingCategoryId: state.createConsultParams.meetingCategoryId,
          otherCategory: isOtherCategory ? state.createConsultParams.otherCategory : '',
          practiceId: state.createConsultParams.practiceId,
          startedAt: new Date(
            `${state.createConsultParams.meetingDate}T${state.createConsultParams.startTime}`,
          ).toISOString(),
          endedAt: new Date(
            `${state.createConsultParams.meetingDate}T${state.createConsultParams.endTime}`,
          ).toISOString(),
          description: state.createConsultParams.note,
          meetingAttendees: state.createConsultParams.attendees.map(a => ({
            staffId: a.id,
            minutes: a.duration ? Number(a.duration) : 0,
          })),
        };

    if (item) {
      handleCreate(entry, item.id);
    } else {
      handleCreate(entry, '');
      if (isOpenCreateDialog) {
        handleResetCreateParams('');
      }
    }
  };

  const handleAttendeeChanges = (selectedAttendees: StaffModelWithDuration[]) => {
    createPracticeMeetingTimeEntryValidator.validateAndSetState(state, setState, 'attendees', selectedAttendees);

    setState(state => ({
      ...state,
      selectedAttendees,
      createConsultParams: {
        ...state.createConsultParams,
        attendees: selectedAttendees,
        isChanged:
          selectedAttendees.length !== state.createConsultParams.attendees.length ||
          state.createConsultParams.isChanged,
      },
    }));
  };

  const handleRemoveAttendee = (id: string) => {
    const selectedAttendees = [...state.selectedAttendees];

    if (state.selectedAttendees.find(a => a.id === id)) {
      selectedAttendees.splice(
        state.selectedAttendees.findIndex(a => a.id === id),
        1,
      );
      setState(state => ({ ...state, errors: GeneralValidator.removeError(state, `duration-${id}`) }));
    }
    createPracticeMeetingTimeEntryValidator.validateAndSetState(state, setState, 'attendees', selectedAttendees);

    setState(state => ({
      ...state,
      selectedAttendees,
      createConsultParams: {
        ...state.createConsultParams,
        attendees: selectedAttendees,
        isChanged:
          selectedAttendees.length !== state.createConsultParams.attendees.length ||
          state.createConsultParams.isChanged,
      },
    }));
  };

  const handleDurationChanges = (id: string, value: any) => {
    const item = state.selectedAttendees.find(a => a.id === id);
    if (item) {
      const isChanged = item.duration !== value || state.createConsultParams.isChanged;
      const error = createPracticeMeetingTimeEntryValidator.validate('duration', value);
      error
        ? setState(state => ({ ...state, errors: GeneralValidator.addError(state, `duration-${item.id}`, error) }))
        : setState(state => ({ ...state, errors: GeneralValidator.removeError(state, `duration-${item.id}`) }));
      item.duration = value;
      const selectedAttendees = state.selectedAttendees.map(a => (a.id === id ? item : a));
      setState(state => ({
        ...state,
        selectedAttendees,
        createConsultParams: { ...state.createConsultParams, attendees: selectedAttendees, isChanged },
      }));
    }
  };

  useEffect(() => {
    if (isOpen) {
      handleResetCreateParams(editMeetingId);
    }
  }, [isOpen, editMeetingId, handleResetCreateParams]);

  useEffect(() => {
    if (meetingCategoriesError) {
      if (axios.isAxiosError(meetingCategoriesError)) {
        if (typeof meetingCategoriesError.response?.data === 'string') {
          if (meetingCategoriesError.response.data.includes('active categories')) {
            setState(state => ({
              ...state,
              errors: {
                ...state.errors,
                activeCategories:
                  'There are no meeting categories available for this program on the selected meeting date',
              },
            }));
          } else if (meetingCategoriesError.response.data.includes('active program')) {
            setState(state => ({
              ...state,
              errors: {
                ...state.errors,
                activeProgram: 'There is no active program for this practice on the selected meeting date',
              },
            }));
          }
        }
      }
    } else if (
      state.errors.activeCategories ===
      'There are no meeting categories available for this program on the selected meeting date'
    ) {
      setState(state => ({
        ...state,
        errors: GeneralValidator.removeError(state, 'activeCategories'),
      }));
    } else if (
      state.errors.activeProgram === 'There is no active program for this practice on the selected meeting date'
    ) {
      setState(state => ({
        ...state,
        errors: GeneralValidator.removeError(state, 'activeProgram'),
      }));
    }
  }, [meetingCategoriesError, state.errors.activeCategories, state.errors.activeProgram]);

  return [
    state,
    meetingCategoriesAndPrograms,
    handleChanges,
    handleAttendeeChanges,
    handleRemoveAttendee,
    handleDurationChanges,
    isCheckedForCreating,
    handleToggleConfirmPracticeDialog,
    handleSubmit,
  ];
}
