import * as Sentry from '@sentry/react';
import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import type {
  StaffAddToPracticeInput,
  StaffCreateInput,
  StaffDeleteInput,
  StaffEditInput,
  StaffInfo,
} from 'services/models/roster.model';
import type { ValidationError } from 'services/models/validation.model';

import API from 'utils/helpers/axiosInstance';

import type { GenericMutationCallbacks, GenericMutationCallbacksWithValidation } from './rosterServiceUtils';
import { asGenericValidationError } from './rosterServiceUtils';

export enum StaffIssueType {
  DuplicateNpi = 'DuplicateNpi',
  DuplicateName = 'DuplicateName',
  DuplicateNameInPractice = 'DuplicateNameInPractice',
  CapsName = 'CapsName',
  NpiRequire = 'NpiRequire',
  AlreadyAssignedToThisPractice = 'AlreadyAssignedToThisPractice',
}
export type StaffValidationError = ValidationError<StaffIssueType, StaffInfo>;

type MutationCallbacks = GenericMutationCallbacks<StaffInfo>;
type MutationCallbacksWithValidation = GenericMutationCallbacksWithValidation<StaffIssueType, StaffInfo>;
const asValidationError = asGenericValidationError<StaffIssueType, StaffInfo>;

async function createStaffApi(input: StaffCreateInput): Promise<StaffInfo> {
  const { practiceId, ...body } = input;
  const result = await API.post<StaffInfo>(`roster/practice/${practiceId}/staff`, body);
  return result.data;
}

async function editStaffApi(input: StaffEditInput): Promise<StaffInfo> {
  const { practiceId, id, ...body } = input;
  const result = await API.put<StaffInfo>(`roster/practice/${practiceId}/staff/${id}`, body);
  return result.data;
}

async function addStaffToPracticeApi(input: StaffAddToPracticeInput): Promise<StaffInfo> {
  const { practiceId, staffId, ...body } = input;
  const result = await API.post<StaffInfo>(`roster/practice/${practiceId}/staff/${staffId}`, body);
  return result.data;
}

async function deleteStaffApi(input: StaffDeleteInput): Promise<StaffInfo> {
  const { practiceId, staffId, ...data } = input;
  const result = await API.delete<StaffInfo>(`roster/practice/${practiceId}/staff/${staffId}`, { data });
  return result.data;
}

export function useCreateStaffMutation({ onSuccess, onError, onValidationError }: MutationCallbacksWithValidation) {
  const queryClient = useQueryClient();
  const { isLoading: isCreateStaffInProgress, mutate } = useMutation(createStaffApi);
  const createStaff = useCallback(
    (input: StaffCreateInput) => {
      mutate(input, {
        onSuccess: staff => {
          queryClient.invalidateQueries('practices');
          queryClient.invalidateQueries('list/practices');
          onSuccess(staff);
        },
        onError: (error: unknown) => {
          Sentry.captureException(error);
          const validationError = asValidationError(error);
          if (validationError) {
            onValidationError(validationError);
          } else {
            onError(error);
          }
        },
      });
    },
    [mutate, queryClient, onSuccess, onError, onValidationError],
  );

  return { isCreateStaffInProgress, createStaff };
}

export function useEditStaffMutation({ onSuccess, onError, onValidationError }: MutationCallbacksWithValidation) {
  const queryClient = useQueryClient();
  const { isLoading: isEditStaffInProgress, mutate } = useMutation(editStaffApi);
  const editStaff = useCallback(
    (input: StaffEditInput) => {
      mutate(input, {
        onSuccess: staff => {
          queryClient.invalidateQueries('practices');
          queryClient.invalidateQueries('list/practices');
          onSuccess(staff);
        },
        onError: (error: unknown) => {
          Sentry.captureException(error);
          const validationError = asValidationError(error);
          if (validationError) {
            onValidationError(validationError);
          } else {
            onError(error);
          }
        },
      });
    },
    [mutate, queryClient, onSuccess, onError, onValidationError],
  );

  return { isEditStaffInProgress, editStaff };
}

export function useAddStaffToPracticeMutation({ onSuccess, onError }: MutationCallbacks) {
  const queryClient = useQueryClient();
  const { isLoading: isAddStaffToPracticeInProgress, mutate } = useMutation(addStaffToPracticeApi);
  const addStaffToPractice = useCallback(
    (input: StaffAddToPracticeInput) => {
      mutate(input, {
        onSuccess: staff => {
          queryClient.invalidateQueries('practices');
          queryClient.invalidateQueries('list/practices');
          onSuccess(staff);
        },
        onError: (error: unknown) => {
          Sentry.captureException(error);
          onError(error);
        },
      });
    },
    [mutate, queryClient, onSuccess, onError],
  );

  return { isAddStaffToPracticeInProgress, addStaffToPractice };
}

export function useDeleteStaffMutation({ onSuccess, onError }: MutationCallbacks) {
  const queryClient = useQueryClient();
  const { isLoading: isDeleteStaffInProgress, mutate } = useMutation(deleteStaffApi);
  const deleteStaff = useCallback(
    (input: StaffDeleteInput) => {
      mutate(input, {
        onSuccess: staff => {
          queryClient.invalidateQueries('practices');
          queryClient.invalidateQueries('list/practices');
          onSuccess(staff);
        },
        onError: (error: unknown) => {
          Sentry.captureException(error);
          onError(error);
        },
      });
    },
    [mutate, queryClient, onSuccess, onError],
  );
  return { isDeleteStaffInProgress, deleteStaff };
}
