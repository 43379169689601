import { addMonths, endOfDay, isAfter, isSameMonth, set } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

export function getDeadlineLocal(): Date {
  // Get current date in PT
  const ptNow = utcToZonedTime(new Date(), 'America/Los_Angeles');

  // Get cut-off date for this month
  let cutOffDate = endOfDay(set(ptNow, { date: 3 }));

  // If we're past the cut-off, get cut-off for next month
  if (isAfter(ptNow, cutOffDate)) {
    cutOffDate = endOfDay(set(addMonths(cutOffDate, 1), { date: 3 }));
  }

  // Convert cut-off date back to UTC
  const cutOffDateUtc = zonedTimeToUtc(cutOffDate, 'America/Los_Angeles');

  // Convert to local time
  return new Date(cutOffDateUtc);
}

export function getCurrentStatementMonth(): string {
  const currentDate = new Date();
  const currentDay = currentDate.getDate();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  if (currentDay > 3) {
    const nextMonthDate = new Date(currentYear, currentMonth + 1);
    return `${nextMonthDate.toLocaleString('default', { month: 'long' })} ${nextMonthDate.getFullYear()}`;
  }
  return `${currentDate.toLocaleString('default', { month: 'long' })} ${currentYear}`;
}

export function getCurrentPerformanceMonth(): string {
  const currentDate = new Date();
  const currentDay = currentDate.getDate();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  if (currentDay > 3) {
    return currentDate.toLocaleString('default', { month: 'long' });
  }
  const prevMonth = currentMonth === 0 ? 11 : currentMonth - 1; // Handle January as special case
  const prevMonthDate = new Date(currentYear, prevMonth);
  return prevMonthDate.toLocaleString('default', { month: 'long' });
}

export function determineStatementMonth(meetingDate: Date): string {
  const meetingDateObj = utcToZonedTime(meetingDate, 'America/Los_Angeles');
  const deadlineDate = getDeadlineLocal();

  if (isSameMonth(meetingDateObj, deadlineDate)) {
    return `${addMonths(deadlineDate, 1).toLocaleString('default', { month: 'long' })} ${addMonths(
      deadlineDate,
      1,
    ).getFullYear()}`;
  }

  return `${deadlineDate.toLocaleString('default', { month: 'long' })} ${deadlineDate.getFullYear()}`;
}
