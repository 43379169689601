import { useContractRolesList } from 'services/listItems.service';

import { BanzaiDropDown } from './BanzaiDropDown';

type Props = Readonly<{
  label?: string;
  selectedId?: string;
  errorMessage?: string | null;
  disabled?: boolean;
  onSelected: (id: string) => void;
}>;

export function SelectContractRoleDropDown({ label, selectedId, errorMessage, disabled, onSelected }: Props) {
  const { isLoading, contractRoles } = useContractRolesList();

  return (
    <BanzaiDropDown
      id='contractRole'
      label={label || 'Contract Role'}
      placeholder='– Select Contract Role –'
      items={contractRoles}
      value={selectedId}
      disabled={disabled || isLoading || !contractRoles?.length}
      errorMessage={errorMessage}
      onSelected={onSelected}
    />
  );
}
