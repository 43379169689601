import { useMemo, useState } from 'react';
import { setPracticeFilters } from 'slices/roster';
import { useDispatch, useSelector } from 'stores';

import { Box, Checkbox, TextField } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import SearchIcon from '@material-ui/icons/Search';
import { Autocomplete } from '@material-ui/lab';

import { usePractices } from 'services/roster';

import { Button } from 'components/Button';

import { useStyles } from './styles';

export function PracticeFilterToolbar() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isLoading, practices } = usePractices();

  const practiceFilters = useSelector(store => store.roster.practiceFilters);
  const [workingFilters, setWorkingFilters] = useState<typeof practiceFilters>(practiceFilters);

  const allPracticeTins = useMemo(() => practices?.map(practice => practice.tin)?.sort(), [practices]);
  const allStaffNpis = useMemo(() => {
    const npis = practices?.reduce((npiSet, practice) => {
      practice.staff.forEach(staff => staff.npi && npiSet.add(staff.npi));
      return npiSet;
    }, new Set<string>());
    return npis && Array.from(npis).sort();
  }, [practices]);

  const submitFilter = () => {
    dispatch(setPracticeFilters(workingFilters));
  };

  const setFreeTextFilter = (id: 'name' | 'staffName', value: string) => {
    setWorkingFilters({ ...workingFilters, [id]: value || undefined });
  };

  const setMultiSelectFilter = (id: 'tin' | 'staffNpi', values: (string | undefined)[]) => {
    const validValues = values.filter(value => value);
    setWorkingFilters({ ...workingFilters, [id]: validValues.length !== 0 ? validValues : undefined });
  };

  const onKeyUp = ({ key }: { key: string }) => {
    if (key === 'Enter') {
      submitFilter();
    }
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
  const checkedIcon = <CheckBoxIcon fontSize='small' className='text-main' />;

  return (
    <Box mt={4} display='flex'>
      <Box width='25%'>
        <Box className='text-base font-semibold text-black1' mb={0.5}>
          Practice Name:
        </Box>
        <TextField
          id='name'
          fullWidth
          variant='outlined'
          classes={{ root: classes.root }}
          disabled={isLoading}
          size='small'
          placeholder='All Practices'
          value={workingFilters.name}
          onChange={event => setFreeTextFilter('name', event.target.value)}
          onKeyUp={onKeyUp}
          InputProps={{ classes: { input: classes.input } }}
        />
      </Box>
      <Box width='25%' ml={3}>
        <Box className='text-base font-semibold text-black1' mb={0.5}>
          TIN:
        </Box>
        <Autocomplete
          id='tin'
          fullWidth
          multiple
          disableCloseOnSelect
          classes={{ root: classes.root }}
          disabled={isLoading}
          size='small'
          value={(workingFilters.tin || []) as string[]}
          options={allPracticeTins || []}
          renderTags={() => <div />}
          renderInput={params => (
            <TextField
              {...params}
              variant='outlined'
              placeholder={workingFilters.tin?.length ? `${workingFilters.tin.length} Selected` : 'All Practice TINs'}
              InputProps={{ ...params.InputProps, type: 'input', classes: { input: classes.input } }}
              onKeyUp={onKeyUp}
            />
          )}
          renderOption={(option, { selected }) => (
            <>
              <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} className='mr-2' />
              {option}
            </>
          )}
          onChange={(event, value) => setMultiSelectFilter('tin', value)}
        />
      </Box>
      <Box width='25%' ml={3}>
        <Box className='text-base font-semibold text-black1' mb={0.5}>
          Staff Name:
        </Box>
        <TextField
          id='staffName'
          fullWidth
          variant='outlined'
          classes={{ root: classes.root }}
          disabled={isLoading}
          size='small'
          placeholder='All Staff Names'
          value={workingFilters.staffName}
          onChange={event => setFreeTextFilter('staffName', event.target.value)}
          onKeyUp={onKeyUp}
          InputProps={{ classes: { input: classes.input } }}
        />
      </Box>
      <Box width='25%' ml={3}>
        <Box className='text-base font-semibold text-black1' mb={0.5}>
          Staff NPI:
        </Box>
        <Autocomplete
          id='staffNpi'
          fullWidth
          multiple
          disableCloseOnSelect
          classes={{ root: classes.root }}
          disabled={isLoading}
          size='small'
          value={(workingFilters.staffNpi || []) as string[]}
          options={allStaffNpis || []}
          renderTags={() => <div />}
          renderInput={params => (
            <TextField
              {...params}
              variant='outlined'
              placeholder={
                workingFilters.staffNpi?.length ? `${workingFilters.staffNpi.length} Selected` : 'All Staff NPIs'
              }
              InputProps={{ ...params.InputProps, type: 'input', classes: { input: classes.input } }}
              onKeyUp={onKeyUp}
            />
          )}
          renderOption={(option, { selected }) => (
            <>
              <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} className='mr-2' />
              {option}
            </>
          )}
          onChange={(event, value) => setMultiSelectFilter('staffNpi', value)}
        />
      </Box>
      <Box display='flex' alignItems='flex-end' ml={3} gridGap={10}>
        <Button
          id='search-button'
          color='black'
          borderRadius={50}
          size='medium'
          disabled={isLoading}
          onClick={submitFilter}>
          <Box display='flex' alignItems='center'>
            <SearchIcon />
            <span className='ml-1'>Search</span>
          </Box>
        </Button>
      </Box>
    </Box>
  );
}
