import { makeStyles } from '@material-ui/core/styles';

import { colors } from 'utils/constants/colors.constant';

export const useStyles = makeStyles(theme => ({
  root: {
    borderColor: colors.gray3,
    borderRadius: 4,
    '& .MuiOutlinedInput-root': {
      minHeight: 45,
      borderColor: colors.gray3,
      borderRadius: 4,
      color: colors.black2,
      '&.Mui-focused fieldset': {
        borderColor: colors.main,
      },
      '& .MuiPickersDay-daySelected': {
        backgroundColor: colors.main,
      },
    },
    '& .MuiIconButton-root': {
      '& .MuiPickersDay-daySelected': {
        backgroundColor: colors.main,
      },
    },
  },
  input: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&::placeholder': {
      textOverflow: 'ellipsis !important',
      color: colors.black2,
      opacity: 1,
    },
  },
  customTooltip: {
    backgroundColor: colors.divider,
    maxWidth: 'none',
    padding: 12,
    color: colors.black1,
    fontSize: 16,
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  arrow: {
    color: colors.divider,
  },
  attendeeSetion: {
    paddingRight: 60,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 20,
      paddingBottom: 20,
    },
  },
  descriptionTooltip: {
    backgroundColor: colors.black,
    maxWidth: 325,
    padding: 12,
    color: colors.white,
    fontSize: 14,
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  descriptionTooltipArrow: {
    color: colors.black,
  },
}));
