import clsx from 'clsx';
import React from 'react';

import { Box, Checkbox, Grid } from '@material-ui/core';

import { ValidationSeverity } from 'services/models/validation.model';

import { Button } from 'components/Button';

type RelatedItemFieldColumn<ItemType extends { id: string }> = Readonly<{
  type: 'field';
  heading: string;
  field: keyof ItemType;
  width: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
}>;

type SelectRelatedItemColumn<ItemType extends { id: string }> = Readonly<{
  type: 'select';
  heading: string;
  buttonText: string;
  width: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  onSelected: (item: ItemType) => void;
}>;

export type RelatedItemColumn<ItemType extends { id: string }> =
  | RelatedItemFieldColumn<ItemType>
  | SelectRelatedItemColumn<ItemType>;

type Props<ItemType extends { id: string }> = Readonly<{
  severity: ValidationSeverity;
  message: string;
  isOverrideWarnings: boolean;
  relatedItems?: ReadonlyArray<ItemType>;
  relatedItemColumns: ReadonlyArray<RelatedItemColumn<ItemType>>;
  setOverrideWarnings: (value: boolean) => void;
}>;

export function ValidationErrorInfo<ItemType extends { id: string }>({
  message,
  severity,
  relatedItems,
  isOverrideWarnings,
  relatedItemColumns,
  setOverrideWarnings,
}: Props<ItemType>) {
  return (
    <Box
      mt={3}
      p={3}
      className={clsx(
        'w-full',
        'border-4',
        'rounded-md',
        severity === ValidationSeverity.Warning ? 'border-warning' : 'border-error',
      )}>
      <Box
        mb={1}
        className={clsx(
          'text-center',
          'font-semibold',
          'text-2xl',
          severity === ValidationSeverity.Warning ? 'text-warning' : 'text-error',
        )}>
        Validation {severity}
      </Box>
      <Box mt={3} className='font-semibold'>
        {message}
      </Box>
      {relatedItems?.length && (
        <Box mt={3}>
          <Grid container spacing={1} className='border-2'>
            {relatedItemColumns.map((column, columnIndex) => (
              <Grid
                key={`${column.type === 'field' ? column.field.toString() : 'select'}-heading`}
                item
                xs={column.width}
                className={clsx('text-center', 'font-semibold', columnIndex !== 0 && 'border-l-2')}>
                {column.heading}
              </Grid>
            ))}
            {relatedItems.map(item =>
              relatedItemColumns.map((column, columnIndex) =>
                column.type === 'field' ? (
                  <Grid
                    key={`${item.id}-${column.field.toString()}`}
                    item
                    xs={column.width}
                    className={clsx('border-t-2', columnIndex !== 0 && 'border-l-2')}>
                    {item[column.field] as React.ReactNode}
                  </Grid>
                ) : (
                  <Grid
                    key={`${item.id}-select`}
                    item
                    xs={column.width}
                    className={clsx('border-t-2', 'text-center', columnIndex !== 0 && 'border-l-2')}>
                    <Button
                      id='selectRelatedItem'
                      size='small'
                      borderRadius={50}
                      onClick={() => column.onSelected(item)}>
                      {column.buttonText}
                    </Button>
                  </Grid>
                ),
              ),
            )}
          </Grid>
        </Box>
      )}
      {severity === ValidationSeverity.Warning && (
        <Box mt={5}>
          <Box>
            If this is intentional, you may click below to override this warning and then attempt to create the Record
            again.
          </Box>
          <Box mt={1}>Please double-check that the information is correct before doing so.</Box>
          <Box mt={5} className='flex flex-row items-center'>
            <Checkbox
              id='overrideWarnings'
              checked={isOverrideWarnings}
              onChange={event => setOverrideWarnings(event.target.checked)}
              color='primary'
              className='p-0'
            />
            <Box ml={1} className='mt-1 font-semibold'>
              Override this Warning
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}
