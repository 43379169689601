/* eslint-disable @typescript-eslint/no-extraneous-class */
export class TitlesConstants {
  public static simpleBaseTitle = 'Vytalize Health';

  public static loginTitle = `${TitlesConstants.simpleBaseTitle} - Login`;

  public static hourlyConsult = `${TitlesConstants.simpleBaseTitle} - Practice Collaboration`;

  public static roster = `${TitlesConstants.simpleBaseTitle} - Roster`;

  public static roleAndPermissions = `${TitlesConstants.simpleBaseTitle} - Role and Permissions`;

  public static rosterSupport = `${TitlesConstants.simpleBaseTitle} - Programs`;

  public static meetingCategories = `${TitlesConstants.simpleBaseTitle} - Meeting Categories`;

  public static errorTitle = `${TitlesConstants.simpleBaseTitle} - Warning`;
}
