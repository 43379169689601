import { useAuth } from 'contexts/AuthContext';
import React from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import { App } from 'services/models/auth.model';

import { LoginPage } from 'pages/LoginPage';
import { MeetingCategoryPage } from 'pages/MeetingCategoryPage';
import NotFoundView from 'pages/NotFoundPage';
import { PracticeCollaborationTrackerPage } from 'pages/PracticeCollaborationTrackerPage';
import { CreateNewTimeEntryForSF } from 'pages/PracticeCollaborationTrackerPage/CreateNewTimeEntryForSF';
import { ProgramPage } from 'pages/ProgramPage';
import { RoleAndPermissionsPage } from 'pages/RoleAndPermissionPage';
import { RosterPage } from 'pages/RosterPage';

import { RoutePath } from 'utils/constants/route.constant';

type AppRoute = Readonly<{
  path: RoutePath;
  app: App;
  Component: React.ComponentType;
}>;

const APP_ROUTES: ReadonlyArray<AppRoute> = [
  {
    path: RoutePath.PracticeCollaboration,
    app: App.PCT,
    Component: PracticeCollaborationTrackerPage,
  },
  {
    path: RoutePath.PctCreateIframe,
    app: App.PCT,
    Component: CreateNewTimeEntryForSF,
  },
  {
    path: RoutePath.Roster,
    app: App.ROSTER,
    Component: RosterPage,
  },
  {
    path: RoutePath.Users,
    app: App.USERS,
    Component: RoleAndPermissionsPage,
  },
  {
    path: RoutePath.Programs,
    app: App.PROGRAMS,
    Component: ProgramPage,
  },
  {
    path: RoutePath.MeetingCategories,
    app: App.MEETING_CATEGORIES,
    Component: MeetingCategoryPage,
  },
];

function NotFoundRedirect() {
  const history = useHistory();

  history.push(RoutePath.NotFound);
  return null;
}

function DefaultRouteRedirect() {
  const { user, isAuthenticated } = useAuth();
  const isOpenIframe = window.sessionStorage.getItem('isOpenIframe');
  const isOpenCreateDialog = window.sessionStorage.getItem('isOpenCreateDialog');
  const practiceTin = window.sessionStorage.getItem('practiceTin');
  if (!isAuthenticated) {
    return <LoginPage />;
  }

  if (isOpenCreateDialog) {
    return <Redirect to={`${RoutePath.PctCreateIframe}?iframeCreate=open&practiceTin=${practiceTin}`} />;
  }

  if (isOpenIframe) {
    return <Redirect to={`${RoutePath.PracticeCollaboration}?iframe=open`} />;
  }

  // Find the first route that the user has access to
  const firstAppRoute = APP_ROUTES.find(route => user?.appRoles[route.app]);

  if (firstAppRoute?.path) {
    // Redirect the user to the first route they have access to
    return <Redirect to={firstAppRoute.path} />;
  }

  // Optionally, redirect the user to a different page or log them out
  return <Redirect to={RoutePath.NotFound} />;
}

export function Routes() {
  const { user, isAuthenticated } = useAuth();
  if (!isAuthenticated) {
    return <LoginPage />;
  }

  return (
    <Switch>
      {APP_ROUTES.filter(route => user?.appRoles[route.app]).map(route => (
        <Route key={route.path} path={route.path}>
          <route.Component />
        </Route>
      ))}
      <Route path={RoutePath.Default} exact>
        <DefaultRouteRedirect />
      </Route>
      <Route path={RoutePath.NotFound} exact>
        <NotFoundView />
      </Route>
      <Route>
        <NotFoundRedirect />
      </Route>
    </Switch>
  );
}
