import type { PracticeModel } from 'services/models/meetingTimeEntry.model';
import type { PracticeSortField } from 'services/models/roster.model';
import { PracticeColumnKey } from 'services/models/roster.model';

import type { ColumnDefinition } from 'components/BanzaiTable';

export const PRACTICE_COLUMN_DEFINITIONS: ReadonlyArray<
  ColumnDefinition<PracticeColumnKey, PracticeSortField, PracticeModel>
> = [
  {
    key: PracticeColumnKey.DisplayName,
    title: 'Practice (Legal Business Name)',
    width: 0.55,
    extractCellContents: practice => ({
      primary: { text: practice.legalBusinessName },
    }),
  },
  {
    key: PracticeColumnKey.TIN,
    title: 'TIN',
    width: 0.25,
    extractCellContents: practice => ({ primary: { text: practice.tin } }),
  },
];
