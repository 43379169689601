import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { Color } from '@material-ui/lab';

export const SnackType = {
  success: 'success' as Color,
  info: 'info' as Color,
  warning: 'warning' as Color,
  error: 'error' as Color,
};

export type VerPosition = 'top' | 'bottom';
export type HorPosition = 'left' | 'center' | 'right';

interface SnackState {
  isOpen: boolean;
  message: string;
  type: Color;
  duration: number;
  vertical: VerPosition;
  horizontal: HorPosition;
}

const initialState: SnackState = {
  isOpen: false,
  message: '',
  vertical: 'bottom',
  horizontal: 'center',
  type: SnackType.success,
  duration: 3500,
};

const slice = createSlice({
  name: 'snack',
  initialState,
  reducers: {
    showSuccess(state: SnackState, action: PayloadAction<{ message: string; duration?: number }>) {
      const { message, duration = 3000 } = action.payload;

      state.type = SnackType.success;
      state.duration = duration;
      state.message = message;
      state.isOpen = true;
    },
    showError(state: SnackState, action: PayloadAction<{ message: string; duration?: number }>) {
      const { message, duration = 3000 } = action.payload;

      state.type = SnackType.error;
      state.duration = duration;
      state.message = message;
      state.isOpen = true;
    },
    showWarning(state: SnackState, action: PayloadAction<{ message: string; duration?: number }>) {
      const { message, duration = 3000 } = action.payload;

      state.type = SnackType.warning;
      state.duration = duration;
      state.message = message;
      state.isOpen = true;
    },
    showInfo(state: SnackState, action: PayloadAction<{ message: string; duration?: number }>) {
      const { message, duration = 3000 } = action.payload;

      state.type = SnackType.info;
      state.duration = duration;
      state.message = message;
      state.isOpen = true;
    },
    closeSnack(state: SnackState) {
      state.isOpen = false;
    },
  },
});

export const { reducer } = slice;

export const { showSuccess, showError, showWarning, showInfo, closeSnack } = slice.actions;

export default slice.reducer;
