import moment from 'moment';

import { Box, Checkbox, Grid, TextField, Tooltip } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { useMeetingCategoriesList, usePracticesList } from 'services/listItems.service';
import type { MeetingsSearchState, PracticeModel, StaffModel } from 'services/models/meetingTimeEntry.model';
import { SortingDirection } from 'services/models/sorting.model';

import { handleCompare } from 'utils/helpers/handleCompare';

import { useStyles } from '../PracticeCollaborationTrackerPage.styles';

type FilterAndSearchSectionComponentProps = Readonly<{
  isLoading: boolean;
  filters: MeetingsSearchState;
  handleChanges: (field: string, value: any) => void;
}>;

export function FilterAndSearchSectionComponent({
  isLoading,
  filters,
  handleChanges,
}: FilterAndSearchSectionComponentProps) {
  const { isLoading: isMeetingCategoriesLoading, meetingCategories } = useMeetingCategoriesList();
  const { isLoading: isPracticesLoading, practices } = usePracticesList();

  const start = moment('2023-06', 'YYYY-MM');
  const end = moment();

  const monthlyStatements: string[] = [];
  monthlyStatements.push(start.format('YYYY-MM'));
  while (start.isBefore(end)) {
    start.add(1, 'months');
    monthlyStatements.push(start.format('YYYY-MM'));
  }

  const staffs = filters.practices.length
    ? filters.practices
        .reduce((a: StaffModel[], b: PracticeModel) => [...a, ...b.staff], [])
        ?.sort((s1, s2) => handleCompare(s1, s2, SortingDirection.Asc, 'displayName'))
    : [];

  const classes = useStyles();

  const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
  const checkedIcon = <CheckBoxIcon fontSize='small' className='text-main' />;

  return (
    <Box flex={1}>
      <Grid container item direction='row' spacing={2}>
        <Grid item xs={6} sm={6} md={3} lg={3}>
          <Box className='text-base font-semibold text-black1' mb={0.5}>
            Monthly Statement
          </Box>
          <Autocomplete
            fullWidth
            classes={{ root: classes.root }}
            disabled={isLoading}
            size='small'
            multiple
            id='monthly-statement-checkbox-autocomplete'
            value={filters.statementMonth}
            options={[...filters.statementMonth]
              ?.sort((p1, p2) => new Date(p1).getTime() - new Date(p2).getTime())
              .concat(
                monthlyStatements
                  .filter(x => !filters.statementMonth.includes(x))
                  ?.sort((p1, p2) => new Date(p1).getTime() - new Date(p2).getTime()),
              )}
            disableCloseOnSelect
            getOptionLabel={option => moment.utc(new Date(option)).format('MMMM YYYY')}
            renderTags={() => <div />}
            renderOption={(option, { selected }) => (
              <>
                <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} className='mr-2' />
                {moment.utc(new Date(option)).format('MMMM YYYY')}
              </>
            )}
            renderInput={params => (
              <TextField
                {...params}
                variant='outlined'
                placeholder={
                  filters.statementMonth.length
                    ? `${filters.statementMonth.length} ${
                        filters.statementMonth.length > 1 ? 'Monthly Statements' : 'Monthly Statement'
                      } Selected`
                    : 'All Monthly Statements'
                }
                InputProps={{ ...params.InputProps, type: 'input', classes: { input: classes.input } }}
              />
            )}
            onChange={(event, value) => {
              handleChanges('statementMonth', value);
            }}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={3}>
          <Box className='flex items-center text-base font-semibold text-black1' mb={0.5}>
            Meeting Category
          </Box>
          <Autocomplete
            fullWidth
            classes={{ root: classes.root }}
            disabled={isLoading || isMeetingCategoriesLoading}
            size='small'
            multiple
            id='meeting-category-checkbox-autocomplete'
            value={filters.meetingCategories}
            options={[...filters.meetingCategories]
              ?.sort((p1, p2) => handleCompare(p1, p2, SortingDirection.Asc, 'name'))
              .concat(
                (meetingCategories || []).filter(x => filters.meetingCategories.find(c => c.id === x.id) === undefined),
              )}
            disableCloseOnSelect
            getOptionLabel={option => option.name}
            renderTags={() => <div />}
            renderOption={(option, { selected }) => {
              if (option.description) {
                return (
                  <>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} className='mr-2' />
                    <Tooltip
                      title={<Box className='text-white'>{option.description}</Box>}
                      placement='right'
                      arrow
                      classes={{ tooltip: classes.descriptionTooltip, arrow: classes.descriptionTooltipArrow }}>
                      <Box width={1}>{option.name}</Box>
                    </Tooltip>
                  </>
                );
              }
              return (
                <>
                  <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} className='mr-2' />
                  {option.name}
                </>
              );
            }}
            renderInput={params => (
              <TextField
                {...params}
                variant='outlined'
                placeholder={
                  filters.meetingCategories.length
                    ? `${filters.meetingCategories.length} ${
                        filters.meetingCategories.length > 1 ? 'Categories' : 'Category'
                      } Selected`
                    : 'All Meeting Categories'
                }
                InputProps={{ ...params.InputProps, type: 'input', classes: { input: classes.input } }}
              />
            )}
            onChange={(event, value) => {
              handleChanges('meetingCategories', value);
            }}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={3}>
          <Box className='text-base font-semibold text-black1' mb={0.5}>
            Practice
          </Box>
          <Autocomplete
            fullWidth
            classes={{ root: classes.root }}
            disabled={isLoading || isPracticesLoading}
            size='small'
            multiple
            id='practice-checkbox-autocomplete'
            value={filters.practices}
            options={[...filters.practices]
              ?.sort((p1, p2) => handleCompare(p1, p2, SortingDirection.Asc, 'legalBusinessName'))
              .concat((practices || []).filter(x => filters.practices.find(c => c.id === x.id) === undefined))}
            disableCloseOnSelect
            getOptionLabel={option => option.legalBusinessName}
            renderTags={() => <div />}
            renderOption={(option, { selected }) => (
              <>
                <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} className='mr-2' />
                {option.legalBusinessName}
              </>
            )}
            renderInput={params => (
              <TextField
                {...params}
                variant='outlined'
                placeholder={
                  filters.practices.length
                    ? `${filters.practices.length} ${filters.practices.length > 1 ? 'Practices' : 'Practice'} Selected`
                    : 'All Practices'
                }
                InputProps={{ ...params.InputProps, type: 'input', classes: { input: classes.input } }}
              />
            )}
            onChange={(event, value) => {
              handleChanges('practices', value);
            }}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={3}>
          <Box className='text-base font-semibold text-black1' mb={0.5}>
            Attendees
          </Box>

          <Autocomplete
            fullWidth
            classes={{ root: classes.root }}
            disabled={isLoading || isPracticesLoading || !filters.practices.length}
            size='small'
            multiple
            id='attendee-checkbox-autocomplete'
            value={filters.staffs}
            options={[...filters.staffs]
              ?.sort((p1, p2) => handleCompare(p1, p2, SortingDirection.Asc, 'displayName'))
              .concat(staffs.filter(x => filters.staffs.find(c => c.id === x.id) === undefined))}
            disableCloseOnSelect
            getOptionLabel={option => option?.displayName}
            renderTags={() => <div />}
            renderOption={(option, { selected }) => (
              <>
                <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} className='mr-2' />
                {option?.displayName}
              </>
            )}
            renderInput={params => (
              <TextField
                {...params}
                variant='outlined'
                placeholder={
                  filters.staffs.length
                    ? `${filters.staffs.length} ${filters.staffs.length > 1 ? 'Attendees' : 'Attendee'} Selected`
                    : 'All Attendees'
                }
                InputProps={{ ...params.InputProps, type: 'input', classes: { input: classes.input } }}
              />
            )}
            onChange={(event, value) => {
              handleChanges('staffs', value);
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
