import moment from 'moment';
import { useState } from 'react';
import { closeAdditionalSearchDialog, setAdditionalFilters } from 'slices/practiceCollaboration';
import { useDispatch, useSelector } from 'stores';

import { Box, Checkbox, Dialog, DialogContent, FormControl, TextField } from '@material-ui/core';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker } from '@material-ui/pickers';

import { useContractRolesList, useUsersList } from 'services/listItems.service';
import type { AdditionalFilterState } from 'services/models/meetingTimeEntry.model';
import { SortingDirection } from 'services/models/sorting.model';

import { Button } from 'components/Button';

import { handleCompare } from 'utils/helpers/handleCompare';

import { useStyles } from '../PracticeCollaborationTrackerPage.styles';

interface AdditionalSearchFilterDialogProps {
  isLoading: boolean;
}

export function AdditionalSearchFilterDialog({ isLoading }: AdditionalSearchFilterDialogProps) {
  const dispatch = useDispatch();
  const { isAdditionalSearchDialogOpen, additionalFilters } = useSelector(state => state.practiceCollaboration);

  const { isLoading: isContractRolesLoading, contractRoles } = useContractRolesList();
  const { isLoading: isUsersLoading, users } = useUsersList();
  const [state, setstate] = useState<AdditionalFilterState>(additionalFilters);

  const classes = useStyles();

  const handleChanges = (field: string, value: any) => {
    setstate({ ...state, [field]: value });
  };

  const handleClose = () => {
    setstate({ ...additionalFilters });
    dispatch(closeAdditionalSearchDialog());
  };

  const controlIcon = <CheckBoxOutlineBlankIcon className='text-mediumGray' />;
  const controlCheckedIcon = <CheckBoxIcon className='text-main' />;

  const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
  const checkedIcon = <CheckBoxIcon fontSize='small' className='text-main' />;

  return (
    <Dialog
      fullWidth
      open={isAdditionalSearchDialogOpen}
      onClose={(e, reason: string) => {
        reason !== 'backdropClick' && handleClose();
      }}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'>
      <DialogContent className='p-8'>
        <Box>
          <span className='text-2xl font-semibold text-black'>Additional Search Filters</span>
        </Box>
        <Box mt={10}>
          <Box mb={4}>
            <Box className='text-base font-semibold text-black1' mb={0.5}>
              Entered By
            </Box>
            <Autocomplete
              fullWidth
              classes={{ root: classes.root }}
              disabled={isLoading || isUsersLoading}
              size='small'
              multiple
              id='entered-by-checkbox-autocomplete'
              value={state.enteredBy}
              options={[...state.enteredBy]
                ?.sort((p1, p2) => handleCompare(p1, p2, SortingDirection.Asc, 'firstName'))
                .concat((users || []).filter(x => state.enteredBy.find(c => c.id === x.id) === undefined))}
              disableCloseOnSelect
              getOptionLabel={option => `${option.firstName} ${option.lastName}`}
              renderTags={() => <div />}
              renderOption={(option, { selected }) => (
                <>
                  <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} className='mr-2' />
                  {option.firstName} {option.lastName}
                </>
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  variant='outlined'
                  placeholder={
                    state.enteredBy.length
                      ? `${state.enteredBy.length} ${state.enteredBy.length > 1 ? 'Users' : 'User'} Selected`
                      : 'All Users'
                  }
                  InputProps={{ ...params.InputProps, type: 'input', classes: { input: classes.input } }}
                />
              )}
              onChange={(event, value) => {
                handleChanges('enteredBy', value);
              }}
            />
          </Box>
          <Box mb={4}>
            <Box className='text-base font-semibold text-black1' mb={0.5}>
              Time Entry Created
            </Box>
            <Box className='flex'>
              <Box flex={1}>
                <FormControl variant='outlined' size='small' fullWidth classes={{ root: classes.root }}>
                  <KeyboardDatePicker
                    disableToolbar
                    disableFuture
                    variant='inline'
                    fullWidth
                    size='small'
                    inputVariant='outlined'
                    format='MM/DD/YYYY'
                    placeholder='MM/DD/YYYY'
                    id='entryCreatedStart'
                    value={state.entryCreatedStart}
                    disabled={isLoading}
                    onChange={momentDate => {
                      handleChanges('entryCreatedStart', momentDate === null ? '' : momentDate.format('YYYY-MM-DD'));
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                      edge: 'end',
                    }}
                    keyboardIcon={<CalendarTodayIcon />}
                    helperText={null}
                    error={false}
                  />
                </FormControl>
              </Box>
              <Box px={2} pt={2.5}>
                to
              </Box>
              <Box flex={1}>
                <FormControl variant='outlined' size='small' fullWidth classes={{ root: classes.root }}>
                  <KeyboardDatePicker
                    disableToolbar
                    disableFuture
                    minDate={moment(state.entryCreatedStart)}
                    variant='inline'
                    fullWidth
                    size='small'
                    inputVariant='outlined'
                    format='MM/DD/YYYY'
                    placeholder='MM/DD/YYYY'
                    id='entryCreatedEnd'
                    value={state.entryCreatedEnd}
                    disabled={isLoading || !state.entryCreatedStart} // Disable if loading or if start date is not selected
                    maxDate={
                      state.entryCreatedStart ? moment(state.entryCreatedStart).add(2, 'months').toDate() : undefined
                    }
                    onChange={momentDate => {
                      // Check if the date entered exceeds the maxDate
                      const maxDate = moment(state.entryCreatedStart).add(2, 'months');
                      if (momentDate && momentDate.isValid() && momentDate > maxDate) {
                        // Automatically adjust the end date to the max allowed date
                        handleChanges('entryCreatedEnd', maxDate.format('YYYY-MM-DD'));
                      } else {
                        handleChanges('entryCreatedEnd', momentDate === null ? '' : momentDate.format('YYYY-MM-DD'));
                      }
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                      edge: 'end',
                    }}
                    keyboardIcon={<CalendarTodayIcon />}
                    helperText={null}
                    error={false}
                  />
                </FormControl>
              </Box>
            </Box>
          </Box>
          <Box mb={4}>
            <Box className='text-base font-semibold text-black1' mb={0.5}>
              Meeting Date Range
            </Box>
            <Box className='flex'>
              <Box flex={1}>
                <FormControl variant='outlined' size='small' fullWidth classes={{ root: classes.root }}>
                  <KeyboardDatePicker
                    disableToolbar
                    disableFuture
                    variant='inline'
                    fullWidth
                    size='small'
                    inputVariant='outlined'
                    format='MM/DD/YYYY'
                    placeholder='MM/DD/YYYY'
                    id='meetingRangeStart'
                    value={state.meetingRangeStart}
                    disabled={isLoading}
                    onChange={momentDate => {
                      handleChanges('meetingRangeStart', momentDate === null ? '' : momentDate.format('YYYY-MM-DD'));
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                      edge: 'end',
                    }}
                    keyboardIcon={<CalendarTodayIcon />}
                    helperText={null}
                    error={false}
                  />
                </FormControl>
              </Box>
              <Box px={2} pt={2.5}>
                to
              </Box>
              <Box flex={1}>
                <FormControl variant='outlined' size='small' fullWidth classes={{ root: classes.root }}>
                  <KeyboardDatePicker
                    disableToolbar
                    disableFuture
                    minDate={moment(state.meetingRangeStart)}
                    variant='inline'
                    fullWidth
                    size='small'
                    inputVariant='outlined'
                    format='MM/DD/YYYY'
                    placeholder='MM/DD/YYYY'
                    id='meetingRangeEnd'
                    value={state.meetingRangeEnd}
                    disabled={isLoading}
                    onChange={momentDate => {
                      handleChanges('meetingRangeEnd', momentDate === null ? '' : momentDate.format('YYYY-MM-DD'));
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                      edge: 'end',
                    }}
                    keyboardIcon={<CalendarTodayIcon />}
                    helperText={null}
                    error={false}
                  />
                </FormControl>
              </Box>
            </Box>
          </Box>
          <Box mb={4}>
            <Box className='text-base font-semibold text-black1' mb={0.5}>
              Contract Roles
            </Box>
            <Autocomplete
              fullWidth
              classes={{ root: classes.root }}
              disabled={isLoading || isContractRolesLoading}
              size='small'
              multiple
              id='contract-role-checkbox-autocomplete'
              value={state.contractRoles}
              options={[...state.contractRoles]
                ?.sort((p1, p2) => handleCompare(p1, p2, SortingDirection.Asc, 'name'))
                .concat((contractRoles || []).filter(x => state.contractRoles.find(c => c.id === x.id) === undefined))}
              disableCloseOnSelect
              getOptionLabel={option => option.name}
              renderTags={() => <div />}
              renderOption={(option, { selected }) => (
                <>
                  <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} className='mr-2' />
                  {option.name}
                </>
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  variant='outlined'
                  placeholder={
                    state.contractRoles.length
                      ? `${state.contractRoles.length} ${
                          state.contractRoles.length > 1 ? 'Contract Roles' : 'Contract Role'
                        } Selected`
                      : 'All Contract Roles'
                  }
                  InputProps={{ ...params.InputProps, type: 'input', classes: { input: classes.input } }}
                />
              )}
              onChange={(event, value) => {
                handleChanges('contractRoles', value);
              }}
            />
          </Box>
          <Box mb={4} display='flex' alignItems='center'>
            <Checkbox
              icon={controlIcon}
              color='primary'
              checkedIcon={controlCheckedIcon}
              checked={state.isShowDeletedEntry}
              onChange={() => {
                handleChanges('isShowDeletedEntry', !state.isShowDeletedEntry);
              }}
            />
            <Box className='text-black1'>Hide deleted time entries</Box>
          </Box>

          <Box display='flex' justifyContent='center' alignItems='center' mt={10}>
            <Box mx={2}>
              <Button
                id='dialog-close-button'
                onClick={handleClose}
                color='tertiary'
                size='medium'
                borderRadius={50}
                disabled={isLoading}>
                Cancel
              </Button>
            </Box>
            <Box>
              <Button
                id='search-button'
                size='medium'
                color='black'
                disabled={isLoading}
                onClick={() => {
                  dispatch(setAdditionalFilters({ value: state }));
                  dispatch(closeAdditionalSearchDialog());
                }}
                borderRadius={50}
                loading={isLoading}>
                <Box display='flex' alignItems='center'>
                  <SearchIcon />
                  <span className='ml-1'>Apply and Search</span>
                </Box>
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
