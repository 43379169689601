import { PracticeColumnKey, type PracticeInfo, PracticeSortField, type SourceInfo } from 'services/models/roster.model';
import { SortingDirection } from 'services/models/sorting.model';

import type { CellContents, ColumnDefinition } from 'components/BanzaiTable';

function extractColumnValueForSource(source: SourceInfo): CellContents {
  return {
    primary: { text: new Date(source.timestamp).toLocaleString() },
    secondary: {
      text: source.user ? `by ${source.user.firstName} ${source.user.lastName}` : 'non-user update',
    },
  };
}

export const COLUMN_DEFINITIONS: ReadonlyArray<ColumnDefinition<PracticeColumnKey, PracticeSortField, PracticeInfo>> = [
  {
    key: PracticeColumnKey.DisplayName,
    title: 'Name',
    width: '34%',
    sortField: PracticeSortField.name,
    extractCellContents: (practice: PracticeInfo) => ({
      primary: { text: practice.displayName },
      secondary:
        practice.doingBusinessAsName && practice.doingBusinessAsName !== practice.displayName
          ? { text: `Doing Business As: ${practice.doingBusinessAsName}` }
          : practice.legalBusinessName !== practice.displayName
            ? { text: `Legal Business Name: ${practice.legalBusinessName}` }
            : undefined,
    }),
  },
  {
    key: PracticeColumnKey.TIN,
    title: 'TIN',
    width: '10%',
    sortField: PracticeSortField.tin,
    extractCellContents: practice => ({ primary: { text: practice.tin } }),
  },
  {
    key: PracticeColumnKey.StaffCount,
    title: 'Staff',
    width: '10%',
    sortField: PracticeSortField.staffCount,
    extractCellContents: practice => ({ primary: { text: `${practice.staff.length} staff` } }),
  },
  {
    key: PracticeColumnKey.ActiveProgram,
    title: 'Active Program',
    width: '15%',
    extractCellContents: practice => ({ primary: { text: practice.activeProgram?.name || '' } }),
  },
  {
    key: PracticeColumnKey.CreatedDate,
    title: 'Created',
    width: '13%',
    sortField: PracticeSortField.createdDate,
    defaultSortDirection: SortingDirection.Desc,
    extractCellContents: practice => extractColumnValueForSource(practice.created),
  },
  {
    key: PracticeColumnKey.UpdatedDate,
    title: 'Updated',
    width: '13%',
    sortField: PracticeSortField.updatedDate,
    defaultSortDirection: SortingDirection.Desc,
    extractCellContents: practice =>
      practice.created.timestamp !== practice.updated.timestamp
        ? extractColumnValueForSource(practice.updated)
        : { primary: { text: 'N/A' } },
  },
];
