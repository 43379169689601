import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

import type { MeetingCategoryCreateModel, MeetingCategoryFullModel } from 'services/models/meetingTimeEntry.model';

import { regexpValidationRule, requiredValidationRule } from 'utils/validation/general-validation-rules';
import { GeneralValidator } from 'utils/validation/general-validator';

export type MeetingCategoryAppState = Readonly<{
  createEditDialog: Readonly<{
    isOpen: boolean;
    existingMeetingCategory?: MeetingCategoryFullModel;
    inputValues: Readonly<{
      [Property in keyof MeetingCategoryCreateModel]?: string;
    }>;
    inputValidationErrors: Readonly<{
      [Property in keyof MeetingCategoryCreateModel]?: string | null;
    }>;
    isInputValueDirty: boolean;
  }>;
}>;

const initialState: MeetingCategoryAppState = {
  createEditDialog: {
    isOpen: false,
    inputValues: {},
    inputValidationErrors: {},
    isInputValueDirty: false,
  },
};

const validator = new GeneralValidator({
  id: [
    regexpValidationRule(/^[A-Z]+(?:_[A-Z0-9]+)*$/, 'Must be in ID_FORMAT (upper case and underscores)'),
    requiredValidationRule(),
  ],
  name: [requiredValidationRule()],
  description: [requiredValidationRule()],
});

function isAnyInputValueDirty(
  inputValues: Record<string, string | boolean | null | undefined>,
  baseValues: Record<string, string | number | boolean | null | undefined | object>,
): boolean {
  return Object.keys(inputValues).some(key =>
    // If the input value is truthy, see if the actual values have changed.
    // If the input value is falsy, see if the actual value is truthy
    inputValues[key] ? inputValues[key] !== baseValues[key] : Boolean(baseValues[key]),
  );
}

const slice = createSlice({
  name: 'meetingCategory',
  initialState,
  reducers: {
    closeCreateEditMeetingCategoryDialog(state) {
      state.createEditDialog = initialState.createEditDialog;
    },
    createEditMeetingCategoryInputValueChanged(
      state,
      action: PayloadAction<{ field: keyof MeetingCategoryCreateModel; value: string }>,
    ) {
      const { field, value } = action.payload;
      state.createEditDialog.inputValues[field] = value;

      const validationError = validator.validate(field, value.toString());
      state.createEditDialog.inputValidationErrors[field] = validationError;

      state.createEditDialog.isInputValueDirty = isAnyInputValueDirty(
        state.createEditDialog.inputValues,
        state.createEditDialog.existingMeetingCategory || initialState.createEditDialog.inputValues,
      );
    },
    openCreateMeetingCategoryDialog(state) {
      state.createEditDialog.isOpen = true;
    },
    openEditMeetingCategoryDialog(state, action: PayloadAction<{ meetingCategory: MeetingCategoryFullModel }>) {
      state.createEditDialog = {
        isOpen: true,
        existingMeetingCategory: action.payload.meetingCategory,
        inputValues: {
          id: action.payload.meetingCategory.id,
          name: action.payload.meetingCategory.name,
          description: action.payload.meetingCategory.description || undefined,
        },
        isInputValueDirty: false,
        inputValidationErrors: {},
      };
    },
  },
});

export const {
  closeCreateEditMeetingCategoryDialog,
  createEditMeetingCategoryInputValueChanged,
  openCreateMeetingCategoryDialog,
  openEditMeetingCategoryDialog,
} = slice.actions;
export const { reducer } = slice;
