import { Box } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

import { getCurrentPerformanceMonth, getCurrentStatementMonth, getDeadlineLocal } from 'utils/helpers/deadline';

export function Deadline() {
  const statementMonth = getCurrentStatementMonth();
  const performanceMonth = getCurrentPerformanceMonth();
  const deadlineDate = getDeadlineLocal();

  return (
    <Box flex={1} p={1.5} className='flex justify-center bg-divider'>
      <InfoIcon className='mr-2.5 h-5 w-5 cursor-pointer text-black' />
      <span className='font-normal' data-cy='timeEntryDeadlineBox'>
        The deadline to submit time entries for the {statementMonth} Payment Statement ({performanceMonth} Performance)
        is{' '}
        <span className='font-bold'>
          {deadlineDate.toLocaleDateString(undefined, { month: 'long', day: 'numeric' })} at{' '}
          {deadlineDate.toLocaleTimeString(undefined, { hour: 'numeric', minute: '2-digit', timeZoneName: 'short' })}
        </span>
      </span>
    </Box>
  );
}
