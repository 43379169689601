import DateFnsUtils from '@date-io/moment';
import { Routes } from 'Routes';
import { Amplify } from 'aws-amplify';
import awsExports from 'aws-exports';
import { AuthProvider, useAuth } from 'contexts/AuthContext';
import { BrowserRouter as Router, useHistory } from 'react-router-dom';
import type { StatsigUser } from 'statsig-react';
import { StatsigProvider } from 'statsig-react';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import ErrorBoundaryPage from 'pages/ErrorBoundaryPage';

import { CommonSnack } from 'components/CommonSnack';
import LoadingLinearProgress from 'components/LoadingLinearProgress';

import 'styles/css/App.css';

Amplify.configure(awsExports);

function AppContent() {
  const { user, inProgress } = useAuth();

  if (inProgress) {
    return <LoadingLinearProgress />;
  }
  const statsigUser: StatsigUser = {
    userID: user?.id,
    email: user?.email,
  };

  return (
    <StatsigProvider
      sdkKey={process.env.REACT_APP_STATSIG_KEY ?? ''}
      user={statsigUser}
      options={{ environment: { tier: process.env.REACT_APP_STATSIG_TIER ?? '' } }}>
      <Routes />
    </StatsigProvider>
  );
}

function App() {
  const history = useHistory();

  return (
    <ErrorBoundaryPage history={history}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Router>
          <AuthProvider>
            <AppContent />
          </AuthProvider>
          <CommonSnack />
        </Router>
      </MuiPickersUtilsProvider>
    </ErrorBoundaryPage>
  );
}

export default App;
