import { useState } from 'react';

interface AuthenticatedLayoutComponentState {
  anchorEl: null | HTMLElement;
}

export function useFacade(): [
  AuthenticatedLayoutComponentState,
  (event: React.MouseEvent<HTMLElement>) => void,
  () => void,
] {
  const [state, setState] = useState({
    anchorEl: null,
  } as AuthenticatedLayoutComponentState);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    setState(state => ({ ...state, anchorEl: event.currentTarget }));
  };

  const handleMenuClose = () => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    setState(state => ({ ...state, anchorEl: null }));
  };

  return [state, handleMenuOpen, handleMenuClose];
}
