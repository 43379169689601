import clsx from 'clsx';

import { Box } from '@material-ui/core';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

import type { UpdateHourlyConsultModel } from 'services/models/meetingTimeEntry.model';

import type { ColumnDefinition, RecordBase } from './types';

export const ACTION_COLUMN_WIDTH = 0.2;

export type Props<RT extends RecordBase, CKT extends string, SFT extends string> = {
  readonly record: RT;
  readonly practiceId: string;
  readonly params: Readonly<UpdateHourlyConsultModel>;
  readonly columns: ReadonlyArray<ColumnDefinition<CKT, SFT, RT>>;
  readonly handleSelect: (id: string) => void;
};

export function PickerTableRow<RT extends RecordBase, CKT extends string, SFT extends string>({
  record,
  practiceId,
  params,
  columns,
  handleSelect,
}: Props<RT, CKT, SFT>) {
  return (
    <Box
      key={`practice-id-${record.id}`}
      display='flex'
      alignItems='center'
      py={1.5}
      className={clsx(
        'cursor-pointer hover:rounded-lg hover:bg-cellHover',
        (params.practiceId === record.id || practiceId === record.id) && 'rounded-lg bg-cellHover',
      )}
      onClick={() => {
        params.practiceId !== record.id && handleSelect(record.id);
      }}>
      {columns.map(({ key, width, extractCellContents }) => {
        const { primary } = extractCellContents(record);
        return (
          <Box key={key} px={2} width={width} className='font-normal'>
            <Box className='break-words'>{primary.text}</Box>
          </Box>
        );
      })}
      <Box px={2} width={ACTION_COLUMN_WIDTH} className='text-center font-normal'>
        {params.practiceId === record.id ? (
          <Box className='italic text-black3'>Selected</Box>
        ) : (
          <Box>
            {practiceId === record.id ? (
              <RadioButtonCheckedIcon className='text-main' data-cy='radio-checked' />
            ) : (
              <RadioButtonUncheckedIcon className='text-main' data-cy='radio-unchecked' />
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}
