import clsx from 'clsx';
import { openAddRoleDialog, setRoleToDelete } from 'slices/roleAndPermission';
import { useDispatch } from 'stores';

import { Box, Grid, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import TrashIcon from '@material-ui/icons/DeleteOutline';

import { useAppPermissions } from 'services/auth.service';
import { App } from 'services/models/auth.model';
import type { UserRoleModel } from 'services/models/roleAndPermissions.model';

import { Button } from 'components/Button';

import { useStyles } from './styles';

type Props = {
  readonly record: UserRoleModel;
};

export function ExpandedRoleAndPermissions({ record }: Props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { canDelete, canUpdate } = useAppPermissions(App.USERS);

  if (record.appRoles.length === 0) {
    return (
      <Box display='flex' alignItems='center' justifyContent='space-between' width={1}>
        <Box className='my-auto flex-1 p-4'>No App Roles</Box>
        <Button
          id='open-create-staff-dialog-button'
          color='primary'
          borderRadius={50}
          size='medium'
          disabled={!canUpdate}
          onClick={() => {
            dispatch(openAddRoleDialog({ userId: record.id }));
          }}>
          <Box display='flex' alignItems='center'>
            <AddIcon />
            <span className='ml-1'>Add New Role</span>
          </Box>
        </Button>
      </Box>
    );
  }

  return (
    <Box className='my-auto flex-1 p-4'>
      <Grid container item direction='row'>
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          <Box display='flex' flexDirection='column' className={classes.practiceStaffSection}>
            <Box px={2} className='text-xl text-black1'>
              Roles
            </Box>
            <Box mt={2}>
              <Box display='flex' py={1.5} className='border-y-2 border-divider'>
                <Box px={2} width={0.4} className='font-semibold text-black1'>
                  App
                </Box>
                <Box px={2} width={0.2} className='font-semibold text-black1'>
                  Role
                </Box>
              </Box>
              {record.appRoles.map(app => (
                <Box key={app.app} display='flex' py={1.5} alignItems='center' className='border-b-2 border-divider'>
                  <Box px={2} width={0.4} className={clsx('break-words font-normal')}>
                    {app.app}
                  </Box>
                  <Box px={2} width={0.5} className={clsx('break-words font-normal')}>
                    {app.roleName}
                  </Box>
                  <Box width={0.2} display='flex' justifyContent='flex-end'>
                    <Box width='70px' display='flex' alignItems='center' justifyContent='center' gridGap={8}>
                      <IconButton
                        aria-label='delete-button'
                        size='small'
                        disabled={!canDelete}
                        onClick={() => {
                          dispatch(setRoleToDelete({ userId: app.userId, roleName: app.roleName, app: app.app }));
                        }}>
                        <TrashIcon className='cursor-pointer text-error' />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Box display='flex' justifyContent='flex-end' alignItems='center' width={1} height={1}>
            <Button
              id='open-create-staff-dialog-button'
              color='primary'
              borderRadius={50}
              size='medium'
              disabled={!canUpdate}
              onClick={() => {
                dispatch(openAddRoleDialog({ userId: record.id }));
              }}>
              <Box display='flex' alignItems='center'>
                <AddIcon />
                <span className='ml-1'>Add New Role</span>
              </Box>
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
