import { useMsal } from '@azure/msal-react';
import { useQuery } from 'react-query';

import API from 'utils/helpers/axiosInstance';

import { type App, type AppPermissions, type CurrentUserModel, Permission } from './models/auth.model';
import type { UserRoleModel } from './models/roleAndPermissions.model';

async function getCurrentUserApi(): Promise<CurrentUserModel> {
  const result = await API.get<UserRoleModel>('users/me');
  const { appRoles, ...user } = result.data;
  return {
    ...user,
    appRoles: appRoles.reduce(
      (roles, { app, role }) => ({
        ...roles,
        [app]: {
          ...(roles[app] || {}),
          ...role.permissions.reduce(
            (permissions, currentPermission) => ({
              ...permissions,
              [currentPermission]: true,
            }),
            {} as AppPermissions,
          ),
        },
      }),
      {} as CurrentUserModel['appRoles'],
    ),
  };
}

export function useCurrentUser() {
  const { accounts } = useMsal();
  const {
    isLoading,
    isRefetching,
    data: currentUser,
    error,
  } = useQuery(['currentUser'], () => getCurrentUserApi(), { enabled: accounts.length > 0 });
  return { isLoading: isLoading || isRefetching, currentUser, error };
}

export const useAppPermissions = (
  app: App,
): Readonly<{ canCreate: boolean; canDelete: boolean; canUpdate: boolean; canView: boolean }> => {
  const { currentUser } = useCurrentUser();
  const permissions = currentUser?.appRoles[app] || {};
  return {
    canCreate: Boolean(permissions[Permission.CREATE]),
    canDelete: Boolean(permissions[Permission.DELETE]),
    canUpdate: Boolean(permissions[Permission.UPDATE]),
    canView: Boolean(permissions[Permission.VIEW]),
  };
};
