import { useEffect } from 'react';
import {
  openCreatePracticeDialog,
  openEditPracticeDialog,
  reset,
  setPracticeToDelete,
  sortByColumn,
} from 'slices/roster';
import { useDispatch, useSelector } from 'stores';

import EditIcon from '@material-ui/icons/BorderColor';
import TrashIcon from '@material-ui/icons/DeleteOutline';

import { useAppPermissions } from 'services/auth.service';
import { App } from 'services/models/auth.model';
import type { PracticeInfo } from 'services/models/roster.model';
import { usePractices } from 'services/roster';

import { AuthenticatedLayoutComponent } from 'components/AuthenticatedLayoutComponent';
import { BanzaiTable } from 'components/BanzaiTable';
import type { RecordAction } from 'components/BanzaiTable/types';

import { TitlesConstants } from 'utils/constants/title.constant';

import { CreatePracticeDialog } from './CreatePracticeDialog';
import { CreateStaffDialog } from './CreateStaffDialog';
import { DeletePracticeDialog } from './DeletePracticeDialog';
import { DeleteStaffDialog } from './DeleteStaffDialog';
import { ExpandedPractice } from './ExpandedPractice';
import { PracticeFilterToolbar } from './PracticeFilterToolbar';
import { COLUMN_DEFINITIONS } from './columnDefinitions';

export function RosterPage() {
  const dispatch = useDispatch();
  const { activeSortColumn, isCreatePracticeDialogOpen, practiceFilters } = useSelector(store => ({
    activeSortColumn: store.roster.activeSortColumn,
    isCreatePracticeDialogOpen: store.roster.createPracticeDialog.isOpen,
    practiceFilters: store.roster.practiceFilters,
  }));
  const { isLoading, practices } = usePractices();
  const { canCreate, canDelete, canUpdate } = useAppPermissions(App.ROSTER);

  const editPractice = (existingPractice: PracticeInfo) => {
    dispatch(openEditPracticeDialog({ existingPractice }));
  };

  const deletePractice = (practice: PracticeInfo) => {
    dispatch(setPracticeToDelete({ id: practice.id, sourceId: practice.sourceId }));
  };

  const getPracticeActions = (practice: PracticeInfo): ReadonlyArray<RecordAction<PracticeInfo>> => {
    const actions: RecordAction<PracticeInfo>[] = [];

    if (practice.allowedActions.edit) {
      if (canUpdate) {
        actions.push({
          id: 'Edit',
          Icon: EditIcon,
          handler: editPractice,
        });
      }
    }
    if (practice.allowedActions.delete) {
      if (canDelete) {
        actions.push({
          id: 'Delete',
          Icon: TrashIcon,
          colorClassName: 'text-error',
          handler: deletePractice,
        });
      }
    }
    return actions;
  };

  useEffect(
    () => () => {
      dispatch(reset());
    },
    [dispatch],
  );

  const emptyTableText = Object.keys(practiceFilters).length
    ? 'No practices match the specified filters'
    : 'No practices currently exist in our system';

  return (
    <AuthenticatedLayoutComponent title={TitlesConstants.roster}>
      <BanzaiTable
        tableId='practices'
        pageHeading='Roster Management'
        tableHeading='Practices'
        createRecord={{
          text: 'Create New Practice',
          enabled: canCreate && !isLoading && !isCreatePracticeDialogOpen,
          handler: () => {
            dispatch(openCreatePracticeDialog());
          },
        }}
        pluralRecordsText='matching Practices'
        isLoading={isLoading}
        records={practices}
        columns={COLUMN_DEFINITIONS}
        getRecordActions={getPracticeActions}
        emptyTableText={emptyTableText}
        sorting={{
          activeSortColumn,
          sortRecords: (field, defaultSortDirection) => {
            dispatch(sortByColumn({ field, defaultSortDirection }));
          },
        }}
        AboveTableComponent={PracticeFilterToolbar}
        ExpandedRecordComponent={ExpandedPractice}
      />
      <CreatePracticeDialog />
      <DeletePracticeDialog />
      <CreateStaffDialog />
      <DeleteStaffDialog />
    </AuthenticatedLayoutComponent>
  );
}
