import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Button, Container, Typography, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import type { Theme } from '@material-ui/core';

import { ReactComponent as PageNotFound } from 'assets/svgs/undraw_page_not_found_su7k.svg';

import LayoutComponent from 'components/LayoutComponent';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#f4f6f8',
    minHeight: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3),
    paddingTop: 80,
    paddingBottom: 80,
  },
  image: {
    maxWidth: '100%',
    width: 560,
    maxHeight: 300,
    height: 'auto',
  },
}));

const NotFoundView: FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <LayoutComponent className={classes.root} title='404: Not found'>
      <Container maxWidth='lg'>
        <Typography align='center' variant={mobileDevice ? 'h4' : 'h1'} color='textPrimary'>
          404: The page you are looking for isn’t here
        </Typography>
        <Typography align='center' variant='subtitle2' color='textSecondary'>
          You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation.
        </Typography>
        <Box mt={6} display='flex' justifyContent='center'>
          <PageNotFound className={classes.image} />
        </Box>
        <Box mt={6} display='flex' justifyContent='center'>
          <Button color='secondary' component={RouterLink} to='/dashboard' variant='outlined'>
            Back to Dashboard
          </Button>
        </Box>
      </Container>
    </LayoutComponent>
  );
};

export default NotFoundView;
