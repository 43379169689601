import type { ChangeEvent } from 'react';
import { AddRoleInputValueChanged } from 'slices/roleAndPermission';
import { useDispatch, useSelector } from 'stores';

import { Box, FormControl, FormHelperText, MenuItem, Select, TextField } from '@material-ui/core';

import { App } from 'services/models/auth.model';
import type { AppRoleInput } from 'services/models/roleAndPermissions.model';
import { RoleName } from 'services/models/roleAndPermissions.model';

import { useStyles } from '../styles';

type FieldName = keyof AppRoleInput;

type Props = Readonly<{
  field: FieldName;
  disabled: boolean;
}>;

const LabelForField: Readonly<Record<FieldName, string>> = {
  userId: 'User Id',
  roleName: 'Role Name',
  app: 'App',
};

export function InputFieldRow({ field, disabled }: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { value, placeholder, errorMessage } = useSelector(store => ({
    value: store.roleAndPermissions.createRoleDialog.inputValues[field],
    placeholder: field === 'userId' || field === 'roleName' || field === 'app' ? 'required' : undefined,
    errorMessage: store.roleAndPermissions.createRoleDialog.inputValidationErrors[field],
  }));

  const onChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    dispatch(AddRoleInputValueChanged({ field, value: event.target.value }));
  };

  return (
    <Box mt={3}>
      <Box className='text-base font-semibold text-black1' mb={0.5}>
        {LabelForField[field]}
      </Box>
      <FormControl variant='outlined' size='medium' fullWidth classes={{ root: classes.root }}>
        {field === 'app' || field === 'roleName' ? (
          <Select
            classes={{ root: `${classes.root} ${disabled ? 'bg-disabled' : ''}` }}
            value={value || ''}
            renderValue={() => {
              if (!value) {
                return <Box className='text-base font-normal text-black2'>– Select {LabelForField[field]} –</Box>;
              }

              return <Box className='text-base font-normal text-black2'>{value}</Box>;
            }}
            displayEmpty
            disabled={disabled}
            onChange={event => {
              dispatch(AddRoleInputValueChanged({ field, value: event.target.value as string }));
            }}>
            {Object.keys(field === 'app' ? App : RoleName).map(key => (
              <MenuItem key={`app-${key}`} value={key}>
                <Box display='flex' alignItems='center' justifyContent='space-between' width={1}>
                  <Box>{key}</Box>
                </Box>
              </MenuItem>
            ))}
          </Select>
        ) : (
          <TextField
            id={field}
            fullWidth
            size='medium'
            InputProps={{ className: 'input' }}
            variant='outlined'
            className='bg-disabled'
            disabled={disabled}
            placeholder={placeholder || ''}
            value={value || ''}
            error={Boolean(errorMessage)}
            onChange={onChange}
          />
        )}
        <FormHelperText error>{errorMessage}</FormHelperText>
      </FormControl>
    </Box>
  );
}
