import { useCallback } from 'react';
import { closeAddRoleDialog } from 'slices/roleAndPermission';
import { showError, showSuccess } from 'slices/snack';
import { useDispatch, useSelector } from 'stores';

import { Box, Dialog, DialogContent, Divider, Grid } from '@material-ui/core';

import { App } from 'services/models/auth.model';
import { RoleName } from 'services/models/roleAndPermissions.model';
import { useAddRoleMutation } from 'services/roleAndPermission';

import { Button } from 'components/Button';

import { InputFieldRow } from './InputFieldRow';

export function AddRoleDialog() {
  const dispatch = useDispatch();
  const { isOpen, inputValues } = useSelector(store => store.roleAndPermissions.createRoleDialog);

  const cancelCreate = () => {
    dispatch(closeAddRoleDialog());
  };

  const onSuccess = useCallback(() => {
    const message = `Role Successfully Created`;
    dispatch(showSuccess({ message }));
    dispatch(closeAddRoleDialog());
  }, [dispatch]);

  const onError = useCallback(() => {
    const message = `An error occurred while Adding the Role`;
    dispatch(showError({ message }));
  }, [dispatch]);

  const { isAddRoleInProgress, addRole } = useAddRoleMutation({ onSuccess, onError });
  const inProgress = isAddRoleInProgress;

  const validateAndSaveRole = () => {
    const { userId, app: appInput, roleName: roleNameInput } = inputValues;
    if (userId && appInput && roleNameInput && appInput in App && roleNameInput in RoleName) {
      const app = App[appInput as keyof typeof App];
      const roleName = RoleName[roleNameInput as keyof typeof RoleName];
      addRole({ userId, app, roleName });
    }
  };

  const isValidToCreate = Boolean(inputValues.userId) && Boolean(inputValues.app) && Boolean(inputValues.roleName);

  return (
    <Dialog
      maxWidth='sm'
      fullWidth
      open={isOpen}
      onClose={(event: object, reason: string) => {
        reason !== 'backdropClick' && cancelCreate();
      }}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'>
      <DialogContent className='p-8'>
        <Box className='xl:flex' justifyContent='space-between' alignItems='center'>
          <Box pr={3}>
            <span className='text-3xl font-semibold text-black'>Add a New Role</span>
          </Box>
        </Box>
        <Grid container item direction='row'>
          <Grid item xs={12}>
            <InputFieldRow field='roleName' disabled={inProgress} />
            <InputFieldRow field='app' disabled={inProgress} />
          </Grid>
        </Grid>
        <Box mt={5}>
          <Divider />
          <Box mt={5} display='flex' justifyContent='center' alignItems='center'>
            <Box mx={2}>
              <Button
                id='dialog-close-button'
                onClick={cancelCreate}
                disabled={inProgress}
                color='tertiary'
                size='medium'
                borderRadius={50}>
                Cancel
              </Button>
            </Box>
            <Box>
              <Button
                id='add-staff-button'
                size='medium'
                disabled={inProgress || !isValidToCreate}
                loading={inProgress}
                onClick={validateAndSaveRole}
                borderRadius={50}>
                Add Role
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
