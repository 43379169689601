import { SortingDirection } from 'services/models/sorting.model';

export function descendingComparator(a: any, b: any, orderBy: string, objectKey?: string | null) {
  let aValue = a[orderBy] ? (Number.isNaN(Number(a[orderBy])) ? a[orderBy].toString().toLowerCase() : a[orderBy]) : '';
  let bValue = b[orderBy] ? (Number.isNaN(Number(a[orderBy])) ? b[orderBy].toString().toLowerCase() : b[orderBy]) : '';
  if (objectKey) {
    aValue = a[objectKey][orderBy]
      ? Number.isNaN(Number(a[objectKey][orderBy]))
        ? a[objectKey][orderBy].toString().toLowerCase()
        : a[objectKey][orderBy]
      : '';
    bValue = b[objectKey][orderBy]
      ? Number.isNaN(Number(b[objectKey][orderBy]))
        ? b[objectKey][orderBy].toString().toLowerCase()
        : b[objectKey][orderBy]
      : '';
  }

  if (bValue < aValue) {
    return -1;
  }
  if (bValue > aValue) {
    return 1;
  }
  return 0;
}

export const handleCompare = (
  object1: object,
  object2: object,
  direction: SortingDirection,
  source: string,
  objectKey?: string,
) =>
  direction === SortingDirection.Desc
    ? descendingComparator(object1, object2, source, objectKey)
    : -descendingComparator(object1, object2, source, objectKey);
