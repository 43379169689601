export enum App {
  ROSTER = 'ROSTER',
  PCT = 'PCT',
  PROGRAMS = 'PROGRAMS',
  USERS = 'USERS',
  MEETING_CATEGORIES = 'MEETING_CATEGORIES',
}

export enum Permission {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  VIEW = 'VIEW',
  DELETE = 'DELETE',
}

export interface LoginModel {
  email: string;
  password: string;
}

export type AppPermissions = Readonly<{
  [permission in Permission]?: boolean;
}>;

export type UserModel = Readonly<{
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}>;

export type CurrentUserModel = UserModel &
  Readonly<{
    appRoles: Readonly<{
      [app in App]?: AppPermissions;
    }>;
  }>;
