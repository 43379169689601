import { useAuth } from 'contexts/AuthContext';

import { Box, Divider } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import { StandardTable } from 'components/BanzaiTable/StandardTable';
import type { RecordBase, Props as TableProps } from 'components/BanzaiTable/StandardTable';
import { Button } from 'components/Button';
import { Container } from 'components/Container';

export type { CellContents, ColumnDefinition } from 'components/BanzaiTable/StandardTable';

type Props<RT extends RecordBase, CKT extends string, SFT extends string> = Pick<
  TableProps<RT, CKT, SFT>,
  'isLoading' | 'records' | 'tableId' | 'columns' | 'sorting' | 'ExpandedRecordComponent' | 'getRecordActions'
> & {
  readonly pageHeading: string;
  readonly tableHeading: string;
  readonly createRecord?: {
    readonly text: string;
    readonly enabled: boolean;
    readonly handler: () => void;
  };
  readonly pluralRecordsText: string;
  readonly emptyTableText: string;
  readonly AboveHeaderComponent?: React.ComponentType;
  readonly AboveTableComponent?: React.ComponentType;
};

export function BanzaiTable<RT extends RecordBase, CKT extends string, SFT extends string>(props: Props<RT, CKT, SFT>) {
  const { isOpenIframe } = useAuth();
  const {
    isLoading,
    records,
    tableId,
    columns,
    pageHeading,
    tableHeading,
    pluralRecordsText,
    emptyTableText,
    sorting,
    createRecord,
    AboveHeaderComponent,
    AboveTableComponent,
    ExpandedRecordComponent,
    getRecordActions,
  } = props;
  return (
    <Container hideBackground>
      <Box width={1} className='p-4 md:p-8'>
        {AboveHeaderComponent && <AboveHeaderComponent />}
        <Box mt={3.5} mb={5} display='flex' alignItems='center' justifyContent='space-between'>
          <Box display='flex' alignItems='center'>
            <Box mr={2} className='text-4xl font-semibold text-black1'>
              {pageHeading}
            </Box>
          </Box>
          {createRecord && (
            <Box display='flex' alignItems='center'>
              <Button
                id='open-new-entry-dialog-button'
                data-cy='createTimeEntryButton'
                color='primary'
                borderRadius={50}
                size='medium'
                disabled={!createRecord.enabled}
                onClick={createRecord.handler}>
                <Box display='flex' alignItems='center'>
                  <AddIcon />
                  <span className='ml-1'>{createRecord.text}</span>
                </Box>
              </Button>
            </Box>
          )}
        </Box>
        <Divider className='h-0.5 border-divider' />
        <Box mt={2} width={1}>
          <Box className='text-2xl font-normal text-black1'>{tableHeading}</Box>
          {!isOpenIframe && AboveTableComponent && <AboveTableComponent />}
        </Box>
        <Box mt={7}>
          {!isLoading && records && (
            <Box display='flex' justifyContent='flex-end' mb={2} className='font-semibold text-black1'>
              {records.length} {pluralRecordsText}
            </Box>
          )}
          <Box minHeight='100%' className='border-t-2 border-black5'>
            <StandardTable
              tableId={tableId}
              isLoading={isLoading}
              records={records}
              columns={columns}
              emptyTableText={emptyTableText}
              sorting={sorting}
              getRecordActions={getRecordActions}
              ExpandedRecordComponent={ExpandedRecordComponent}
            />
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
