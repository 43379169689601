import { useMemo } from 'react';

import { usePracticesList } from 'services/listItems.service';

import { BanzaiDropDown } from './BanzaiDropDown';

type Props = Readonly<{
  label?: string;
  selectedId?: string;
  errorMessage?: string | null;
  disabled?: boolean;
  onSelected: (id: string) => void;
}>;

export function SelectPracticeDropDown({ label, selectedId, errorMessage, disabled, onSelected }: Props) {
  const { isLoading, practices } = usePracticesList();
  const practicesAsItems = useMemo(
    () => (practices || []).map(practice => ({ id: practice.id, name: practice.displayName })),
    [practices],
  );

  return (
    <BanzaiDropDown
      id='practice'
      label={label || 'Practice'}
      placeholder='– Select Meeting Category –'
      items={practicesAsItems}
      value={selectedId}
      disabled={disabled || isLoading || !practices?.length}
      errorMessage={errorMessage}
      onSelected={onSelected}
    />
  );
}
