import { useCallback } from 'react';
import { setOverridePracticeWarnings } from 'slices/roster';
import { useDispatch, useSelector } from 'stores';

import type { PracticeInfo } from 'services/models/roster.model';
import { PracticeIssueType } from 'services/roster';

import type { RelatedItemColumn } from '../ValidationErrorInfo';
import { ValidationErrorInfo } from '../ValidationErrorInfo';

const relatedItemColumns: ReadonlyArray<RelatedItemColumn<PracticeInfo>> = [
  { type: 'field', heading: 'TIN', field: 'tin', width: 2 },
  { type: 'field', heading: 'Display Name', field: 'displayName', width: 5 },
  { type: 'field', heading: 'Legal Business Name', field: 'legalBusinessName', width: 5 },
];

const issueTypeMessages: Readonly<Record<PracticeIssueType, string>> = {
  [PracticeIssueType.DuplicateTin]: 'The TIN you specified is already being used by another Practice:',
  [PracticeIssueType.DuplicateLegalBusinessName]:
    'The Legal Business Name you specified matches the one used by the following Practice(s):',
};

export function PracticeValidationErrorInfo() {
  const dispatch = useDispatch();
  const [apiValidationError, isOverrideWarnings] = useSelector(store => [
    store.roster.createPracticeDialog.apiValidationError,
    store.roster.createPracticeDialog.isOverrideWarnings,
  ]);

  const setOverrideWarnings = useCallback(
    (overrideWarnings: boolean) => {
      dispatch(setOverridePracticeWarnings({ overrideWarnings }));
    },
    [dispatch],
  );

  if (apiValidationError) {
    return (
      <ValidationErrorInfo
        severity={apiValidationError.severity}
        message={issueTypeMessages[apiValidationError.type]}
        relatedItems={apiValidationError.relatedItems}
        isOverrideWarnings={isOverrideWarnings}
        relatedItemColumns={relatedItemColumns}
        setOverrideWarnings={setOverrideWarnings}
      />
    );
  }

  return null;
}
