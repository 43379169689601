import PropTypes from 'prop-types';
import type { HTMLProps, ReactNode } from 'react';
import { Helmet } from 'react-helmet';

interface PageProps extends HTMLProps<HTMLDivElement> {
  children?: ReactNode;
  title?: string;
}

const LayoutComponent: React.FC<PageProps> = ({ children, title = '', ...rest }: PageProps) => (
  <div {...rest}>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    {children}
  </div>
);

LayoutComponent.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default LayoutComponent;
