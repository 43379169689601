import type { App, Permission } from './auth.model';

export enum UserRoleColumnKey {
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
}

export enum RoleName {
  ADMIN = 'ADMIN',
  VIEWER = 'VIEWER',
}

type Role = {
  name: string;
  permissions: Permission[];
};

type AppRole = {
  userId: string;
  roleName: RoleName;
  app: App;
  role: Role;
};

export type UserRoleModel = Readonly<{
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  appRoles: AppRole[];
}>;

export type AppRoleInput = Omit<AppRole, 'role'>;
