import { useCallback } from 'react';
import { closeAddMeetingCategoryDialog } from 'slices/program';
import { showError, showSuccess } from 'slices/snack';
import { useDispatch, useSelector } from 'stores';

import { Box, Dialog, DialogContent, Divider, Grid } from '@material-ui/core';

import { useAddMeetingCategoryToProgramMutation, useEditMeetingCategoryInProgramMutation } from 'services/roster';

import { Button } from 'components/Button';

import { InputFieldRow } from './InputFieldRow';

export function AddMeetingCategoryToProgramDialog() {
  const dispatch = useDispatch();
  const { isOpen, programId, existingProgramMeetingCategory, inputValues, inputValidationErrors, isInputValueDirty } =
    useSelector(store => store.program.addMeetingCategoryDialog);

  const closeDialog = () => {
    dispatch(closeAddMeetingCategoryDialog());
  };

  const onSuccess = useCallback(() => {
    const message = `Meeting Category Successfully ${existingProgramMeetingCategory ? 'Updated' : 'Added'}.`;
    dispatch(showSuccess({ message }));
    dispatch(closeAddMeetingCategoryDialog());
  }, [dispatch, existingProgramMeetingCategory]);

  const onError = useCallback(() => {
    const message = `An error occurred while ${
      existingProgramMeetingCategory ? 'updating' : 'adding'
    } this Meeting Category.`;
    dispatch(showError({ message }));
  }, [dispatch, existingProgramMeetingCategory]);

  const { isAdding, addMeetingCategoryToProgram } = useAddMeetingCategoryToProgramMutation({ onSuccess, onError });
  const { isUpdating, editMeetingCategoryInProgram } = useEditMeetingCategoryInProgramMutation({ onSuccess, onError });
  const inProgress = isAdding || isUpdating;

  const validateAndProcess = () => {
    const { startDate, endDate, paid, meetingCategoryId } = inputValues;
    if (startDate && paid !== undefined) {
      const validatedInputValues = {
        meetingCategoryId,
        startDate,
        endDate,
        paid,
      };
      if (existingProgramMeetingCategory) {
        editMeetingCategoryInProgram({
          programMeetingCategoryId: existingProgramMeetingCategory.programMeetingCategoryId,
          sourceId: existingProgramMeetingCategory.sourceId,
          startDate,
          endDate,
          paid,
        });
      } else {
        addMeetingCategoryToProgram({ ...validatedInputValues, programId });
      }
    }
  };

  const isValidToCreate =
    isInputValueDirty &&
    Boolean(inputValues.meetingCategoryId) &&
    Boolean(inputValues.startDate) &&
    inputValues.paid !== undefined &&
    !Object.values(inputValidationErrors).some(error => error);

  return (
    <Dialog
      maxWidth='sm'
      fullWidth
      open={isOpen}
      onClose={(event: object, reason: string) => {
        reason !== 'backdropClick' && closeDialog();
      }}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'>
      <DialogContent className='p-8'>
        <Box className='xl:flex' justifyContent='space-between' alignItems='center'>
          <Box pr={3}>
            <span className='text-3xl font-semibold text-black'>
              {existingProgramMeetingCategory ? 'Editing Meeting Category' : 'Add Meeting Category'}
            </span>
          </Box>
        </Box>
        <Grid container item direction='row'>
          <Grid item xs={12}>
            {!existingProgramMeetingCategory && <InputFieldRow field='meetingCategoryId' disabled={inProgress} />}
            <InputFieldRow
              field='startDate'
              disabled={
                inProgress ||
                (existingProgramMeetingCategory ? !existingProgramMeetingCategory.allowedActions.edit.startDate : false)
              }
            />
            <InputFieldRow
              field='endDate'
              disabled={
                inProgress ||
                (existingProgramMeetingCategory ? !existingProgramMeetingCategory.allowedActions.edit.endDate : false)
              }
            />
            <InputFieldRow field='paid' disabled={inProgress} />
          </Grid>
        </Grid>
        <Box mt={5}>
          <Divider />
          <Box mt={5} display='flex' justifyContent='center' alignItems='center'>
            <Box mx={2}>
              <Button
                id='dialog-close-button'
                onClick={closeDialog}
                disabled={inProgress}
                color='tertiary'
                size='medium'
                borderRadius={50}>
                Cancel
              </Button>
            </Box>
            <Box>
              <Button
                id='add-meeting-category-button'
                size='medium'
                disabled={inProgress || !isValidToCreate}
                loading={inProgress}
                onClick={validateAndProcess}
                borderRadius={50}>
                {existingProgramMeetingCategory ? 'Save Changes' : 'Add Meeting Category'}
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
