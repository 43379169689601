import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  type PracticeCreateInput,
  type PracticeInfo,
  PracticeSortField,
  type StaffCreateInput,
  type StaffInfo,
} from 'services/models/roster.model';
import { type SortColumn, SortingDirection } from 'services/models/sorting.model';
import type { PracticeValidationError, StaffValidationError } from 'services/roster';

import {
  functionValidationRule,
  maxLengthValidationRule,
  requiredValidationRule,
} from 'utils/validation/general-validation-rules';
import { GeneralValidator } from 'utils/validation/general-validator';

export type PracticeFilterField = 'name' | 'tin' | 'staffName' | 'staffNpi';
export type PracticeInputFieldName =
  | keyof Omit<PracticeCreateInput, 'overrideWarnings' | 'program'>
  | 'programId'
  | 'programStartDate';
type StaffInputFieldName = keyof Omit<StaffCreateInput, 'overrideWarnings'>;

type CreatePracticeDialogState = Readonly<{
  isOpen: boolean;
  existingPractice?: PracticeInfo;
  inputValues: Readonly<{
    [Field in PracticeInputFieldName]?: string;
  }>;
  inputValidationErrors: Readonly<{
    [Field in PracticeInputFieldName]?: string | null;
  }>;
  apiValidationError?: PracticeValidationError;
  isOverrideWarnings: boolean;
  isInputValueDirty: boolean;
}>;

type CreateStaffDialogState = Readonly<{
  isOpen: boolean;
  existingStaff?: StaffInfo;
  inputValues: Readonly<{
    [Field in StaffInputFieldName]?: string;
  }>;
  inputValidationErrors: Readonly<{
    [Field in StaffInputFieldName]?: string | null;
  }>;
  apiValidationError?: StaffValidationError;
  isOverrideWarnings: boolean;
  isInputValueDirty: boolean;
  existingStaffToAdd?: StaffInfo;
}>;

type RosterState = Readonly<{
  activeSortColumn: SortColumn<PracticeSortField>;
  practiceFilters: Readonly<{
    name?: string;
    tin?: ReadonlyArray<string>;
    staffName?: string;
    staffNpi?: ReadonlyArray<string>;
  }>;
  createPracticeDialog: CreatePracticeDialogState;
  createStaffDialogState: CreateStaffDialogState;
  practiceToDelete?: Readonly<{ id: string; sourceId: string }>;
  staffToDelete?: Readonly<{ staffId: string; practiceId: string; sourceId: string }>;
}>;

// Helper type to solve readonly issues in draft state
type Writeable<T> = { -readonly [P in keyof T]: Writeable<T[P]> };

const initialState: RosterState = {
  activeSortColumn: { field: PracticeSortField.name, direction: SortingDirection.Asc },
  practiceFilters: {},
  createPracticeDialog: {
    isOpen: false,
    inputValues: {},
    inputValidationErrors: {},
    isOverrideWarnings: false,
    isInputValueDirty: false,
  },
  createStaffDialogState: {
    isOpen: false,
    inputValues: {},
    inputValidationErrors: {},
    isOverrideWarnings: false,
    isInputValueDirty: false,
  },
};

const practiceValidator = new GeneralValidator({
  tin: [
    functionValidationRule(
      (value: string) => value.length === 9 && Number.isInteger(Number(value)),
      'Must be a nine digit number',
    ),
    requiredValidationRule(),
  ],
  legalBusinessName: [maxLengthValidationRule(100), requiredValidationRule()],
  displayName: [maxLengthValidationRule(100)],
  doingBusinessAsName: [maxLengthValidationRule(100)],
});

const staffValidator = new GeneralValidator({
  id: [requiredValidationRule()],
  firstName: [requiredValidationRule()],
  lastName: [requiredValidationRule()],
  jobTitle: [requiredValidationRule()],
  npi: [
    functionValidationRule(
      (value: string) => !value || (value.length === 10 && Number.isInteger(Number(value))),
      'Must be a 10-digit number',
    ),
  ],
  contractRoleId: [requiredValidationRule()],
});

function isAnyInputValueDirty(
  inputValues: Record<string, string | null | undefined>,
  baseValues: Record<string, string | number | null | undefined | object>,
): boolean {
  return Object.keys(inputValues).some(key =>
    // If the input value is truthy, see if the actual values have changed.
    // If the input value is falsy, see if the actual value is truthy
    inputValues[key] ? inputValues[key] !== baseValues[key] : Boolean(baseValues[key]),
  );
}

const slice = createSlice({
  name: 'roster',
  initialState,
  reducers: {
    reset(state) {
      state.activeSortColumn = initialState.activeSortColumn as Writeable<SortColumn<PracticeSortField>>;
      state.practiceFilters = {};
      state.createPracticeDialog = initialState.createPracticeDialog as Writeable<CreatePracticeDialogState>;
      state.createStaffDialogState = initialState.createStaffDialogState as Writeable<CreateStaffDialogState>;
    },
    sortByColumn(state, action: PayloadAction<{ field: PracticeSortField; defaultSortDirection?: SortingDirection }>) {
      state.activeSortColumn = {
        field: action.payload.field,
        direction:
          action.payload.field === state.activeSortColumn.field
            ? state.activeSortColumn.direction === SortingDirection.Asc
              ? SortingDirection.Desc
              : SortingDirection.Asc
            : action.payload.defaultSortDirection || SortingDirection.Asc,
      };
    },
    setPracticeFilters(state, action: PayloadAction<RosterState['practiceFilters']>) {
      state.practiceFilters = action.payload as Writeable<RosterState['practiceFilters']>;
    },
    openCreatePracticeDialog(state) {
      state.createPracticeDialog = {
        ...initialState.createPracticeDialog,
        isOpen: true,
      } as Writeable<CreatePracticeDialogState>;
    },
    openEditPracticeDialog(state, action: PayloadAction<{ existingPractice: PracticeInfo }>) {
      const { tin, displayName, legalBusinessName, doingBusinessAsName } = action.payload.existingPractice;
      state.createPracticeDialog.existingPractice = action.payload.existingPractice as Writeable<PracticeInfo>;
      state.createPracticeDialog.inputValues = {
        tin,
        displayName,
        legalBusinessName,
        doingBusinessAsName: doingBusinessAsName || undefined,
      };
      state.createPracticeDialog.isOpen = true;
    },
    closeCreatePracticeDialog(state) {
      state.createPracticeDialog.isOpen = false;
      state.createPracticeDialog.apiValidationError = undefined;
    },
    showPracticeValidationError(state, action: PayloadAction<{ error: PracticeValidationError }>) {
      state.createPracticeDialog.apiValidationError = action.payload.error as Writeable<PracticeValidationError>;
      state.createPracticeDialog.isInputValueDirty = false;
    },
    practiceInputValueChanged(state, action: PayloadAction<{ field: PracticeInputFieldName; value: string }>) {
      const { field, value } = action.payload;

      state.createPracticeDialog.inputValues[field] = value || undefined;

      const validationError = practiceValidator.validate(field, value);
      state.createPracticeDialog.inputValidationErrors[field] = validationError;

      state.createPracticeDialog.isInputValueDirty = isAnyInputValueDirty(
        state.createPracticeDialog.inputValues,
        state.createPracticeDialog.existingPractice || initialState.createPracticeDialog.inputValues,
      );
    },
    setOverridePracticeWarnings(state, action: PayloadAction<{ overrideWarnings: boolean }>) {
      state.createPracticeDialog.isOverrideWarnings = action.payload.overrideWarnings;
    },
    setPracticeToDelete(state, action: PayloadAction<{ id: string; sourceId: string }>) {
      state.practiceToDelete = action.payload;
    },
    clearPracticeToDelete(state) {
      delete state.practiceToDelete;
    },
    openCreateStaffDialog(state, action: PayloadAction<{ practiceId: string }>) {
      const { practiceId } = action.payload;
      state.createStaffDialogState.inputValues = {
        practiceId,
      };
      state.createStaffDialogState.isOpen = true;
    },
    openEditStaffDialog(state, action: PayloadAction<{ practiceId: string; existingStaff: StaffInfo }>) {
      const { practiceId } = action.payload;
      const { displayName, firstName, middleName, lastName, prefix, suffix, npi, jobTitle, contractRoleId } =
        action.payload.existingStaff;
      state.createStaffDialogState.existingStaff = action.payload.existingStaff as Writeable<StaffInfo>;
      state.createStaffDialogState.inputValues = {
        practiceId,
        displayName: displayName || undefined,
        firstName,
        middleName: middleName || undefined,
        lastName,
        contractRoleId,
        jobTitle: jobTitle || undefined,
        prefix: prefix || undefined,
        suffix: suffix || undefined,
        npi: npi || undefined,
      };
      state.createStaffDialogState.isOpen = true;
    },
    closeCreateStaffDialog(state) {
      state.createStaffDialogState = initialState.createStaffDialogState as Writeable<CreateStaffDialogState>;
    },
    showStaffValidationError(state, action: PayloadAction<{ error: StaffValidationError }>) {
      state.createStaffDialogState.apiValidationError = action.payload.error as Writeable<StaffValidationError>;
      state.createStaffDialogState.isInputValueDirty = false;
    },
    staffInputValueChanged(state, action: PayloadAction<{ field: StaffInputFieldName; value: string }>) {
      const { field, value } = action.payload;

      state.createStaffDialogState.inputValues[field] = value || undefined;

      const validationError = staffValidator.validate(field, value);
      state.createStaffDialogState.inputValidationErrors[field] = validationError;

      state.createStaffDialogState.isInputValueDirty = isAnyInputValueDirty(
        state.createStaffDialogState.inputValues,
        state.createStaffDialogState.existingStaff || initialState.createStaffDialogState.inputValues,
      );
    },
    setOverrideStaffWarnings(state, action: PayloadAction<{ overrideWarnings: boolean }>) {
      state.createStaffDialogState.isOverrideWarnings = action.payload.overrideWarnings;
    },
    setExistingStaffToAdd(state, action: PayloadAction<{ existingStaffToAdd: StaffInfo }>) {
      state.createStaffDialogState.existingStaffToAdd = action.payload.existingStaffToAdd;
    },
    clearExistingStaffToAdd(state) {
      state.createStaffDialogState.existingStaffToAdd = undefined;
    },
    setStaffToDelete(state, action: PayloadAction<{ staffId: string; practiceId: string; sourceId: string }>) {
      state.staffToDelete = action.payload;
    },
    clearStaffToDelete(state) {
      delete state.staffToDelete;
    },
  },
});

export const {
  reset,
  sortByColumn,
  setPracticeFilters,
  openCreatePracticeDialog,
  openEditPracticeDialog,
  closeCreatePracticeDialog,
  showPracticeValidationError,
  practiceInputValueChanged,
  setOverridePracticeWarnings,
  setPracticeToDelete,
  clearPracticeToDelete,
  openCreateStaffDialog,
  openEditStaffDialog,
  closeCreateStaffDialog,
  showStaffValidationError,
  staffInputValueChanged,
  setOverrideStaffWarnings,
  setExistingStaffToAdd,
  clearExistingStaffToAdd,
  setStaffToDelete,
  clearStaffToDelete,
} = slice.actions;

export const { reducer } = slice;
