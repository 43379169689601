export enum SortingDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export type SortColumn<SortFieldType extends string> = Readonly<{
  field: SortFieldType;
  direction: SortingDirection;
}>;

export type SortApiInput<SortFieldType extends string> = ReadonlyArray<SortColumn<SortFieldType>>;
