import type { UserModel } from './auth.model';
import type { ProgramModel } from './program.model';

export enum MeetingColumnKey {
  EnteredBy = 'EnteredBy',
  MonthlyStatement = 'MonthlyStatement',
  MeetingDate = 'MeetingDate',
  MeetingCategory = 'MeetingCategory',
  Practice = 'Practice',
  CreationDate = 'CreationDate',
}

export enum MeetingSortField {
  lastUpdatedBy = 'lastUpdatedBy',
  statementMonth = 'statementMonth',
  meetingDate = 'meetingDate',
  meetingCategory = 'meetingCategory',
  practice = 'practice',
  createdDate = 'createdDate',
}

export enum EntryType {
  ADJUSTMENT = 'ADJUSTMENT',
  LATE = 'LATE',
  ON_TIME = 'ON_TIME',
}

export enum MeetingCategoryColumnKey {
  ID = 'ID',
  Name = 'Name',
  MeetingsCount = 'MeetingsCount',
  CreatedDate = 'CreatedDate',
  UpdatedDate = 'UpdatedDate',
}

export type MeetingModel = Readonly<{
  id: string;
  meetingCategoryId: string;
  practiceId: string;
  startedAt: Date | string;
  endedAt: Date | string;
  statementMonth: string;
  entryType: EntryType;
  description: string | null;
  otherCategory: string | null;
  meetingCategory?: MeetingCategoryModel & Readonly<{ paid: boolean }>;
  meetingAttendees: MeetingAttendeeModel[];
  practice: MeetingPracticeModel;
  created: SourceModel;
  updated: SourceModel;
  deleted: boolean;
  processingStatus: string;
  sourceId: string;
}>;

export type MeetingPracticeModel = Readonly<{
  id: string;
  displayName: string;
  tin: string;
}>;

export type MeetingAttendeeModel = Readonly<{
  id: string;
  staffId: string;
  minutes: number;
  description: string | null;
  displayName: string;
  jobTitle: string | null;
  contractRole: ContractRoleModel;
}>;

type SourceModel = Readonly<{
  timestamp: Date | string;
  user: UserModel;
}>;

export type ContractRoleModel = Readonly<{
  id: string;
  name: string;
}>;

export type StaffModelWithDuration = StaffModel & { duration?: number };

export interface CreateHourlyConsultModel {
  meetingCategoryId: string;
  otherCategory: string;
  meetingDate: Date | string;
  startTime: string;
  endTime: string;
  note: string;
  practiceId: string;
  practiceTin: string;
  attendees: StaffModelWithDuration[];
  isChanged: boolean;
}

export interface UpdateHourlyConsultModel extends CreateHourlyConsultModel {
  id: string;
}

export type MeetingCategoryModel = Readonly<{
  id: string;
  name: string;
  description?: string | null;
}>;

export type MeetingCategoryCreateModel = Readonly<{
  id: string;
  name: string;
  description: string;
}>;

export type MeetingCategoryEditModel = MeetingCategoryCreateModel &
  Readonly<{
    sourceId: string;
  }>;

export type MeetingCategoryFullModel = MeetingCategoryModel &
  Readonly<{
    sourceId: string;
    meetingsCount: number;
    created: SourceModel;
    updated: SourceModel;
    allowedActions: Readonly<{ edit: boolean; delete: boolean }>;
  }>;

export type ProgramMeetingCategloryModel = Readonly<{
  paid: boolean;
  meetingCategory: MeetingCategoryModel;
  program: ProgramModel;
}>;

export type PracticeModel = Readonly<{
  id: string;
  displayName: string;
  legalBusinessName: string;
  doingBusinessAsName?: string | null;
  tin: string;
  staff: StaffModel[];
}>;

export type StaffModel = Readonly<{
  id: string;
  displayName: string;
  npi?: string | null;
  jobTitle?: string | null;
  contractRole: ContractRoleModel;
}>;

export interface CreateEntryModel {
  sourceId?: string;
  meetingCategoryId: string;
  practiceId: string;
  startedAt: string;
  endedAt: string;
  description: string;
  otherCategory?: string;
  meetingAttendees: InitAttendeeModel[];
}

export interface UpdateEntryModel {
  id: string;
  updateEntry: CreateEntryModel;
}

export interface InitAttendeeModel {
  id?: string;
  staffId: string;
  minutes: number;
  description?: string;
}

export interface DateRange {
  min?: string;
  max?: string;
}

export interface FilterModel {
  statementMonth: string[] | null;
  meetingCategoryId: string[] | null;
  practiceId: string[] | null;
  createdByUserId: string[] | null;
  staffId: string[] | null;
  contractRoleId: string[] | null;
  createdDate: DateRange | null;
  meetingDate: DateRange | null;
}

export enum FilterAndSortingSource {
  None = 'none',
  CreatedDate = 'createdDate',
  EnteredBy = 'lastUpdatedBy',
  Practice = 'practice',
  MeetingDate = 'meetingDate',
  StatementMonth = 'statementMonth',
  MeetingCategory = 'meetingCategory',
}

export interface MeetingsSearchState {
  statementMonth: string[];
  meetingCategories: MeetingCategoryModel[];
  practices: PracticeModel[];
  staffs: StaffModel[];
}

export interface AdditionalFilterState {
  enteredBy: UserModel[];
  entryCreatedStart: string;
  entryCreatedEnd: string;
  meetingRangeStart: string;
  meetingRangeEnd: string;
  contractRoles: ContractRoleModel[];
  isShowDeletedEntry: boolean;
}
