import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { clearRoleToDelete } from 'slices/roleAndPermission';
import { showError, showSuccess } from 'slices/snack';
import { useDispatch, useSelector } from 'stores';

import type { AppRoleInput } from 'services/models/roleAndPermissions.model';
import { useDeleteRoleMutation } from 'services/roleAndPermission';

import { ConfirmationDialog } from 'components/ConfirmationDialog';

export function DeleteRoleDialog() {
  const dispatch = useDispatch();
  const { roleToDelete } = useSelector(store => ({
    roleToDelete: store.roleAndPermissions.roleToDelete,
  }));

  const queryClient = useQueryClient();

  const onSuccess = useCallback(() => {
    dispatch(clearRoleToDelete());
    dispatch(showSuccess({ message: 'Role Successfully Deleted' }));
    queryClient.invalidateQueries('users');
  }, [dispatch, queryClient]);

  const onError = useCallback(() => {
    dispatch(clearRoleToDelete());
    dispatch(showError({ message: 'An error occurred while deleting this Role.' }));
  }, [dispatch]);

  const { isDeleteRoleInProgress, deleteRole } = useDeleteRoleMutation({ onSuccess, onError });

  const onCancel = () => {
    dispatch(clearRoleToDelete());
  };

  const onConfirm = () => {
    deleteRole(roleToDelete as AppRoleInput);
  };

  return (
    <ConfirmationDialog
      isOpen={Boolean(roleToDelete)}
      isDisabled={isDeleteRoleInProgress}
      message='Are you sure you want to delete this Role?'
      handleCancel={onCancel}
      handleConfirm={onConfirm}
    />
  );
}
