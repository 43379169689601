import clsx from 'clsx';
import React from 'react';

import { IconButton, InputBase } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { useStyles } from './SearchComponent.styles';

interface SearchComponentProps {
  defaultValue?: string;
  placeholder: string;
  handleSearch: (value: string) => void;
  handleChanges?: (value: string) => void;
}

export const SearchComponent: React.FC<SearchComponentProps> = (props: SearchComponentProps) => {
  const { defaultValue = '', placeholder, handleSearch, handleChanges } = props;

  const [searchValue, setValue] = React.useState<string>(defaultValue);
  const classes = useStyles();

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    if (handleChanges) {
      handleChanges(event.target.value);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleSearch(encodeURIComponent(searchValue));
    }
  };

  return (
    <InputBase
      placeholder={placeholder}
      className={clsx('input', classes.search)}
      onKeyPress={handleKeyPress}
      onChange={onChange}
      value={searchValue}
      classes={{
        root: classes.inputRoot,
        input: classes.inputField,
      }}
      startAdornment={
        <IconButton
          id='search'
          onClick={() => {
            handleSearch(encodeURIComponent(searchValue));
          }}
          disabled={!searchValue}>
          <SearchIcon />
        </IconButton>
      }
    />
  );
};
