import { useAuth } from 'contexts/AuthContext';
import { useCallback, useEffect } from 'react';

import { Box } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';

import LogoPNG from 'assets/images/Logo.png';
import { ReactComponent as MicrosoftIcon } from 'assets/svgs/MicrosoftIcon.svg';

import LayoutComponent from 'components/LayoutComponent';

import { TitlesConstants } from 'utils/constants/title.constant';

export function LoginPage() {
  const { handleLogin } = useAuth();
  const isOpenIframe = window.sessionStorage.getItem('isOpenIframe');
  const isOpenCreateDialog = window.sessionStorage.getItem('isOpenCreateDialog');

  useEffect(() => {
    if (isOpenIframe || isOpenCreateDialog) {
      handleLogin();
    }
  }, [handleLogin, isOpenIframe, isOpenCreateDialog]);

  const showFeedbackDialog = useCallback(() => {
    window.location.href = `mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`;
  }, []);

  return (
    <LayoutComponent title={TitlesConstants.loginTitle}>
      <Box
        className='login-background'
        display='flex'
        justifyContent='center'
        alignItems='center'
        flexDirection='column'
        pb={20}>
        <img className='w-36 md:w-52' src={LogoPNG} alt='logo img' />
        <Box mt={4} className='text-center text-3xl text-black5'>
          Practice Collaboration Tracker
        </Box>
        <Box className='z-10 mt-3 w-80 rounded-xl bg-white px-0 py-8 shadow-default sm:mt-5 sm:w-[404px] sm:p-8'>
          <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' width={1}>
            <Box className='text-center text-xl text-black5'>Sign in</Box>
            <Box mt='18px'>
              <MicrosoftIcon className='cursor-pointer' onClick={handleLogin} data-cy='loginWithMicrosoft' />
            </Box>
          </Box>
        </Box>
        <Box display='flex' alignItems='center' mt={2} className='cursor-pointer' onClick={showFeedbackDialog}>
          <HelpIcon className='text-green' />
          <Box className='ml-2.5'>Need Help?</Box>
        </Box>
      </Box>
    </LayoutComponent>
  );
}
