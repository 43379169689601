import { createEditMeetingCategoryInputValueChanged } from 'slices/meetingCategory';
import { useDispatch, useSelector } from 'stores';

import { Box, FormControl, FormHelperText, TextField } from '@material-ui/core';

import type { MeetingCategoryCreateModel } from 'services/models/meetingTimeEntry.model';

import { useStyles } from './styles';

type Props = Readonly<{
  field: keyof MeetingCategoryCreateModel;
  disabled: boolean;
}>;

const LabelForField: Readonly<Record<keyof MeetingCategoryCreateModel, string>> = {
  id: 'ID',
  name: 'Name',
  description: 'Description',
};

// This is not intended as a general purpose Input Field row, but instead is specific to
// the CreatePracticeDialog, to limit code repetition
export function InputFieldRow({ field, disabled }: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { value, errorMessage } = useSelector(store => ({
    value: store.meetingCategory.createEditDialog.inputValues[field],
    errorMessage: store.meetingCategory.createEditDialog.inputValidationErrors[field],
  }));

  return (
    <Box mt={3}>
      <Box className='text-base font-semibold text-black1' mb={0.5}>
        {LabelForField[field]}
      </Box>
      <FormControl variant='outlined' size='medium' fullWidth classes={{ root: classes.root }}>
        <TextField
          id={field}
          fullWidth
          size='medium'
          InputProps={{ className: 'input' }}
          variant='outlined'
          disabled={disabled}
          placeholder='required'
          value={value || ''}
          error={Boolean(errorMessage)}
          onChange={event => {
            dispatch(createEditMeetingCategoryInputValueChanged({ field, value: event.target.value }));
          }}
        />
        <FormHelperText error>{errorMessage}</FormHelperText>
      </FormControl>
    </Box>
  );
}
