export enum RoutePath {
  Default = '/',
  NotFound = '/404',
  PracticeCollaboration = '/practice-collaboration-tracker',
  PctCreateIframe = '/pct-create-iframe',
  Roster = '/roster',
  Users = '/users',
  Programs = '/programs',
  MeetingCategories = '/meeting-categories',
}

export const GuideUrl =
  'https://vytalizehealth0.sharepoint.com/:w:/s/technology_dept/ER7R4kN5PLhAjgXkg4tkGBABv64PH0RNfHpwHVdvzFHPnA?e=pW860E';
