import { useCallback } from 'react';
import { closeCreatePracticeDialog, showPracticeValidationError } from 'slices/roster';
import { showError, showSuccess } from 'slices/snack';
import { useDispatch, useSelector } from 'stores';

import { Box, Dialog, DialogContent, Divider, Grid } from '@material-ui/core';

import { useCreatePracticeMutation, useEditPracticeMutation } from 'services/roster';
import type { PracticeValidationError } from 'services/roster';

import { Button } from 'components/Button';

import { InputFieldRow } from './InputFieldRow';
import { PracticeValidationErrorInfo } from './PracticeValidationErrorInfo';

export function CreatePracticeDialog() {
  const dispatch = useDispatch();
  const {
    isOpen,
    existingPractice,
    inputValues,
    inputValidationErrors,
    apiValidationError,
    isOverrideWarnings,
    isInputValueDirty,
  } = useSelector(store => store.roster.createPracticeDialog);

  const cancelCreate = () => {
    dispatch(closeCreatePracticeDialog());
  };

  const onSuccess = useCallback(() => {
    const message = `Practice Successfully ${existingPractice ? 'Updated' : 'Created'}.`;
    dispatch(closeCreatePracticeDialog());
    dispatch(showSuccess({ message }));
  }, [dispatch, existingPractice]);

  const onError = useCallback(() => {
    const message = `An error occurred while ${existingPractice ? 'updating' : 'creating'} this Practice.`;
    dispatch(showError({ message }));
  }, [dispatch, existingPractice]);

  const onValidationError = useCallback(
    (error: PracticeValidationError) => {
      dispatch(showPracticeValidationError({ error }));
    },
    [dispatch],
  );

  const { isCreatePracticeInProgress, createPractice } = useCreatePracticeMutation({
    onSuccess,
    onError,
    onValidationError,
  });
  const { isEditPracticeInProgress, editPractice } = useEditPracticeMutation({ onSuccess, onError, onValidationError });
  const inProgress = isCreatePracticeInProgress || isEditPracticeInProgress;

  const validateAndSavePractice = () => {
    const { tin, displayName, legalBusinessName, doingBusinessAsName, programId, programStartDate } = inputValues;
    if (tin && legalBusinessName) {
      const validatedInputValues = {
        tin,
        displayName,
        legalBusinessName,
        doingBusinessAsName,
        overrideWarnings: isOverrideWarnings,
      };
      if (existingPractice) {
        editPractice({
          ...validatedInputValues,
          id: existingPractice.id,
          sourceId: existingPractice.sourceId,
        });
      } else if (programId && programStartDate) {
        createPractice({
          ...validatedInputValues,
          program: { programId, startDate: programStartDate },
        });
      }
    }
  };

  const isValidToCreate =
    (isInputValueDirty || isOverrideWarnings) &&
    Boolean(inputValues.tin) &&
    Boolean(inputValues.legalBusinessName) &&
    Object.values(inputValidationErrors).filter(error => error).length === 0 &&
    (existingPractice || (Boolean(inputValues.programId) && Boolean(inputValues.programStartDate)));

  return (
    <Dialog
      maxWidth={apiValidationError ? 'lg' : 'sm'}
      fullWidth
      open={isOpen}
      onClose={(event: object, reason: string) => {
        reason !== 'backdropClick' && cancelCreate();
      }}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'>
      <DialogContent className='p-8'>
        <Box className='xl:flex' justifyContent='space-between' alignItems='center'>
          <Box pr={3}>
            <span className='text-3xl font-semibold text-black'>
              {existingPractice ? 'Editing Practice' : 'Create a New Practice'}
            </span>
          </Box>
        </Box>
        <Grid container item direction='row'>
          <Grid item xs={apiValidationError ? 5 : 12}>
            <InputFieldRow field='tin' disabled={inProgress} />
            <InputFieldRow field='displayName' disabled={inProgress} />
            <InputFieldRow field='legalBusinessName' disabled={inProgress} />
            <InputFieldRow field='doingBusinessAsName' disabled={inProgress} />
            {!existingPractice && (
              <>
                <InputFieldRow field='programId' disabled={inProgress} />
                <InputFieldRow field='programStartDate' disabled={inProgress} />
              </>
            )}
          </Grid>
          {apiValidationError && (
            <Grid item xs={7}>
              <Box className='flex h-full'>
                <Divider orientation='vertical' className='mx-8 hidden border-divider xl:block' />
                <PracticeValidationErrorInfo />
              </Box>
            </Grid>
          )}
        </Grid>
        <Box mt={5}>
          <Divider />
          <Box mt={5} display='flex' justifyContent='center' alignItems='center'>
            <Box mx={2}>
              <Button
                id='dialog-close-button'
                onClick={cancelCreate}
                disabled={inProgress}
                color='tertiary'
                size='medium'
                borderRadius={50}>
                Cancel
              </Button>
            </Box>
            <Box>
              <Button
                id='add-practice-button'
                size='medium'
                disabled={inProgress || !isValidToCreate}
                loading={inProgress}
                onClick={validateAndSavePractice}
                borderRadius={50}>
                {existingPractice ? 'Save Changes' : 'Create Practice'}
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
