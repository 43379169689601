import * as Sentry from '@sentry/react';
import { useAuth } from 'contexts/AuthContext';
import { useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import {
  closeCreateDialog,
  closeDeleteDialog,
  openCreateDialog,
  reset,
  updateLoading,
} from 'slices/practiceCollaboration';
import { showError, showSuccess } from 'slices/snack';
import { useDispatch, useSelector } from 'stores';

import {
  createMeeting,
  deleteMeeting,
  updateMeeting,
  useMeetingTimeEntries,
} from 'services/meeting-time-entry/meetingTimeEntry.service';
import type { CreateEntryModel, MeetingModel } from 'services/models/meetingTimeEntry.model';

export function useFacade(): [(entry: CreateEntryModel, meetingId: string) => void, () => void] {
  const { isOpenCreateDialog } = useAuth();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { selectedId } = useSelector(state => state.practiceCollaboration);
  const { meetings } = useMeetingTimeEntries();

  const createMeetingMutation = useMutation(createMeeting);
  const updateMeetingMutation = useMutation(updateMeeting);
  const deleteMeetingMutation = useMutation(deleteMeeting);

  const handleAddConsult = (entry: CreateEntryModel, meetingId: string) => {
    dispatch(updateLoading());
    const cb = () => {
      if (!isOpenCreateDialog) {
        dispatch(closeCreateDialog());
      }
      dispatch(updateLoading());
    };

    if (meetingId) {
      updateMeetingMutation.mutate(
        { id: meetingId, updateEntry: entry },
        {
          onSuccess: () => {
            queryClient.invalidateQueries('meetings');
            dispatch(showSuccess({ message: 'Time Entry Successfully Updated' }));
            cb();
          },
          onError: error => {
            dispatch(showError({ message: 'An error occurred while attempting to update a Time Entry.' }));
            cb();
            Sentry.captureException(error);
          },
        },
      );
    } else {
      createMeetingMutation.mutate(entry, {
        onSuccess: () => {
          queryClient.invalidateQueries('meetings');
          dispatch(showSuccess({ message: 'Time Entry Successfully Created' }));
          cb();
        },
        onError: error => {
          dispatch(showError({ message: 'An error occurred while attempting to create a Time Entry.' }));
          cb();
          Sentry.captureException(error);
        },
      });
    }
  };

  const handleDeleteEntry = () => {
    const entry: MeetingModel | undefined = meetings?.find((x: MeetingModel) => x.id === selectedId);
    if (entry) {
      dispatch(updateLoading());

      const cb = () => {
        dispatch(closeDeleteDialog());
        dispatch(updateLoading());
      };

      deleteMeetingMutation.mutate(entry, {
        onSuccess: () => {
          queryClient.invalidateQueries('meetings');
          dispatch(showSuccess({ message: 'Time Entry Successfully Deleted' }));
          cb();
        },
        onError: error => {
          dispatch(showError({ message: 'An error occurred while attempting to delete a Time Entry.' }));
          cb();
          Sentry.captureException(error);
        },
      });
    }
  };

  useEffect(
    () => () => {
      dispatch(reset());
    },
    [dispatch],
  );

  useEffect(() => {
    if (isOpenCreateDialog) {
      dispatch(openCreateDialog({ meetingId: '' }));
    }
  }, [isOpenCreateDialog, dispatch]);

  return [handleAddConsult, handleDeleteEntry];
}
