import { useCallback } from 'react';
import { clearPracticeToDelete } from 'slices/roster';
import { showError, showSuccess } from 'slices/snack';
import { useDispatch, useSelector } from 'stores';

import type { PracticeDeleteInput } from 'services/models/roster.model';
import { useDeletePracticeMutation } from 'services/roster';

import { ConfirmationDialog } from 'components/ConfirmationDialog';

export function DeletePracticeDialog() {
  const dispatch = useDispatch();
  const { practiceToDelete } = useSelector(store => ({
    practiceToDelete: store.roster.practiceToDelete,
  }));
  const onSuccess = useCallback(() => {
    dispatch(clearPracticeToDelete());
    dispatch(showSuccess({ message: 'Practice Successfully Deleted' }));
  }, [dispatch]);
  const onError = useCallback(() => {
    dispatch(clearPracticeToDelete());
    dispatch(showError({ message: 'An error occurred while deleting this Practice.' }));
  }, [dispatch]);
  const { isDeletePracticeInProgress, deletePractice } = useDeletePracticeMutation({ onSuccess, onError });

  const onCancel = () => {
    dispatch(clearPracticeToDelete());
  };
  const onConfirm = () => {
    // This method can only be called when practiceToDelete is set,
    // but TS thinks it could still be undefined
    deletePractice(practiceToDelete as PracticeDeleteInput);
  };

  return (
    <ConfirmationDialog
      isOpen={Boolean(practiceToDelete)}
      isDisabled={isDeletePracticeInProgress}
      message='Are you sure you want to delete this practice?'
      handleCancel={onCancel}
      handleConfirm={onConfirm}
    />
  );
}
