import { Box, Tooltip } from '@material-ui/core';

import { useStyles } from './styles';
import type { TooltipInfo } from './types';

type Props = Readonly<{
  children: React.ReactElement;
  tooltip?: TooltipInfo;
}>;

export function WithOptionalTooltip({ tooltip, children }: Props) {
  const classes = useStyles();

  if (!tooltip) {
    return children;
  }
  return (
    <Tooltip
      title={
        <Box display='flex' alignItems='center' px={2}>
          <tooltip.Icon className={`mr-2 ${tooltip.colorClassName || 'text-black5'}`} />
          {tooltip.text}
        </Box>
      }
      placement='top'
      arrow
      classes={{ tooltip: classes.customTooltip, arrow: classes.arrow }}>
      {children}
    </Tooltip>
  );
}
