import * as Sentry from '@sentry/react';
import { useCallback } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import API from 'utils/helpers/axiosInstance';

import type {
  MeetingCategoryCreateModel,
  MeetingCategoryEditModel,
  MeetingCategoryFullModel,
} from './models/meetingTimeEntry.model';

async function getMeetingCategoriesApi() {
  const result = await API.get<ReadonlyArray<MeetingCategoryFullModel>>('meetingCategory');
  return result.data;
}

async function createMeetingCategoryApi(input: MeetingCategoryCreateModel) {
  const result = await API.post<MeetingCategoryFullModel>('meetingCategory', input);
  return result.data;
}

async function editMeetingCategoryApi({ id, ...body }: MeetingCategoryEditModel) {
  const result = await API.put<MeetingCategoryFullModel>(`meetingCategory/${id}`, body);
  return result.data;
}

export function useMeetingCategoriesQuery() {
  const { isLoading, isRefetching, data: meetingCategories } = useQuery('meetingCategories', getMeetingCategoriesApi);
  return { isLoading: isLoading || isRefetching, meetingCategories };
}

type MutationCallbacks = Readonly<{
  onSuccess: (result: MeetingCategoryFullModel) => void;
  onError: (error: unknown) => void;
}>;

export function useCreateMeetingCategoryMutation({ onSuccess, onError }: MutationCallbacks) {
  const queryClient = useQueryClient();
  const { isLoading, mutate } = useMutation(createMeetingCategoryApi);
  const createMeetingCategory = useCallback(
    (input: MeetingCategoryCreateModel) => {
      mutate(input, {
        onSuccess: meetingCategory => {
          queryClient.invalidateQueries('meetingCategories'); // Trigger refetch
          queryClient.invalidateQueries('list/meetingCategories');
          onSuccess(meetingCategory);
        },
        onError: (error: unknown) => {
          Sentry.captureException(error);
          onError(error);
        },
      });
    },
    [mutate, queryClient, onSuccess, onError],
  );

  return { inProgress: isLoading, createMeetingCategory };
}

export function useEditMeetingCategoryMutation({ onSuccess, onError }: MutationCallbacks) {
  const queryClient = useQueryClient();
  const { isLoading, mutate } = useMutation(editMeetingCategoryApi);
  const editMeetingCategory = useCallback(
    (input: MeetingCategoryEditModel) => {
      mutate(input, {
        onSuccess: meetingCategory => {
          queryClient.invalidateQueries('meetingCategories'); // Trigger refetch
          queryClient.invalidateQueries('list/meetingCategories');
          onSuccess(meetingCategory);
        },
        onError: (error: unknown) => {
          Sentry.captureException(error);
          onError(error);
        },
      });
    },
    [mutate, queryClient, onSuccess, onError],
  );

  return { inProgress: isLoading, editMeetingCategory };
}
