export enum PracticeColumnKey {
  DisplayName = 'DisplayName',
  TIN = 'TIN',
  ActiveProgram = 'ActiveProgram',
  StaffCount = 'StaffCount',
  CreatedDate = 'CreatedDate',
  UpdatedDate = 'UpdatedDate',
}

export enum PracticeSortField {
  name = 'name',
  tin = 'tin',
  staffCount = 'staffCount',
  createdDate = 'createdDate',
  updatedDate = 'updatedDate',
}

export type SourceInfo = Readonly<{
  timestamp: string;
  user: Readonly<{
    email: string;
    firstName: string;
    lastName: string;
  }> | null;
}>;

export type AllowedActions = Readonly<{
  edit: boolean;
  delete: boolean;
}>;

export type ProgramInfo = Readonly<{
  id: string;
  name: string;
}>;

export type StaffInfo = Readonly<{
  id: string;
  displayName: string;
  firstName: string;
  middleName: string | null;
  lastName: string;
  fullName: string | null;
  prefix: string | null;
  suffix: string | null;
  npi: string | null;
  jobTitle: string | null;
  contractRoleId: string;
  contractRoleName: string;
  meetingsAttended: number;
  sourceId: string;
  created: SourceInfo;
  updated: SourceInfo;
  allowedActions: AllowedActions;
}>;

export type PracticeInfo = Readonly<{
  id: string;
  displayName: string;
  legalBusinessName: string;
  doingBusinessAsName: string | null;
  tin: string;
  staff: ReadonlyArray<StaffInfo>;
  activeProgram?: Readonly<{ id: string; name: string }> | null;
  sourceId: string;
  created: SourceInfo;
  updated: SourceInfo;
  allowedActions: AllowedActions;
}>;

export type PracticeFilterInput = Readonly<{
  name?: string;
  tin?: string | ReadonlyArray<string>;
  staffName?: string;
  staffNpi?: string | ReadonlyArray<string>;
  staffJobTitle?: string | ReadonlyArray<string>;
  createdDate?: Readonly<{ min?: string; max?: string }>;
  updatedDate?: Readonly<{ min?: string; max?: string }>;
  createdUserId?: string | ReadonlyArray<string>;
  updatedUserId?: string | ReadonlyArray<string>;
}>;

export type PracticeCreateInput = Readonly<{
  displayName?: string | null;
  legalBusinessName: string;
  doingBusinessAsName?: string | null;
  tin: string;
  program: Readonly<{
    programId: string;
    startDate: string;
  }>;
  overrideWarnings?: boolean;
}>;

export type PracticeEditInput = Omit<PracticeCreateInput, 'program'> &
  Readonly<{
    id: string;
    sourceId: string;
  }>;

export type PracticeDeleteInput = Readonly<{
  id: string;
  sourceId: string;
}>;

export type StaffCreateInput = Readonly<{
  practiceId?: string;
  displayName?: string | null;
  firstName: string;
  middleName?: string | null;
  lastName: string;
  fullName?: string | null;
  prefix?: string | null;
  suffix?: string | null;
  npi?: string | null;
  jobTitle: string;
  contractRoleId: string;
  overrideWarnings?: boolean;
}>;

export type StaffEditInput = StaffCreateInput &
  Readonly<{
    id: string;
    sourceId: string;
  }>;

export type StaffAddToPracticeInput = Readonly<{
  staffId: string;
  practiceId: string;
  contractRoleId: string;
  jobTitle?: string | null;
}>;

export type StaffDeleteInput = Readonly<{
  staffId: string;
  practiceId: string;
  sourceId: string;
}>;
