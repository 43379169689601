import { ProgramColumnKey } from 'services/models/program.model';
import type { ProgramAndRelatedItemsModel, ProgramSortField } from 'services/models/program.model';

import type { ColumnDefinition } from 'components/BanzaiTable';

export const PROGRAM_COLUMN_DEFINITIONS: ReadonlyArray<
  ColumnDefinition<ProgramColumnKey, ProgramSortField, ProgramAndRelatedItemsModel>
> = [
  {
    key: ProgramColumnKey.Name,
    title: 'Program Name',
    width: '40%',
    extractCellContents: program => ({
      primary: { text: program.name },
    }),
  },
  {
    key: ProgramColumnKey.PracticeCount,
    title: 'Practice',
    width: '30%',
    extractCellContents: program => ({
      primary: { text: `${program.practices.length} Practices` },
    }),
  },
  {
    key: ProgramColumnKey.MeetingCategoryCount,
    title: 'Meeting Category',
    width: '30%',
    extractCellContents: program => ({
      primary: { text: `${program.meetingCategories.length} Meeting Categories` },
    }),
  },
];
