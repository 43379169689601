import { Box, Dialog, DialogContent } from '@material-ui/core';

import { Button } from 'components/Button';

type Props = Readonly<{
  isOpen: boolean;
  isDisabled?: boolean;
  message: string;
  handleCancel: () => void;
  handleConfirm: () => void;
}>;

export function ConfirmationDialog({ isOpen, isDisabled, message, handleCancel, handleConfirm }: Props) {
  return (
    <Dialog
      open={isOpen}
      onClose={(e, reason: string) => {
        reason !== 'backdropClick' && handleCancel();
      }}
      aria-labelledby='delete-dialog-title'
      aria-describedby='delete-dialog-description'>
      <DialogContent className='w-[565px]'>
        <Box mt={3} className='w-full text-center'>
          <Box className='text-xl font-normal text-black1'>{message}</Box>
        </Box>
        <Box mt={5} mb={3} display='flex' justifyContent='center' alignItems='center'>
          <Box mx={2}>
            <Button
              id='dialog-close-button'
              data-cy='cancelDeleteTimeEntryButton'
              onClick={() => handleCancel()}
              color='tertiary'
              size='medium'
              borderRadius={50}
              disabled={isDisabled}>
              Cancel
            </Button>
          </Box>
          <Box>
            <Button
              id='delete-entry-button'
              data-cy='proceedDeleteTimeEntryButton'
              size='medium'
              color='primary'
              onClick={() => handleConfirm()}
              borderRadius={50}
              loading={isDisabled}>
              Proceed
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
