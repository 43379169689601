import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import * as Sentry from '@sentry/react';
import { msalConfig } from 'authConfig';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import store from 'stores';

import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';

import { colors } from 'utils/constants/colors.constant';

import 'styles/css/index.css';

import App from './App';

if (
  process.env.REACT_APP_SENTRY_DSN &&
  process.env.REACT_APP_SENTRY_SAMPLE_RATE &&
  process.env.REACT_APP_SENTRY_ENVIRONMENT
) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    replaysSessionSampleRate: 0.01,
    // In buffer mode, Replay will continuously record data, but won't send any of it.
    // It will instead keep up to the last 60 seconds in memory. When an error occurs,
    // replaysOnErrorSampleRate will be checked and if it's sampled, the replay will be uploaded
    // to Sentry and continue recording normally.
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new Sentry.BrowserTracing(),
      new Sentry.Replay({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: parseFloat(process.env.REACT_APP_SENTRY_SAMPLE_RATE),
    tracePropagationTargets: [], // Don't include sentry-trace header for now. See https://develop.sentry.dev/sdk/performance/#header-sentry-trace
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  });
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
const msalInstance = new PublicClientApplication(msalConfig);

const theme = createTheme({
  palette: {
    primary: {
      main: colors.main,
    },
  },
});

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <MsalProvider instance={msalInstance}>
        <MuiThemeProvider theme={theme}>
          <App />
        </MuiThemeProvider>
      </MsalProvider>
    </Provider>
  </QueryClientProvider>,
);
