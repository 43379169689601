import { useProgramsList } from 'services/listItems.service';

import { BanzaiDropDown } from './BanzaiDropDown';

type Props = Readonly<{
  label?: string;
  selectedId?: string;
  errorMessage?: string | null;
  disabled?: boolean;
  onSelected: (id: string) => void;
}>;

export function SelectProgramDropDown({ label, selectedId, errorMessage, disabled, onSelected }: Props) {
  const { isLoading, programs } = useProgramsList();

  return (
    <BanzaiDropDown
      id='program'
      label={label || 'Program'}
      placeholder='– Select Program –'
      items={programs}
      value={selectedId}
      disabled={disabled || isLoading || !programs?.length}
      errorMessage={errorMessage}
      onSelected={onSelected}
    />
  );
}
