import { useState } from 'react';
import { openAdditionalSearchDialog, setMeetingFilters } from 'slices/practiceCollaboration';
import { useDispatch, useSelector } from 'stores';

import { Box } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { ReactComponent as FilterIcon } from 'assets/svgs/filterIcon.svg';

import type { MeetingsSearchState } from 'services/models/meetingTimeEntry.model';

import { Button } from 'components/Button';

import { FilterAndSearchSectionComponent } from './FilterAndSearchSectionComponent';

type Props = Readonly<{
  isLoading: boolean;
}>;

export function FilterToolbar({ isLoading }: Props) {
  const dispatch = useDispatch();

  const { meetingFilters, additionalFilters } = useSelector(store => store.practiceCollaboration);
  const [state, setstate] = useState<MeetingsSearchState>(meetingFilters);

  const submitFilter = () => {
    dispatch(setMeetingFilters({ value: state }));
  };

  const handleChanges = (field: string, value: any) => {
    if (field === 'practices') {
      setstate({ ...state, staffs: [] });
    }
    setstate({ ...state, [field]: value });
  };

  const filters = Object.keys(additionalFilters).filter(key => {
    const filterState: any = additionalFilters;
    if (
      filterState[key] !== null &&
      filterState[key] !== 'Invalid date' &&
      filterState[key] !== '' &&
      !!filterState[key].length
    ) {
      return true;
    }

    if (filterState[key] === true) {
      return true;
    }
    return false;
  }).length;

  return (
    <Box mt={4} display='flex' justifyContent='space-between'>
      <FilterAndSearchSectionComponent isLoading={isLoading} filters={state} handleChanges={handleChanges} />
      <Box display='flex' alignItems='flex-end' ml={3} gridGap={10}>
        <Button
          id='filter-button'
          color='white'
          borderRadius={50}
          size='medium'
          disabled={isLoading}
          onClick={() => {
            dispatch(openAdditionalSearchDialog());
          }}>
          <Box display='flex' alignItems='center'>
            <FilterIcon />
            <span className='ml-1'>Filters{filters > 0 && ` (${filters})`}</span>
          </Box>
        </Button>
        <Button
          id='search-button'
          color='black'
          borderRadius={50}
          size='medium'
          disabled={isLoading}
          onClick={submitFilter}>
          <Box display='flex' alignItems='center'>
            <SearchIcon />
            <span className='ml-1'>Search</span>
          </Box>
        </Button>
      </Box>
    </Box>
  );
}
