import { useQuery } from 'react-query';
import { useSelector } from 'stores';

import type {
  AdditionalFilterState,
  CreateEntryModel,
  FilterModel,
  MeetingModel,
  MeetingSortField,
  MeetingsSearchState,
  UpdateEntryModel,
} from 'services/models/meetingTimeEntry.model';
import type { SortApiInput } from 'services/models/sorting.model';

import API from 'utils/helpers/axiosInstance';

export const getMeetingCategories = (practiceId: string, meetingDate: string) =>
  API.get(`time-entry/practice/${practiceId}/meetingCategories?meetingDate=${meetingDate}`).then(res => res.data);

export const createMeeting = (model: CreateEntryModel) => API.post(`time-entry/meeting`, model).then(res => res.data);

export const updateMeeting = (body: UpdateEntryModel) =>
  API.put(`time-entry/meeting/${body.id}`, body.updateEntry).then(res => res.data);

export const deleteMeeting = (meeting: MeetingModel) =>
  API.delete(`time-entry/meeting/${meeting.id}`, { data: { sourceId: meeting.sourceId } }).then(res => res.data);

const initialFilterOption: FilterModel = {
  statementMonth: null,
  meetingCategoryId: null,
  practiceId: null,
  createdByUserId: null,
  staffId: null,
  contractRoleId: null,
  createdDate: null,
  meetingDate: null,
};

async function getMeetingsApi(
  meetingFilters: MeetingsSearchState,
  additionalFilters: AdditionalFilterState,
  sort?: SortApiInput<MeetingSortField>,
): Promise<ReadonlyArray<MeetingModel>> {
  const queryStringComponents = [];
  if (sort) {
    queryStringComponents.push(`sort=${encodeURIComponent(JSON.stringify(sort))}`);
  }
  const filter: FilterModel = { ...initialFilterOption };

  if (meetingFilters.meetingCategories.length) {
    filter.meetingCategoryId = meetingFilters.meetingCategories.map(x => x.id);
  }

  if (meetingFilters.statementMonth.length) {
    filter.statementMonth = meetingFilters.statementMonth;
  }

  if (meetingFilters.practices.length) {
    filter.practiceId = meetingFilters.practices.map(x => x.id);
  }

  if (meetingFilters.staffs.length) {
    filter.staffId = meetingFilters.staffs.map(x => x.id);
  }

  if (additionalFilters.enteredBy.length) {
    filter.createdByUserId = additionalFilters.enteredBy.map(x => x.id);
  }

  if (additionalFilters.contractRoles.length) {
    filter.contractRoleId = additionalFilters.contractRoles.map(x => x.id);
  }

  if (additionalFilters.entryCreatedStart) {
    filter.createdDate = {
      ...filter.createdDate,
      min: `${additionalFilters.entryCreatedStart}T00:00:00.000Z`,
    };
  }

  if (additionalFilters.entryCreatedEnd) {
    filter.createdDate = {
      ...filter.createdDate,
      max: `${additionalFilters.entryCreatedEnd}T23:59:00.000Z`,
    };
  }

  if (additionalFilters.meetingRangeStart) {
    filter.meetingDate = {
      ...filter.meetingDate,
      min: `${additionalFilters.meetingRangeStart}T00:00:00.000Z`,
    };
  }

  if (additionalFilters.meetingRangeEnd) {
    filter.meetingDate = {
      ...filter.meetingDate,
      max: `${additionalFilters.meetingRangeEnd}T23:59:00.000Z`,
    };
  }

  const filteredKeys = Object.keys(filter).filter(key => !!filter[key as keyof FilterModel]);
  if (filteredKeys.length) {
    const reduceFilter = filteredKeys.reduce((a, b) => ({ ...a, [b]: filter[b as keyof FilterModel] }), {});
    queryStringComponents.push(`filter=${encodeURIComponent(JSON.stringify(reduceFilter))}`);
  }
  queryStringComponents.push(`includeDeleted=${!additionalFilters.isShowDeletedEntry}`);

  const queryString = queryStringComponents.length ? `?${queryStringComponents.join('&')}` : '';
  const result = await API.get<MeetingModel[]>(`time-entry/meetings${queryString}`);
  return result.data;
}

export function useMeetingTimeEntries() {
  const [activeSortColumn, meetingFilters, additionalFilters] = useSelector(store => [
    store.practiceCollaboration.activeSortColumn,
    store.practiceCollaboration.meetingFilters,
    store.practiceCollaboration.additionalFilters,
  ]);
  const {
    isLoading,
    isRefetching,
    data: meetings,
  } = useQuery(['meetings', activeSortColumn, meetingFilters, additionalFilters], () => {
    const sort = [activeSortColumn];
    return getMeetingsApi(meetingFilters, additionalFilters, sort);
  });
  return { isLoading: isLoading || isRefetching, meetings };
}
