import { useGate } from 'statsig-react';

import { useMeetingCategoriesList, usePracticesList } from 'services/listItems.service';
import type { CreateEntryModel } from 'services/models/meetingTimeEntry.model';

import { ConfirmationDialog } from 'components/ConfirmationDialog';

import { SelectAttendeesDialog } from '../SelectAttendeeDialog';
import { SelectPracticeDialog } from '../SelectPracticeDialog';
import { useFacade } from './CreateAndUpdateTimeEntryDialog.hooks';
import { NewMeetingTimeEntryDialog } from './NewMeetingTimeEntryDialog';
import { OldMeetingTimeEntryDialog } from './OldMeetingTimeEntryDialog';

export interface CreateAndUpdateTimeEntryDialogProps {
  isOpen: boolean;
  isLoading: boolean;
  editMeetingId: string;
  handleCreate: (entry: CreateEntryModel, meetingId: string) => void;
  handleClose: () => void;
}

export function CreateAndUpdateTimeEntryDialog(props: CreateAndUpdateTimeEntryDialogProps) {
  const { isOpen, isLoading, editMeetingId, handleClose, handleCreate } = props;

  const [
    state,
    meetingCategoriesAndPrograms,
    handleChanges,
    handleAttendeeChanges,
    handleRemoveAttendee,
    handleDurationChanges,
    isCheckedForCreating,
    handleToggleConfirmPracticeDialog,
    handleSubmit,
  ] = useFacade(isOpen, editMeetingId, handleCreate);

  const { value: isFeatureEnabled } = useGate('pct-aco3-meeting-categories');
  const { isLoading: isMeetingCategoriesLoading, meetingCategories } = useMeetingCategoriesList();
  const { isLoading: isPracticesLoading, practices } = usePracticesList();

  return (
    <>
      {isFeatureEnabled ? (
        <NewMeetingTimeEntryDialog
          isOpen={isOpen}
          isLoading={isLoading || isMeetingCategoriesLoading || isPracticesLoading}
          params={state.createConsultParams}
          errors={state.errors}
          meetingCategories={meetingCategoriesAndPrograms}
          practices={practices}
          isCheckedForCreating={isCheckedForCreating()}
          handleClose={handleClose}
          handleChanges={handleChanges}
          handleCreate={handleSubmit}
          handleRemoveAttendee={handleRemoveAttendee}
          handleDurationChanges={handleDurationChanges}
          handleToggleConfirm={handleToggleConfirmPracticeDialog}
        />
      ) : (
        <OldMeetingTimeEntryDialog
          isOpen={isOpen}
          isLoading={isLoading || isMeetingCategoriesLoading || isPracticesLoading}
          params={state.createConsultParams}
          errors={state.errors}
          meetingCategories={meetingCategories}
          practices={practices}
          isCheckedForCreating={isCheckedForCreating()}
          handleClose={handleClose}
          handleChanges={handleChanges}
          handleCreate={handleSubmit}
          handleRemoveAttendee={handleRemoveAttendee}
          handleDurationChanges={handleDurationChanges}
          handleToggleConfirm={handleToggleConfirmPracticeDialog}
        />
      )}
      <SelectPracticeDialog
        params={state.createConsultParams}
        practices={practices}
        handleToggleConfirm={handleToggleConfirmPracticeDialog}
        handleSelectPractice={handleChanges}
      />
      <SelectAttendeesDialog
        params={state.createConsultParams}
        practices={practices}
        handleAttendeeChanges={handleAttendeeChanges}
      />
      <ConfirmationDialog
        isOpen={state.isOpenConfirmPractice}
        message='Changing the Practice selection will remove all selected Attendees'
        handleCancel={() => handleToggleConfirmPracticeDialog('')}
        handleConfirm={() => handleChanges('practiceId', state.selectedId)}
      />
    </>
  );
}
