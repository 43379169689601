import { TableCell, TableRow, createStyles, makeStyles, withStyles } from '@material-ui/core';

import { colors } from 'utils/constants/colors.constant';

export const useStyles = makeStyles(() => ({
  customTooltip: {
    backgroundColor: colors.divider,
    maxWidth: 'none',
    padding: 12,
    color: colors.black1,
    fontSize: 16,
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  arrow: {
    color: colors.divider,
  },
}));

export const CustomTableCell = withStyles(() =>
  createStyles({
    root: {
      borderBottom: '1px solid #EFF3F8',
    },
    head: {
      color: '#25282B',
      fontWeight: 600,
    },
    body: {
      color: '#25282B',
      fontWeight: 400,
    },
  }),
)(TableCell);

export const CustomTableRow = withStyles(() => ({
  root: {
    '&:hover': {
      backgroundColor: '#F2F9F5',
    },
  },
}))(TableRow);
