import { useEffect, useState } from 'react';
import { timer } from 'rxjs';
import type { Subscription } from 'rxjs';

export function useFacade(
  timeout: number,
  onClick?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
): [boolean, (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void] {
  const [canExecute, setCanExecute] = useState(true);

  const handleOnClick = (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setCanExecute(() => false);
    if (onClick) {
      onClick(event);
    }
  };

  const useEffectCB = () => {
    const timerSubscription = timer(timeout).subscribe(() => {
      setCanExecute(() => true);
      timerSubscription.unsubscribe();
    });

    const subscriptions: Subscription[] = [timerSubscription];

    return () => {
      /* eslint-disable array-callback-return */
      subscriptions.map(it => {
        it.unsubscribe();
      });
    };
  };

  useEffect(useEffectCB, [canExecute, timeout]);

  return [canExecute, handleOnClick];
}
