export interface Colors {
  main: string;
  mainDisabled: string;
  black: string;
  black1: string;
  black2: string;
  black3: string;
  black4: string;
  black5: string;
  white: string;
  error: string;
  disabled: string;
  gray1: string;
  gray2: string;
  gray3: string;
  gray4: string;
  stroke: string;
  divider: string;
}

const vytalizeColors: Colors = {
  main: '#008a3f',
  mainDisabled: '#B9CFC3',
  black: '#000000',
  black1: '#25282B',
  black2: '#75757A',
  black3: '#C4C4C4',
  black4: '#7D7D7D',
  black5: '#1B2B3A',
  white: '#FFFFFF',
  error: '#E7635D',
  disabled: '#E7E7EC',
  gray1: '#7C8A9D',
  gray2: '#AFBACA',
  gray3: '#F2F9F5',
  gray4: '#C1CCD3',
  stroke: '#EAEFF5',
  divider: '#EBF0F5',
};

export const colors = vytalizeColors;
