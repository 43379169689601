export enum ValidationSeverity {
  Error = 'Error',
  Warning = 'Warning',
}

export type ValidationError<IssueType extends string, ItemType extends object> = Readonly<{
  message: string;
  type: IssueType;
  severity: ValidationSeverity;
  relatedItems?: ReadonlyArray<ItemType>;
}>;
