import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

import type {
  MeetingCategoryToProgramInput,
  PracticeToProgramInput,
  ProgramMeetingCategoryModel,
  ProgramPracticeModel,
} from 'services/models/program.model';
import { ProgramSortField } from 'services/models/program.model';
import { type SortColumn, SortingDirection } from 'services/models/sorting.model';

import { dateValidationRule, requiredValidationRule } from 'utils/validation/general-validation-rules';
import { GeneralValidator } from 'utils/validation/general-validator';

type PracticeToProgramInputFieldName = keyof Omit<PracticeToProgramInput, 'overrideWarnings'>;
type MeetingCategoryToProgramInputFieldName = keyof Omit<MeetingCategoryToProgramInput, 'overrideWarnings'>;

type AddPracticeDialogState = Readonly<{
  isOpen: boolean;
  programId?: string;
  existingProgramPractice?: ProgramPracticeModel;
  inputValues: Readonly<{
    [Field in PracticeToProgramInputFieldName]?: string;
  }>;
  inputValidationErrors: Readonly<{
    [Field in PracticeToProgramInputFieldName]?: string | null;
  }>;
  isInputValueDirty: boolean;
}>;

type AddMeetingCategoryDialogState = Readonly<{
  isOpen: boolean;
  programId?: string;
  existingProgramMeetingCategory?: ProgramMeetingCategoryModel;
  inputValues: Readonly<{
    [Field in MeetingCategoryToProgramInputFieldName]?: MeetingCategoryToProgramInput[Field];
  }>;
  inputValidationErrors: Readonly<{
    [Field in MeetingCategoryToProgramInputFieldName]?: string | null;
  }>;
  isInputValueDirty: boolean;
}>;

type ProgramState = Readonly<{
  activeSortColumn: SortColumn<ProgramSortField>;
  addPracticeDialog: AddPracticeDialogState;
  addMeetingCategoryDialog: AddMeetingCategoryDialogState;
  practiceToDelete?: Readonly<{ programPracticeId: string; sourceId: string }>;
  meetingCategoryToDelete?: Readonly<{ programMeetingCategoryId: string; sourceId: string }>;
}>;

// Helper type to solve readonly issues in draft state
type Writeable<T> = { -readonly [P in keyof T]: Writeable<T[P]> };

const initialState: ProgramState = {
  activeSortColumn: { field: ProgramSortField.name, direction: SortingDirection.Asc },
  addPracticeDialog: {
    isOpen: false,
    inputValues: {},
    inputValidationErrors: {},
    isInputValueDirty: false,
  },
  addMeetingCategoryDialog: {
    isOpen: false,
    inputValues: {
      paid: true,
    },
    inputValidationErrors: {},
    isInputValueDirty: false,
  },
};

function isAnyInputValueDirty(
  inputValues: Record<string, string | boolean | null | undefined>,
  baseValues: Record<string, string | number | boolean | null | undefined | object>,
): boolean {
  return Object.keys(inputValues).some(key =>
    // If the input value is truthy, see if the actual values have changed.
    // If the input value is falsy, see if the actual value is truthy
    inputValues[key] ? inputValues[key] !== baseValues[key] : Boolean(baseValues[key]),
  );
}

const validator = new GeneralValidator({
  startDate: [dateValidationRule('Start Date is invalid'), requiredValidationRule()],
  endDate: [dateValidationRule('End Date is invalid')],
});

const updateDateValidationState = (startDate?: string, endDate?: string | null) => {
  if (startDate === 'Invalid date' || endDate === 'Invalid date') {
    return null;
  }
  if (moment(startDate).isSameOrAfter(moment(endDate))) {
    return {
      field: 'endDate',
      value: 'End Date cannot be same as, or before, Start Date',
    };
  }
  return null;
};

const slice = createSlice({
  name: 'program',
  initialState,
  reducers: {
    reset(state) {
      state.activeSortColumn = initialState.activeSortColumn as Writeable<SortColumn<ProgramSortField>>;
      state.addPracticeDialog = initialState.addPracticeDialog as Writeable<AddPracticeDialogState>;
      state.addMeetingCategoryDialog =
        initialState.addMeetingCategoryDialog as Writeable<AddMeetingCategoryDialogState>;
    },
    sortByColumn(state, action: PayloadAction<{ field: ProgramSortField; defaultSortDirection?: SortingDirection }>) {
      state.activeSortColumn = {
        field: action.payload.field,
        direction:
          action.payload.field === state.activeSortColumn.field
            ? state.activeSortColumn.direction === SortingDirection.Asc
              ? SortingDirection.Desc
              : SortingDirection.Asc
            : action.payload.defaultSortDirection || SortingDirection.Asc,
      };
    },
    openAddPracticeDialog(state, action: PayloadAction<{ programId: string }>) {
      const { programId } = action.payload;
      state.addPracticeDialog = {
        ...initialState.addPracticeDialog,
        programId,
        isOpen: true,
      } as Writeable<AddPracticeDialogState>;
    },
    openEditPracticeDialog(state, action: PayloadAction<{ existingProgramPractice: ProgramPracticeModel }>) {
      const { startDate, endDate, id } = action.payload.existingProgramPractice;
      state.addPracticeDialog.existingProgramPractice = action.payload
        .existingProgramPractice as Writeable<ProgramPracticeModel>;
      state.addPracticeDialog.inputValues = {
        practiceId: id,
        startDate: new Date(startDate).toISOString().slice(0, 10),
        endDate: endDate ? new Date(endDate).toISOString().slice(0, 10) : undefined,
      };

      state.addPracticeDialog.isInputValueDirty = isAnyInputValueDirty(
        state.addPracticeDialog.inputValues,
        state.addPracticeDialog.existingProgramPractice || initialState.addPracticeDialog.inputValues,
      );
      state.addPracticeDialog.isOpen = true;
    },
    closeAddPracticeDialog(state) {
      state.addPracticeDialog.isOpen = false;
    },
    practiceInputValueChanged(state, action: PayloadAction<{ field: PracticeToProgramInputFieldName; value: string }>) {
      const { field, value } = action.payload;

      state.addPracticeDialog.inputValues[field] = value || undefined;

      const validationError = validator.validate(field, value);
      state.addPracticeDialog.inputValidationErrors[field] = validationError;

      let validationDateError = null;
      if (field === 'startDate') {
        validationDateError = updateDateValidationState(value, state.addPracticeDialog.inputValues?.endDate);
      } else if (field === 'endDate') {
        validationDateError = updateDateValidationState(state.addPracticeDialog.inputValues?.startDate, value);
      }
      if (validationDateError) {
        state.addPracticeDialog.inputValidationErrors[validationDateError.field as PracticeToProgramInputFieldName] =
          validationDateError.value;
      }

      state.addPracticeDialog.isInputValueDirty = isAnyInputValueDirty(
        state.addPracticeDialog.inputValues,
        state.addPracticeDialog.existingProgramPractice || initialState.addPracticeDialog.inputValues,
      );
    },
    setPracticeToDelete(state, action: PayloadAction<{ programPracticeId: string; sourceId: string }>) {
      state.practiceToDelete = action.payload;
    },
    clearPracticeToDelete(state) {
      delete state.practiceToDelete;
    },
    openAddMeetingCategoryDialog(state, action: PayloadAction<{ programId: string }>) {
      const { programId } = action.payload;
      state.addMeetingCategoryDialog = {
        ...initialState.addMeetingCategoryDialog,
        programId,
        isOpen: true,
      } as Writeable<AddMeetingCategoryDialogState>;
    },
    openEditMeetingCategoryDialog(
      state,
      action: PayloadAction<{ existingProgramMeetingCategory: ProgramMeetingCategoryModel }>,
    ) {
      const { startDate, endDate, id, paid } = action.payload.existingProgramMeetingCategory;
      state.addMeetingCategoryDialog.existingProgramMeetingCategory = action.payload
        .existingProgramMeetingCategory as Writeable<ProgramMeetingCategoryModel>;
      state.addMeetingCategoryDialog.inputValues = {
        meetingCategoryId: id,
        startDate: new Date(startDate).toISOString().slice(0, 10),
        endDate: endDate ? new Date(endDate).toISOString().slice(0, 10) : undefined,
        paid,
      };

      state.addMeetingCategoryDialog.isInputValueDirty = isAnyInputValueDirty(
        state.addMeetingCategoryDialog.inputValues,
        state.addMeetingCategoryDialog.existingProgramMeetingCategory ||
          initialState.addMeetingCategoryDialog.inputValues,
      );
      state.addMeetingCategoryDialog.isOpen = true;
    },
    closeAddMeetingCategoryDialog(state) {
      state.addMeetingCategoryDialog.isOpen = false;
    },
    meetingCategoryInputValueChanged(
      state,
      action: PayloadAction<
        | { field: Exclude<MeetingCategoryToProgramInputFieldName, 'paid'>; value: string }
        | { field: 'paid'; value: boolean }
      >,
    ) {
      const { field, value } = action.payload;
      if (field === 'paid') {
        state.addMeetingCategoryDialog.inputValues[field] = value;
      } else {
        state.addMeetingCategoryDialog.inputValues[field] = value;
      }

      const validationError = validator.validate(field, value.toString());
      state.addMeetingCategoryDialog.inputValidationErrors[field] = validationError;

      let validationDateError = null;
      if (field === 'startDate') {
        validationDateError = updateDateValidationState(value, state.addMeetingCategoryDialog.inputValues?.endDate);
      } else if (field === 'endDate') {
        validationDateError = updateDateValidationState(state.addMeetingCategoryDialog.inputValues?.startDate, value);
      }
      if (validationDateError) {
        state.addMeetingCategoryDialog.inputValidationErrors[
          validationDateError.field as MeetingCategoryToProgramInputFieldName
        ] = validationDateError.value;
      }

      state.addMeetingCategoryDialog.isInputValueDirty = isAnyInputValueDirty(
        state.addMeetingCategoryDialog.inputValues,
        state.addMeetingCategoryDialog.existingProgramMeetingCategory ||
          initialState.addMeetingCategoryDialog.inputValues,
      );
    },
    setMeetingCategoryToDelete(state, action: PayloadAction<{ programMeetingCategoryId: string; sourceId: string }>) {
      state.meetingCategoryToDelete = action.payload;
    },
    clearMeetingCategoryToDelete(state) {
      delete state.meetingCategoryToDelete;
    },
  },
});

export const {
  reset,
  sortByColumn,
  openAddPracticeDialog,
  openEditPracticeDialog,
  closeAddPracticeDialog,
  practiceInputValueChanged,
  setPracticeToDelete,
  clearPracticeToDelete,
  openAddMeetingCategoryDialog,
  openEditMeetingCategoryDialog,
  closeAddMeetingCategoryDialog,
  meetingCategoryInputValueChanged,
  setMeetingCategoryToDelete,
  clearMeetingCategoryToDelete,
} = slice.actions;

export const { reducer } = slice;
