import { useMeetingCategoriesList } from 'services/listItems.service';

import { BanzaiDropDown } from './BanzaiDropDown';

type Props = Readonly<{
  label?: string;
  selectedId?: string;
  errorMessage?: string | null;
  disabled?: boolean;
  onSelected: (id: string) => void;
}>;

export function SelectMeetingCategoryDropDown({ label, selectedId, errorMessage, disabled, onSelected }: Props) {
  const { isLoading, meetingCategories } = useMeetingCategoriesList();

  return (
    <BanzaiDropDown
      id='meetingCategory'
      label={label || 'Meeting Category'}
      placeholder='– Select Meeting Category –'
      items={meetingCategories}
      value={selectedId}
      disabled={disabled || isLoading || !meetingCategories?.length}
      errorMessage={errorMessage}
      onSelected={onSelected}
    />
  );
}
