import type { RouterHistory } from '@sentry/react/types/reactrouter';

import { GuideUrl, type RoutePath } from 'utils/constants/route.constant';

function goUp(): void {
  window.scrollTo(0, 0);
}

function goBack(history: RouterHistory): void {
  history.goBack();
  goUp();
}

function toRoute(history: RouterHistory, route: RoutePath): void {
  history.push(route);
  goUp();
}

function toGuide(): void {
  window.open(GuideUrl, '_blank');
}

export const navigationService = { goBack, toRoute, toGuide };
