import { useCallback } from 'react';
import { clearPracticeToDelete } from 'slices/program';
import { showError, showSuccess } from 'slices/snack';
import { useDispatch, useSelector } from 'stores';

import type { RemovePracticeFromProgramInput } from 'services/models/program.model';
import { useRemovePracticeFromProgramMutation } from 'services/roster';

import { ConfirmationDialog } from 'components/ConfirmationDialog';

export function RemovePracticeDialog() {
  const dispatch = useDispatch();
  const { practiceToDelete } = useSelector(store => ({
    practiceToDelete: store.program.practiceToDelete,
  }));

  const onSuccess = useCallback(() => {
    dispatch(clearPracticeToDelete());
    dispatch(showSuccess({ message: 'Practice Successfully Removed' }));
  }, [dispatch]);

  const onError = useCallback(() => {
    dispatch(clearPracticeToDelete());
    dispatch(showError({ message: 'An error occurred while removing this Practice.' }));
  }, [dispatch]);

  const { isLoading, removePracticeFromProgram } = useRemovePracticeFromProgramMutation({
    onSuccess,
    onError,
  });

  const onCancel = () => {
    dispatch(clearPracticeToDelete());
  };

  const onConfirm = () => {
    removePracticeFromProgram(practiceToDelete as RemovePracticeFromProgramInput);
  };

  return (
    <ConfirmationDialog
      isOpen={Boolean(practiceToDelete)}
      isDisabled={isLoading}
      message='Are you sure you want to remove this Practice?'
      handleCancel={onCancel}
      handleConfirm={onConfirm}
    />
  );
}
