import clsx from 'clsx';
import { useAuth } from 'contexts/AuthContext';
import { useState } from 'react';
import { AutoSizer, CellMeasurer, CellMeasurerCache, List, type ListRowProps, WindowScroller } from 'react-virtualized';

import { Box, Table, TableBody, TableContainer, TableHead, TableSortLabel } from '@material-ui/core';

import type { SortColumn, SortingDirection } from 'services/models/sorting.model';

import LoadingLinearProgress from 'components/LoadingLinearProgress';

import { ACTION_COLUMN_WIDTH, EXPAND_COLUMN_WIDTH, type Props as RowProps, StandardTableRow } from './StandardTableRow';
import { CustomTableCell, CustomTableRow } from './styles';
import type { RecordBase } from './types';

export * from './types';

export type Props<RT extends RecordBase, CKT extends string, SFT extends string> = Pick<
  RowProps<RT, CKT, SFT>,
  'columns' | 'getRecordActions' | 'ExpandedRecordComponent'
> &
  Readonly<{
    tableId: string;
    isLoading: boolean;
    records?: ReadonlyArray<RT>;
    emptyTableText: string;
    sorting?: Readonly<{
      activeSortColumn: SortColumn<SFT>;
      sortRecords: (sortField: SFT, defaultSortDirection?: SortingDirection) => void;
    }>;
  }>;

export function StandardTable<RT extends RecordBase, CKT extends string, SFT extends string>({
  tableId,
  isLoading,
  records,
  columns,
  emptyTableText,
  sorting,
  getRecordActions,
  ExpandedRecordComponent,
}: Props<RT, CKT, SFT>) {
  const { isOpenIframe } = useAuth();
  const [expandedRecordId, setExpandedRecordId] = useState<string | null>(null);

  const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 100,
  });

  if (isLoading) {
    return <LoadingLinearProgress />;
  }

  if (!records || !records.length) {
    return (
      <TableContainer>
        <Table>
          <TableHead>
            <CustomTableRow className='group'>
              <CustomTableCell className='bg-divider group-hover:bg-divider' />
              {columns.map(({ key, width, sortField, title, defaultSortDirection }) => (
                <CustomTableCell key={key} className='bg-divider group-hover:bg-divider' style={{ width }}>
                  {sorting && sortField ? (
                    <TableSortLabel
                      id={`${tableId}-${key}`}
                      active={sortField === sorting.activeSortColumn.field}
                      direction={
                        sortField === sorting.activeSortColumn.field
                          ? sorting.activeSortColumn.direction
                          : defaultSortDirection
                      }
                      onClick={() => {
                        sorting.sortRecords(sortField, defaultSortDirection);
                      }}>
                      {title}
                    </TableSortLabel>
                  ) : (
                    title
                  )}
                </CustomTableCell>
              ))}
              <CustomTableCell className='bg-divider text-center group-hover:bg-divider'>Actions</CustomTableCell>
            </CustomTableRow>
          </TableHead>
          <TableBody>
            <CustomTableRow>
              <CustomTableCell className='bg-gray4 text-center' colSpan={8}>
                <Box className='italic text-black4'>{emptyTableText}</Box>
              </CustomTableCell>
            </CustomTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  const renderRow = ({ index, key, style, parent }: ListRowProps) => (
    <CellMeasurer key={key} cache={cache} parent={parent} columnIndex={0} rowIndex={index}>
      {({ registerChild }) => (
        <div style={style} ref={registerChild as React.LegacyRef<HTMLDivElement>} className='w-full'>
          <StandardTableRow
            columns={columns}
            getRecordActions={getRecordActions}
            ExpandedRecordComponent={ExpandedRecordComponent}
            record={records[index]}
            isExpanded={expandedRecordId === records[index].id}
            onToggleExpanded={() => {
              setExpandedRecordId(expandedRecordId === records[index].id ? null : records[index].id);
            }}
          />
        </div>
      )}
    </CellMeasurer>
  );

  return (
    <>
      <Box
        className={clsx(
          isOpenIframe ? 'top-0' : 'top-16',
          'group sticky z-10 flex w-full bg-divider font-semibold text-black1',
        )}>
        <Box className='my-auto bg-divider p-4 group-hover:bg-divider' style={{ minWidth: EXPAND_COLUMN_WIDTH }} />
        {columns.map(({ key, title, width, sortField, defaultSortDirection }) => (
          <Box key={key} className='my-auto bg-divider p-4 group-hover:bg-divider' style={{ width }}>
            {sorting && sortField ? (
              <TableSortLabel
                id={`manage-hourly-consult-${key}`}
                active={sortField === sorting.activeSortColumn.field}
                direction={
                  sortField === sorting.activeSortColumn.field
                    ? sorting.activeSortColumn.direction
                    : defaultSortDirection
                }
                onClick={() => {
                  sorting.sortRecords(sortField, defaultSortDirection);
                }}>
                {title}
              </TableSortLabel>
            ) : (
              title
            )}
          </Box>
        ))}
        {getRecordActions && (
          <Box
            className='my-auto flex-1 bg-divider p-4 text-center group-hover:bg-divider'
            style={{ minWidth: ACTION_COLUMN_WIDTH }}>
            Actions
          </Box>
        )}
      </Box>
      {/* eslint-disable react/no-unstable-nested-components */}
      <AutoSizer disableHeight>
        {({ width }) => (
          <WindowScroller>
            {({ height, isScrolling, onChildScroll, scrollTop, registerChild }) => (
              <div ref={registerChild as React.LegacyRef<HTMLDivElement>}>
                <List
                  autoHeight
                  height={height}
                  width={width}
                  isScrolling={isScrolling}
                  onScroll={onChildScroll}
                  rowCount={records.length || 0}
                  deferredMeasurementCache={cache}
                  rowHeight={cache.rowHeight}
                  rowRenderer={renderRow}
                  scrollTop={scrollTop}
                />
              </div>
            )}
          </WindowScroller>
        )}
      </AutoSizer>
      {/* eslint-enable react/no-unstable-nested-components */}
    </>
  );
}
