import { createStyles, makeStyles } from '@material-ui/core';
import type { Theme } from '@material-ui/core';

const drawerWidth = 190;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: '#FAFCFF',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,

      [theme.breakpoints.down('sm')]: {
        width: 0,
      },
    },
    drawerPaper: {
      width: drawerWidth,

      [theme.breakpoints.down('sm')]: {
        width: 0,
      },
    },
  }),
);
