import { openCreateStaffDialog, openEditStaffDialog, setStaffToDelete } from 'slices/roster';
import { useDispatch } from 'stores';

import { Box, Grid, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/BorderColor';
import TrashIcon from '@material-ui/icons/DeleteOutline';

import { useAppPermissions } from 'services/auth.service';
import { App } from 'services/models/auth.model';
import type { PracticeInfo } from 'services/models/roster.model';

import { Button } from 'components/Button';

import { useStyles } from './styles';

type Props = {
  readonly record: PracticeInfo;
};

export function ExpandedPractice({ record }: Props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { canCreate, canDelete, canUpdate } = useAppPermissions(App.ROSTER);

  if (record.staff.length === 0) {
    return (
      <Box display='flex' alignItems='center' justifyContent='space-between' width={1}>
        <Box className='my-auto flex-1 p-4'>This practice does not have any staff members associated with it.</Box>
        <Button
          id='open-create-staff-dialog-button'
          color='primary'
          borderRadius={50}
          size='medium'
          disabled={!canCreate}
          onClick={() => {
            dispatch(openCreateStaffDialog({ practiceId: record.id }));
          }}>
          <Box display='flex' alignItems='center'>
            <AddIcon />
            <span className='ml-1'>Create New Staff</span>
          </Box>
        </Button>
      </Box>
    );
  }

  return (
    <Box className='my-auto flex-1 p-4'>
      <Grid container item direction='row'>
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          <Box display='flex' flexDirection='column' className={classes.practiceStaffSection}>
            <Box px={2} className='text-xl text-black1'>
              Staff
            </Box>
            <Box mt={2}>
              <Box display='flex' py={1.5} className='border-y-2 border-divider'>
                <Box px={2} width={0.35} className='font-semibold text-black1'>
                  Name
                </Box>
                <Box px={2} width={0.2} className='font-semibold text-black1'>
                  Job Title
                </Box>
                <Box px={2} width={0.2} className='font-semibold text-black1'>
                  Contract Role
                </Box>
                <Box px={2} width={0.1} className='text-center font-semibold text-black1'>
                  Meetings Attended
                </Box>
                <Box width={0.15} />
              </Box>
              {record.staff.map(staff => (
                <Box key={staff.id} display='flex' py={1.5} alignItems='center' className='border-b-2 border-divider'>
                  <Box px={2} width={0.35} className='break-words font-normal'>
                    {staff.displayName}
                  </Box>
                  <Box px={2} width={0.2} className='break-words font-normal'>
                    {staff.jobTitle}
                  </Box>
                  <Box px={2} width={0.2} className='break-words font-normal'>
                    {staff.contractRoleName}
                  </Box>
                  <Box px={2} width={0.1} className='break-words text-center font-normal'>
                    {staff.meetingsAttended}
                  </Box>
                  <Box width={0.15} display='flex' justifyContent='flex-end'>
                    <Box width='70px' display='flex' alignItems='center' justifyContent='center' gridGap={8}>
                      <IconButton
                        aria-label='edit-button'
                        size='small'
                        disabled={!canUpdate}
                        onClick={() => {
                          dispatch(openEditStaffDialog({ practiceId: record.id, existingStaff: staff }));
                        }}>
                        <EditIcon className='cursor-pointer text-black5' />
                      </IconButton>
                      {staff.allowedActions.delete && (
                        <IconButton
                          aria-label='delete-button'
                          size='small'
                          disabled={!canDelete}
                          onClick={() => {
                            dispatch(
                              setStaffToDelete({ staffId: staff.id, practiceId: record.id, sourceId: staff.sourceId }),
                            );
                          }}>
                          <TrashIcon className='cursor-pointer text-error' />
                        </IconButton>
                      )}
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Box display='flex' justifyContent='flex-end' alignItems='center' width={1} height={1}>
            <Button
              id='open-create-staff-dialog-button'
              color='primary'
              borderRadius={50}
              size='medium'
              disabled={!canCreate}
              onClick={() => {
                dispatch(openCreateStaffDialog({ practiceId: record.id }));
              }}>
              <Box display='flex' alignItems='center'>
                <AddIcon />
                <span className='ml-1'>Create New Staff</span>
              </Box>
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
