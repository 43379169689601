import * as Sentry from '@sentry/react';
import { Component } from 'react';

import { RoutePath } from 'utils/constants/route.constant';

import { ErrorBoundaryComponent } from './components/ErrorBoundaryComponent';

interface ErrorPageProps {
  history: any;
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
  eventId: string;
}

export default class ErrorBoundaryPage extends Component<ErrorPageProps, State> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, eventId: '' }; // eslint-disable-line react/no-unused-state
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      const eventId = Sentry.captureException(error);
      this.setState({ eventId }); // eslint-disable-line react/no-unused-state
    });
  }

  goToDashboard = () => {
    const { history } = this.props;
    this.setState(state => ({ hasError: !state.hasError }));
    if (!history) return;
    history.push(RoutePath.Default);
  };

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    return hasError ? <ErrorBoundaryComponent key='fb-error' onClick={this.goToDashboard} /> : children;
  }
}
