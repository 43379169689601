import { useCallback } from 'react';
import {
  clearExistingStaffToAdd,
  closeCreateStaffDialog,
  setExistingStaffToAdd,
  setOverrideStaffWarnings,
} from 'slices/roster';
import { showError, showSuccess } from 'slices/snack';
import { useDispatch, useSelector } from 'stores';

import type { StaffInfo } from 'services/models/roster.model';
import { ValidationSeverity } from 'services/models/validation.model';
import { StaffIssueType, useAddStaffToPracticeMutation } from 'services/roster';

import { ConfirmationDialog } from 'components/ConfirmationDialog';

import type { RelatedItemColumn } from '../ValidationErrorInfo';
import { ValidationErrorInfo } from '../ValidationErrorInfo';

const issueTypeMessages: Readonly<Record<StaffIssueType, string>> = {
  [StaffIssueType.DuplicateNpi]: 'The NPI you specified is already being used by an existing Staff record:',
  [StaffIssueType.DuplicateName]: 'The Staff Name you specified matches the one used by the following Staff members:',
  [StaffIssueType.DuplicateNameInPractice]: 'Another Staff already assigned to this practice has the same name:',
  [StaffIssueType.CapsName]:
    "YOU SPECIFIED THIS STAFF MEMBER'S NAME IN ALL CAPS. PLEASE USE NORMAL CASE FOR STAFF NAMES.",
  [StaffIssueType.NpiRequire]: 'The NPI is required for this contract role:',
  [StaffIssueType.AlreadyAssignedToThisPractice]: 'This staff member is already assigned to this practice',
};

export function StaffValidationErrorInfo() {
  const dispatch = useDispatch();
  const [apiValidationError, isOverrideWarnings, existingStaffToAdd, input] = useSelector(store => {
    const { apiValidationError, isOverrideWarnings, existingStaffToAdd, inputValues } =
      store.roster.createStaffDialogState;
    const { practiceId, contractRoleId, jobTitle } = inputValues;
    return [apiValidationError, isOverrideWarnings, existingStaffToAdd, { practiceId, contractRoleId, jobTitle }];
  });

  const onSuccess = useCallback(() => {
    dispatch(
      showSuccess({
        message: `${existingStaffToAdd?.displayName || 'The existing staff'} was added to this practice`,
      }),
    );
    dispatch(closeCreateStaffDialog());
  }, [dispatch, existingStaffToAdd]);

  const onError = useCallback(() => {
    dispatch(
      showError({
        message: `An error occurred while adding ${
          existingStaffToAdd?.displayName || 'the existing staff'
        } to this practice`,
      }),
    );
    dispatch(clearExistingStaffToAdd());
  }, [dispatch, existingStaffToAdd]);

  const { isAddStaffToPracticeInProgress, addStaffToPractice } = useAddStaffToPracticeMutation({ onSuccess, onError });

  const setOverrideWarnings = useCallback(
    (overrideWarnings: boolean) => {
      dispatch(setOverrideStaffWarnings({ overrideWarnings }));
    },
    [dispatch],
  );

  const onSelected = useCallback(
    (relatedItem: StaffInfo) => {
      dispatch(setExistingStaffToAdd({ existingStaffToAdd: relatedItem }));
    },
    [dispatch],
  );

  const onCancel = useCallback(() => {
    dispatch(clearExistingStaffToAdd());
  }, [dispatch]);

  const onConfirm = useCallback(() => {
    if (existingStaffToAdd && input.practiceId && input.contractRoleId) {
      addStaffToPractice({
        practiceId: input.practiceId,
        staffId: existingStaffToAdd.id,
        contractRoleId: input.contractRoleId,
        jobTitle: input.jobTitle,
      });
    }
  }, [existingStaffToAdd, input, addStaffToPractice]);

  if (apiValidationError) {
    const canSelectRelatedItem =
      apiValidationError.relatedItems?.length &&
      apiValidationError.severity === ValidationSeverity.Warning &&
      apiValidationError.type === StaffIssueType.DuplicateName;

    const relatedItemColumns: RelatedItemColumn<StaffInfo>[] = [
      { type: 'field', heading: 'Display Name', field: 'displayName', width: canSelectRelatedItem ? 4 : 5 },
      { type: 'field', heading: 'Full Name', field: 'fullName', width: canSelectRelatedItem ? 4 : 5 },
      { type: 'field', heading: 'NPI', field: 'npi', width: 2 },
      ...(canSelectRelatedItem
        ? [{ type: 'select' as const, heading: 'Action', buttonText: 'Add', width: 2 as const, onSelected }]
        : []),
    ];

    return (
      <>
        <ValidationErrorInfo
          severity={apiValidationError.severity}
          message={issueTypeMessages[apiValidationError.type]}
          relatedItems={apiValidationError.relatedItems}
          isOverrideWarnings={isOverrideWarnings}
          relatedItemColumns={relatedItemColumns}
          setOverrideWarnings={setOverrideWarnings}
        />
        <ConfirmationDialog
          isOpen={Boolean(existingStaffToAdd)}
          isDisabled={isAddStaffToPracticeInProgress}
          message='The existing staff member will be added to this practice'
          handleCancel={onCancel}
          handleConfirm={onConfirm}
        />
      </>
    );
  }

  return null;
}
