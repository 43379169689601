import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useCountdown } from 'hooks/useCountdown';
import { useEffect } from 'react';

import { Box, Dialog, DialogContent } from '@material-ui/core';

import { ReactComponent as TimerIcon } from 'assets/svgs/Timer.svg';

import { Button } from 'components/Button';

type Props = Readonly<{
  isOpen: boolean;
  isDisabled?: boolean;
  message: string;
  handleCancel: () => void;
  handleExtendSession: () => void;
  handleSignOut: () => void;
}>;

const CountdownTimer = ({ targetDate, handleSignOut }: { targetDate: number; handleSignOut: () => void }) => {
  const { inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const seconds = useCountdown(targetDate);

  useEffect(() => {
    if (seconds === 0 && inProgress === InteractionStatus.None && isAuthenticated) {
      handleSignOut();
    }
  }, [seconds, handleSignOut, isAuthenticated, inProgress]);

  return (
    <Box className='text-2xl font-normal text-black1'>
      Your login session will automatically end in <span className='text-4xl font-bold text-error'>{seconds}</span>{' '}
      seconds due to inactivity,
      <br />
      unless you choose to stay logged in.
    </Box>
  );
};

export function CountDownDialog({ isOpen, message, handleCancel, handleExtendSession, handleSignOut }: Props) {
  return (
    <Dialog
      open={isOpen}
      maxWidth='lg'
      onClose={(e, reason: string) => {
        reason !== 'backdropClick' && handleCancel();
      }}
      aria-labelledby='delete-dialog-title'
      aria-describedby='delete-dialog-description'>
      <DialogContent className='w-[908px]'>
        <Box my={6} className='flex w-full justify-center'>
          <TimerIcon />
        </Box>
        <Box className='w-full text-center'>
          <Box className='text-3xl font-semibold text-black1'>{message}</Box>
        </Box>
        <Box mt={6} mb={8} className='w-full text-center'>
          <CountdownTimer
            targetDate={59}
            handleSignOut={() => {
              handleSignOut();
              handleCancel();
            }}
          />
        </Box>
        <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
          <Box my={2} width={256}>
            <Button
              id='dialog-close-button'
              data-cy='stayLoginButton'
              onClick={() => handleExtendSession()}
              color='primary'
              size='medium'
              fullWidth
              borderRadius={50}>
              Stay Logged in
            </Button>
          </Box>
          <Box width={180}>
            <Button
              id='delete-entry-button'
              data-cy='logoutButton'
              size='medium'
              color='gray'
              fullWidth
              onClick={() => {
                handleSignOut();
                handleCancel();
              }}
              borderRadius={50}>
              Log out
            </Button>
          </Box>
        </Box>
        <Box mt={5} mb={3} className='w-full text-center'>
          <Box className='text-2xl font-normal text-black1'>
            For security, user login sessions automatically expire
            <br /> after 15 minutes of inactivity.
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
