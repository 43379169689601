import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { AppRoleInput } from 'services/models/roleAndPermissions.model';
import { UserRoleColumnKey } from 'services/models/roleAndPermissions.model';
import type { SortColumn } from 'services/models/sorting.model';
import { SortingDirection } from 'services/models/sorting.model';

type RoleAndPermissionsState = Readonly<{
  activeSortColumn: SortColumn<UserRoleColumnKey>;
  createRoleDialog: AddRoleDialogState;
  roleToDelete?: Readonly<AppRoleInput>;
}>;

type AppRoleInputFieldName = keyof AppRoleInput;

// Helper type to solve readonly issues in draft state
type Writeable<T> = { -readonly [P in keyof T]: Writeable<T[P]> };

type AddRoleDialogState = Readonly<{
  isOpen: boolean;
  inputValues: Readonly<{
    [Field in AppRoleInputFieldName]?: string;
  }>;
  inputValidationErrors: Readonly<{
    [Field in AppRoleInputFieldName]?: string | null;
  }>;
}>;

const initialState: RoleAndPermissionsState = {
  activeSortColumn: { field: UserRoleColumnKey.firstName, direction: SortingDirection.Desc },
  createRoleDialog: {
    isOpen: false,
    inputValues: {},
    inputValidationErrors: {},
  },
};

const slice = createSlice({
  name: 'roleAndPermissions',
  initialState,
  reducers: {
    sortByColumn(state, action: PayloadAction<{ key: UserRoleColumnKey; defaultSortDirection?: SortingDirection }>) {
      state.activeSortColumn = {
        field: action.payload.key,
        direction:
          action.payload.key === state.activeSortColumn.field
            ? state.activeSortColumn.direction === SortingDirection.Asc
              ? SortingDirection.Desc
              : SortingDirection.Asc
            : action.payload.defaultSortDirection || SortingDirection.Asc,
      };
    },
    openAddRoleDialog(state, action: PayloadAction<{ userId: string }>) {
      const { userId } = action.payload;
      state.createRoleDialog.inputValues = {
        userId,
      };
      state.createRoleDialog.isOpen = true;
    },
    closeAddRoleDialog(state) {
      state.createRoleDialog = initialState.createRoleDialog as Writeable<AddRoleDialogState>;
    },
    AddRoleInputValueChanged(state, action: PayloadAction<{ field: AppRoleInputFieldName; value: string }>) {
      const { field, value } = action.payload;

      state.createRoleDialog.inputValues[field] = value || undefined;
    },
    setRoleToDelete(state, action: PayloadAction<AppRoleInput>) {
      state.roleToDelete = action.payload;
    },
    clearRoleToDelete(state) {
      delete state.roleToDelete;
    },
  },
});

export const { reducer } = slice;

export const {
  sortByColumn,
  openAddRoleDialog,
  closeAddRoleDialog,
  AddRoleInputValueChanged,
  setRoleToDelete,
  clearRoleToDelete,
} = slice.actions;

export default slice.reducer;
