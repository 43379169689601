import clsx from 'clsx';

import { Box, Grid } from '@material-ui/core';

import type { MeetingModel } from 'services/models/meetingTimeEntry.model';

import { useStyles } from '../PracticeCollaborationTrackerPage.styles';

type Props = Readonly<{
  record: MeetingModel;
}>;

export function ExpandedMeeting({ record }: Props) {
  const classes = useStyles();
  const isDeleted = record.deleted;

  return (
    <Box className='my-auto flex-1 p-4'>
      <Grid container item direction='row'>
        <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
          <Box display='flex' flexDirection='column' className={classes.attendeeSetion}>
            <Box px={2} className='text-xl text-black1'>
              Attendees
            </Box>
            <Box mt={2}>
              <Box display='flex' py={1.5} className='border-y-2 border-divider'>
                <Box px={2} width={0.3} className='font-semibold text-black1'>
                  Attendee
                </Box>
                <Box px={2} width={0.25} className='font-semibold text-black1'>
                  Job Title
                </Box>
                <Box px={2} width={0.25} className='font-semibold text-black1'>
                  Contract Role
                </Box>
                <Box px={2} width={0.2} className='font-semibold text-black1'>
                  Duration
                </Box>
              </Box>
              {record.meetingAttendees.length > 0 &&
                record.meetingAttendees.map(attendee => (
                  <Box
                    key={attendee.id}
                    display='flex'
                    py={1.5}
                    alignItems='center'
                    className='border-b-2 border-divider'>
                    <Box
                      px={2}
                      width={0.3}
                      className={clsx('break-words font-normal', isDeleted && 'italic line-through')}>
                      {attendee.displayName}
                    </Box>
                    <Box
                      px={2}
                      width={0.25}
                      className={clsx('break-words font-normal', isDeleted && 'italic line-through')}>
                      {attendee.jobTitle}
                    </Box>
                    <Box
                      px={2}
                      width={0.25}
                      className={clsx('break-words font-normal', isDeleted && 'italic line-through')}>
                      {attendee.contractRole.name}
                    </Box>
                    <Box
                      px={2}
                      width={0.2}
                      className={clsx('break-words font-normal', isDeleted && 'italic line-through')}>
                      {attendee.minutes} minutes
                    </Box>
                  </Box>
                ))}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
          <Box display='flex' flexDirection='column' height={1}>
            <Box className='text-xl text-black1'>Internal Notes</Box>
            <Box mt={2} p={2} className='border border-bgColor2 bg-white' flex={1}>
              <Box className='whitespace-pre-line font-normal text-black1'>{record.description}</Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
