import { closeCreateDialog } from 'slices/practiceCollaboration';
import { useDispatch, useSelector } from 'stores';

import { useFacade } from './PracticeCollaborationTrackerPage.hooks';
import { CreateAndUpdateTimeEntryDialog } from './components/CreateAndUpdateTimeEntryDialog';

export function CreateNewTimeEntryForSF() {
  const dispatch = useDispatch();

  const { isLoading, isCreateDialogOpen, selectedId } = useSelector(state => state.practiceCollaboration);

  const [handleAddConsult] = useFacade();

  return (
    <CreateAndUpdateTimeEntryDialog
      isOpen={isCreateDialogOpen}
      isLoading={isLoading}
      editMeetingId={selectedId}
      handleCreate={handleAddConsult}
      handleClose={() => {
        dispatch(closeCreateDialog());
      }}
    />
  );
}
