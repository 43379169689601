import { useAuth } from 'contexts/AuthContext';
import { useMemo, useState } from 'react';
import { closeSelectPracticeDialog, updateSearchKey } from 'slices/practiceCollaboration';
import { useDispatch, useSelector } from 'stores';

import { Box, Dialog, DialogContent } from '@material-ui/core';

import type { PracticeModel, UpdateHourlyConsultModel } from 'services/models/meetingTimeEntry.model';

import { PickerTable } from 'components/BanzaiTable/PickerTable';
import { Button } from 'components/Button';
import { SearchComponent } from 'components/SearchComponent';

import { PRACTICE_COLUMN_DEFINITIONS } from './practiceColumnDefinitions';

interface SelectPracticeDialogProps {
  params: UpdateHourlyConsultModel;
  practices?: ReadonlyArray<PracticeModel>;
  handleToggleConfirm: (id: string) => void;
  handleSelectPractice: (field: string, value: any) => void;
}

export function SelectPracticeDialog(props: SelectPracticeDialogProps) {
  const { isOpenCreateDialog } = useAuth();
  const dispatch = useDispatch();
  const { isSelectPracticeDialogOpen, searchKey } = useSelector(state => state.practiceCollaboration);
  const { params, practices, handleToggleConfirm, handleSelectPractice } = props;
  const [practiceId, setPracticeId] = useState<string>('');

  const filteredPractices = useMemo(() => {
    if (searchKey) {
      const trimedSearch = decodeURIComponent(searchKey).trim().toLowerCase();
      const arrayTrimSearchKey = trimedSearch.split(' ');

      const filtered = practices?.filter(
        p =>
          arrayTrimSearchKey.find(item => p.legalBusinessName?.toLowerCase().includes(item)) !== undefined ||
          arrayTrimSearchKey.find(item => p.tin?.toLowerCase().includes(item)) !== undefined,
      );
      return filtered;
    }
    return practices;
  }, [practices, searchKey]);

  const handleCancel = () => {
    dispatch(closeSelectPracticeDialog());
    setPracticeId('');
  };

  const handleSelect = () => {
    if (params.practiceId) {
      handleToggleConfirm(practiceId);
    } else {
      handleSelectPractice('practiceId', practiceId);
    }
    handleCancel();
  };

  const emptyText = searchKey ? 'No practices match the search key' : 'No practices currently exist in our system';

  return (
    <Dialog
      maxWidth='md'
      fullWidth
      fullScreen={isOpenCreateDialog}
      open={isSelectPracticeDialogOpen}
      onClose={(e, reason: string) => {
        reason !== 'backdropClick' && handleCancel();
      }}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'>
      <DialogContent className='p-3 sm:p-8'>
        <Box>
          <span className='text-3xl font-semibold text-black'>Create Time Entry</span>
        </Box>
        <Box className='mt-4 text-2xl font-normal text-black sm:mt-10'>Select a Practice</Box>
        <Box className='mt-4 sm:mt-10 md:flex' alignItems='center' justifyContent='space-between'>
          <Box flex={1} mr={2} width={1}>
            <SearchComponent
              placeholder='Type Practice name or TIN to filter list'
              data-cy='practiceSearchBox'
              defaultValue={searchKey}
              handleSearch={value => {
                dispatch(updateSearchKey({ keyword: value }));
              }}
              handleChanges={value => {
                dispatch(updateSearchKey({ keyword: value }));
              }}
            />
          </Box>
          <Box className='mt-2 md:mt-0' display='flex' alignItems='center' justifyContent='flex-end'>
            <Box mx={2}>
              <Button
                id='dialog-close-button'
                data-cy='selectPracticeDialogCloseButton'
                onClick={() => {
                  dispatch(closeSelectPracticeDialog());
                }}
                color='tertiary'
                size='medium'
                borderRadius={50}>
                Cancel
              </Button>
            </Box>
            <Box>
              <Button
                id='select-practice-button'
                size='medium'
                onClick={handleSelect}
                borderRadius={50}
                disabled={!practiceId}
                data-cy='selectPracticeButton'>
                Select Practice
              </Button>
            </Box>
          </Box>
        </Box>
        <Box className='mt-4 sm:mt-10' mb={3}>
          <PickerTable
            columns={PRACTICE_COLUMN_DEFINITIONS}
            practiceId={practiceId}
            params={params}
            records={filteredPractices}
            emptyText={emptyText}
            handleSelect={setPracticeId}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
