import { practiceInputValueChanged } from 'slices/program';
import { useDispatch, useSelector } from 'stores';

import { Box, FormControl, FormHelperText } from '@material-ui/core';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { KeyboardDatePicker } from '@material-ui/pickers';

import type { PracticeToProgramInput } from 'services/models/program.model';

import { SelectPracticeDropDown } from 'components/dropdowns/SelectPracticeDropDown';

import { useStyles } from '../styles';

type FieldName = keyof Omit<PracticeToProgramInput, 'overrideWarnings'>;

type Props = Readonly<{
  field: FieldName;
  disabled: boolean;
}>;

const LabelForField: Readonly<Record<Exclude<FieldName, 'practiceId'>, string>> = {
  startDate: 'Start Date',
  endDate: 'End Date',
};

export function InputFieldRow({ field, disabled }: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { value, errorMessage } = useSelector(store => ({
    value: store.program.addPracticeDialog.inputValues[field],
    errorMessage: store.program.addPracticeDialog.inputValidationErrors[field],
  }));

  if (field === 'practiceId') {
    return (
      <SelectPracticeDropDown
        selectedId={value}
        errorMessage={errorMessage}
        disabled={disabled}
        onSelected={value => {
          dispatch(practiceInputValueChanged({ field, value }));
        }}
      />
    );
  }

  const placeholder = field === 'startDate' ? 'required' : undefined;

  return (
    <Box mt={3}>
      <Box className='text-base font-semibold text-black1' mb={0.5}>
        {LabelForField[field]}
      </Box>
      <FormControl variant='outlined' size='medium' fullWidth classes={{ root: classes.root }}>
        <KeyboardDatePicker
          disableToolbar
          variant='inline'
          fullWidth
          size='small'
          inputVariant='outlined'
          format='MM/DD/yyyy'
          placeholder={placeholder || 'MM/DD/YYYY'}
          id={field}
          error={Boolean(errorMessage)}
          helperText={null}
          value={value || null}
          disabled={disabled}
          onChange={momentDate => {
            dispatch(
              practiceInputValueChanged({ field, value: momentDate === null ? '' : momentDate.format('YYYY-MM-DD') }),
            );
          }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
            edge: 'end',
          }}
          keyboardIcon={<CalendarTodayIcon />}
        />
        <FormHelperText error>{errorMessage}</FormHelperText>
      </FormControl>
    </Box>
  );
}
