import clsx from 'clsx';
import React from 'react';

import { CircularProgress, Button as MuiButton } from '@material-ui/core';

import { useFacade } from './Button.hooks';
import { useStyles } from './Button.styles';

interface ButtonProps {
  id: string;
  onClick?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  children?: any;
  style?: React.CSSProperties;
  size?: 'default' | 'large' | 'medium' | 'small';
  color?: 'primary' | 'secondary' | 'tertiary' | 'danger' | 'white' | 'black' | 'gray';
  width?: number | string;
  autoFocus?: boolean;
  disabled?: boolean;
  border?: 'none';
  borderRadius?: number;
  timeout?: number;
  type?: any;
  fullWidth?: boolean;
  loading?: boolean;
}

export function Button(props: ButtonProps) {
  const {
    onClick,
    style,
    children,
    timeout = 250,
    size = 'default',
    color = 'primary',
    width = 'auto',
    border = '',
    borderRadius = 8,
    disabled = false,
    fullWidth = false,
    loading = false,
    ...buttonProps
  } = props;
  const classes = useStyles();

  const [canExecute, handleOnClick] = useFacade(timeout, onClick);

  return (
    <MuiButton
      className={clsx(classes.root, {
        [classes.none]: border === 'none',
        [classes.primary]: color === 'primary',
        [classes.secondary]: color === 'secondary',
        [classes.tertiary]: color === 'tertiary',
        [classes.danger]: color === 'danger',
        [classes.white]: color === 'white',
        [classes.black]: color === 'black',
        [classes.gray]: color === 'gray',
        [classes.default]: size === 'default',
        [classes.large]: size === 'large',
        [classes.medium]: size === 'medium',
        [classes.small]: size === 'small',
      })}
      disabled={disabled || !canExecute || loading}
      onClick={handleOnClick}
      style={{
        borderRadius,
        width: fullWidth ? '100%' : width,
        ...style,
      }}
      fullWidth={fullWidth}
      {...buttonProps}>
      {loading ? <CircularProgress size={24} className='text-main' /> : children}
    </MuiButton>
  );
}
