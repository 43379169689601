import * as Sentry from '@sentry/react';
import { useCallback } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'stores';

import type {
  PracticeCreateInput,
  PracticeDeleteInput,
  PracticeEditInput,
  PracticeFilterInput,
  PracticeInfo,
  PracticeSortField,
} from 'services/models/roster.model';
import type { SortApiInput } from 'services/models/sorting.model';
import type { ValidationError } from 'services/models/validation.model';

import API from 'utils/helpers/axiosInstance';

import type { GenericMutationCallbacks, GenericMutationCallbacksWithValidation } from './rosterServiceUtils';
import { asGenericValidationError } from './rosterServiceUtils';

export enum PracticeIssueType {
  DuplicateTin = 'DuplicateTin',
  DuplicateLegalBusinessName = 'DuplicateLegalBusinessName',
}
export type PracticeValidationError = ValidationError<PracticeIssueType, PracticeInfo>;

type MutationCallbacks = GenericMutationCallbacks<PracticeInfo>;
type MutationCallbacksWithValidation = GenericMutationCallbacksWithValidation<PracticeIssueType, PracticeInfo>;
const asValidationError = asGenericValidationError<PracticeIssueType, PracticeInfo>;

async function getPracticesApi(
  filter?: PracticeFilterInput,
  sort?: SortApiInput<PracticeSortField>,
): Promise<ReadonlyArray<PracticeInfo>> {
  const queryStringComponents = [];
  if (filter) {
    queryStringComponents.push(`filter=${encodeURIComponent(JSON.stringify(filter))}`);
  }
  if (sort) {
    queryStringComponents.push(`sort=${encodeURIComponent(JSON.stringify(sort))}`);
  }
  const queryString = queryStringComponents.length ? `?${queryStringComponents.join('&')}` : '';
  const result = await API.get<ReadonlyArray<PracticeInfo>>(`roster/practices${queryString}`);
  return result.data;
}

async function createPracticeApi(input: PracticeCreateInput): Promise<PracticeInfo> {
  const result = await API.post<PracticeInfo>('roster/practice', input);
  return result.data;
}

async function editPracticeApi(input: PracticeEditInput): Promise<PracticeInfo> {
  const { id, ...body } = input;
  const result = await API.put<PracticeInfo>(`roster/practice/${id}`, body);
  return result.data;
}

async function deletePracticeApi(input: PracticeDeleteInput): Promise<PracticeInfo> {
  const { id, ...data } = input;
  const result = await API.delete<PracticeInfo>(`roster/practice/${id}`, { data });
  return result.data;
}

export function usePractices() {
  const [activeSortColumn, practiceFilters] = useSelector(store => [
    store.roster.activeSortColumn,
    store.roster.practiceFilters,
  ]);
  const {
    isLoading,
    isRefetching,
    data: practices,
  } = useQuery(['practices', activeSortColumn, practiceFilters], () => {
    const sort = [activeSortColumn];
    return getPracticesApi(practiceFilters, sort);
  });
  return { isLoading: isLoading || isRefetching, practices };
}

export function useCreatePracticeMutation({ onSuccess, onError, onValidationError }: MutationCallbacksWithValidation) {
  const queryClient = useQueryClient();
  const { isLoading: isCreatePracticeInProgress, mutate } = useMutation(createPracticeApi);
  const createPractice = useCallback(
    (input: PracticeCreateInput) => {
      mutate(input, {
        onSuccess: practice => {
          queryClient.invalidateQueries('practices'); // Trigger refetch
          queryClient.invalidateQueries('list/practices');
          onSuccess(practice);
        },
        onError: (error: unknown) => {
          Sentry.captureException(error);
          const validationError = asValidationError(error);
          if (validationError) {
            onValidationError(validationError);
          } else {
            onError(error);
          }
        },
      });
    },
    [mutate, queryClient, onSuccess, onError, onValidationError],
  );

  return { isCreatePracticeInProgress, createPractice };
}

export function useEditPracticeMutation({ onSuccess, onError, onValidationError }: MutationCallbacksWithValidation) {
  const queryClient = useQueryClient();
  const { isLoading: isEditPracticeInProgress, mutate } = useMutation(editPracticeApi);
  const editPractice = useCallback(
    (input: PracticeEditInput) => {
      mutate(input, {
        onSuccess: practice => {
          queryClient.invalidateQueries('practices'); // Trigger refetch
          queryClient.invalidateQueries('list/practices');
          onSuccess(practice);
        },
        onError: (error: unknown) => {
          Sentry.captureException(error);
          const validationError = asValidationError(error);
          if (validationError) {
            onValidationError(validationError);
          } else {
            onError(error);
          }
        },
      });
    },
    [mutate, queryClient, onSuccess, onError, onValidationError],
  );

  return { isEditPracticeInProgress, editPractice };
}

export function useDeletePracticeMutation({ onSuccess, onError }: MutationCallbacks) {
  const queryClient = useQueryClient();
  const { isLoading: isDeletePracticeInProgress, mutate } = useMutation(deletePracticeApi);
  const deletePractice = useCallback(
    (input: PracticeDeleteInput) => {
      mutate(input, {
        onSuccess: practice => {
          queryClient.invalidateQueries('practices'); // Trigger refetch
          queryClient.invalidateQueries('list/practices');
          onSuccess(practice);
        },
        onError: (error: unknown) => {
          Sentry.captureException(error);
          onError(error);
        },
      });
    },
    [mutate, queryClient, onSuccess, onError],
  );
  return { isDeletePracticeInProgress, deletePractice };
}
