import clsx from 'clsx';
import { useAuth } from 'contexts/AuthContext';
import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Statsig } from 'statsig-react';

import { AppBar, Avatar, Box, Button, CircularProgress, CssBaseline, Drawer, Menu, MenuItem } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import CategoryIcon from '@material-ui/icons/Category';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import GroupIcon from '@material-ui/icons/Group';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import HelpIcon from '@material-ui/icons/Help';
import HighlightIcon from '@material-ui/icons/Highlight';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';

import LogoPNG from 'assets/images/Logo.png';
import { ReactComponent as LogoutIcon } from 'assets/svgs/LogoutIcon.svg';

import { App } from 'services/models/auth.model';
import { navigationService } from 'services/navigation/navigation.service';

import LayoutComponent from 'components/LayoutComponent';

import { RoutePath } from 'utils/constants/route.constant';

import { AppNavLink } from './AppNavLink';
import { useFacade } from './AuthenticatedLayoutComponent.hooks';
import { useStyles } from './AuthenticatedLayoutComponent.styles';

interface LayoutComponentProps {
  title: string;
  children: React.ReactNode;
}

export function AuthenticatedLayoutComponent(props: LayoutComponentProps) {
  const { title, children } = props;
  const { user, avatar, isOpenIframe, handleSignOut } = useAuth();
  const history = useHistory();

  const [state, handleMenuOpen, handleMenuClose] = useFacade();

  const classes = useStyles();

  const showFeedbackDialog = useCallback(() => {
    window.location.href = `mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`;
  }, []);

  const renderAvatar = () => {
    if (user) {
      return (
        <>
          <Avatar className='bg-black5 text-2xl font-bold uppercase text-white'>
            {avatar ? (
              <img src={avatar} alt='avatar' />
            ) : (
              `${user.firstName?.charAt(0).toUpperCase()}${user.lastName?.charAt(0).toUpperCase()}`
            )}
          </Avatar>
          <Box className='font-medium capitalize text-black1' ml={4} mr={2}>
            {`${user.firstName} ${user.lastName}`}
          </Box>
          {state.anchorEl ? <ExpandLess /> : <ExpandMore />}
        </>
      );
    }
    return null;
  };

  useEffect(() => {
    const hasLogged = localStorage.getItem('hasLogged');

    if (!hasLogged && user) {
      Statsig.logEvent('User authenticated', user?.email, {
        email: user?.email || '',
        firstName: user?.firstName || '',
        lastName: user?.lastName || '',
      });
      localStorage.setItem('hasLogged', 'true');
    }
  }, [user]);

  return (
    <LayoutComponent title={title}>
      <div className='flex'>
        <CssBaseline />
        <AppBar position='fixed' color='inherit' className={clsx(classes.appBar, isOpenIframe && 'hidden')}>
          <Toolbar>
            <Box
              className='grow'
              onClick={() => {
                navigationService.toRoute(history, RoutePath.PracticeCollaboration);
              }}>
              <img className='w-24 cursor-pointer md:w-36' src={LogoPNG} alt='logo img' />
            </Box>
            <Box className='flex grow justify-end'>
              <Button
                aria-label='account of current user'
                aria-controls='menu-appbar'
                aria-haspopup='true'
                data-cy='profileButton'
                onClick={handleMenuOpen}>
                {!user ? <CircularProgress size={24} className='text-main' /> : renderAvatar()}
              </Button>
              <Menu
                id='menu-appbar'
                data-cy='profileMenu'
                anchorEl={state.anchorEl}
                keepMounted
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                open={Boolean(state.anchorEl)}
                onClose={handleMenuClose}>
                <MenuItem data-cy='logoutButton' onClick={handleSignOut} className='justify-left flex'>
                  <Box className='px-6 py-2 text-lg font-medium text-black'>
                    <Box display='flex' alignItems='center'>
                      <LogoutIcon />
                      <span className='ml-2.5'>Logout</span>
                    </Box>
                  </Box>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer
          className={clsx(classes.drawer, isOpenIframe && 'hidden')}
          variant='permanent'
          classes={{
            paper: classes.drawerPaper,
          }}>
          <Toolbar />
          <div className='h-full overflow-auto bg-black5 pl-6 pr-2 pt-28 text-white'>
            <AppNavLink
              routePath={RoutePath.PracticeCollaboration}
              label='Practice Collaboration Tracker'
              app={App.PCT}
              Icon={GroupWorkIcon}
            />
            <AppNavLink
              routePath={RoutePath.Roster}
              label='Roster Management'
              app={App.ROSTER}
              Icon={BeachAccessIcon}
            />
            <AppNavLink routePath={RoutePath.Programs} label='Programs' app={App.PROGRAMS} Icon={HighlightIcon} />
            <AppNavLink
              routePath={RoutePath.MeetingCategories}
              label='Meeting Categories'
              app={App.MEETING_CATEGORIES}
              Icon={CategoryIcon}
            />
            <AppNavLink routePath={RoutePath.Users} label='Users' app={App.USERS} Icon={GroupIcon} />
            <Box
              display='flex'
              alignItems='center'
              className='cursor-pointer leading-8'
              onClick={() => {
                navigationService.toGuide();
              }}>
              <LibraryBooksIcon />
              <Box ml={1}>User Guide</Box>
            </Box>
            <Box
              display='flex'
              alignItems='center'
              mt={1}
              className='cursor-pointer leading-8'
              onClick={showFeedbackDialog}>
              <HelpIcon />
              <Box ml={1}>Need Help?</Box>
            </Box>
          </div>
        </Drawer>
        <main className='grow'>
          <Toolbar className={clsx(isOpenIframe && 'hidden')} />
          {children}
        </main>
      </div>
    </LayoutComponent>
  );
}
