import type { ChangeEvent } from 'react';
import { staffInputValueChanged } from 'slices/roster';
import { useDispatch, useSelector } from 'stores';

import { Box, FormControl, FormHelperText, TextField } from '@material-ui/core';

import type { StaffCreateInput } from 'services/models/roster.model';

import { SelectContractRoleDropDown } from 'components/dropdowns/SelectContractRoleDropDown';

import { useStyles } from '../styles';

type FieldName = keyof Omit<StaffCreateInput, 'overrideWarnings'>;

type Props = Readonly<{
  field: FieldName;
  disabled: boolean;
}>;

const LabelForField: Readonly<Record<Exclude<FieldName, 'contractRoleId'>, string>> = {
  practiceId: 'Practice Id',
  fullName: 'Full Name',
  displayName: 'Display Name',
  firstName: 'First Name',
  middleName: 'Middle Name',
  lastName: 'Last Name',
  prefix: 'Prefix',
  suffix: 'Suffix',
  npi: 'Npi',
  jobTitle: 'Job Title',
};

export function InputFieldRow({ field, disabled }: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { value, errorMessage } = useSelector(store => ({
    value: store.roster.createStaffDialogState.inputValues[field],
    errorMessage: store.roster.createStaffDialogState.inputValidationErrors[field],
  }));

  const onChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    dispatch(staffInputValueChanged({ field, value: event.target.value }));
  };

  if (field === 'contractRoleId') {
    return (
      <SelectContractRoleDropDown
        selectedId={value}
        errorMessage={errorMessage}
        disabled={disabled}
        onSelected={value => {
          dispatch(staffInputValueChanged({ field, value }));
        }}
      />
    );
  }

  const placeholder = field === 'firstName' || field === 'lastName' || field === 'jobTitle' ? 'required' : undefined;

  return (
    <Box mt={3}>
      <Box className='text-base font-semibold text-black1' mb={0.5}>
        {LabelForField[field]}
      </Box>
      <FormControl variant='outlined' size='medium' fullWidth classes={{ root: classes.root }}>
        <TextField
          id={field}
          fullWidth
          size='medium'
          InputProps={{ className: 'input' }}
          variant='outlined'
          disabled={disabled}
          placeholder={placeholder || ''}
          value={value || ''}
          error={Boolean(errorMessage)}
          onChange={onChange}
        />
        <FormHelperText error>{errorMessage}</FormHelperText>
      </FormControl>
    </Box>
  );
}
