import { sortByColumn } from 'slices/roleAndPermission';
import { useDispatch, useSelector } from 'stores';

import { COLUMN_DEFINITIONS } from 'services/roleAndPermission/columnDefinitions';
import { useUsersWithRolesAndPermissionsList } from 'services/roleAndPermission/roleAndPersmssion.service';

import { AuthenticatedLayoutComponent } from 'components/AuthenticatedLayoutComponent';
import { BanzaiTable } from 'components/BanzaiTable';

import { TitlesConstants } from 'utils/constants/title.constant';

import { AddRoleDialog } from './AddRoleDialog';
import { DeleteRoleDialog } from './DeleteRoleDialog';
import { ExpandedRoleAndPermissions } from './ExpandedRoleAndPermissions';

export function RoleAndPermissionsPage() {
  const dispatch = useDispatch();
  const { activeSortColumn } = useSelector(store => ({
    activeSortColumn: store.roleAndPermissions.activeSortColumn, // Adjust to your store slice
  }));
  const { isLoading, users } = useUsersWithRolesAndPermissionsList();
  return (
    <AuthenticatedLayoutComponent title={TitlesConstants.roleAndPermissions}>
      <BanzaiTable
        tableId='roleAndPermissions'
        pageHeading='Role and Permissions'
        tableHeading='Users'
        isLoading={isLoading}
        records={users}
        columns={COLUMN_DEFINITIONS}
        sorting={{
          activeSortColumn,
          sortRecords: (key, defaultSortDirection) => {
            dispatch(sortByColumn({ key, defaultSortDirection }));
          },
        }}
        ExpandedRecordComponent={ExpandedRoleAndPermissions}
        emptyTableText='No users found'
        pluralRecordsText='users'
      />
      <AddRoleDialog />
      <DeleteRoleDialog />
    </AuthenticatedLayoutComponent>
  );
}

export default RoleAndPermissionsPage;
