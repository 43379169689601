import { Fragment } from 'react';
import type { FC } from 'react';

import { Button, Grid } from '@material-ui/core';

import Logo from 'assets/images/Logo.png';

import { TitlesConstants } from 'utils/constants/title.constant';

import { useStyles } from './ErrorBoundaryComponent.styles';

interface ErrorBoundaryComponentProps {
  onClick: () => void;
}

export const ErrorBoundaryComponent: FC<ErrorBoundaryComponentProps> = (props: ErrorBoundaryComponentProps) => {
  const { onClick } = props;

  const classes = useStyles();
  const email = 'support@vytalize.com';
  return (
    <>
      <title>{TitlesConstants.errorTitle}</title>
      <Grid
        className={classes.container}
        container
        direction='column'
        justify='center'
        alignItems='center'
        wrap='nowrap'
        spacing={2}>
        <Grid>
          <img src={Logo} alt='logo' className={classes.logo} />
        </Grid>
        <Grid className={classes.titleGrid}>
          <div className={classes.title}>We’re having connection issues.</div>
        </Grid>
        <Grid>
          <div className={classes.description}>
            We’re looking into it right now. Please try again later. You can contact support at{' '}
            <a id='error-boundary-support-email' href={`mailto:${email}`}>
              {email}
            </a>
          </div>
        </Grid>
        <Grid className={classes.gridButton}>
          <Button className={classes.button} variant='text' id='back-button' onClick={onClick}>
            Back to Dashboard
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
