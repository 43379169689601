import { MeetingCategoryColumnKey, type MeetingCategoryFullModel } from 'services/models/meetingTimeEntry.model';

import type { CellContents, ColumnDefinition } from 'components/BanzaiTable';

function extractColumnValueForSource(source: MeetingCategoryFullModel['created']): CellContents {
  return {
    primary: { text: new Date(source.timestamp).toLocaleString() },
    secondary: {
      text: source.user ? `by ${source.user.firstName} ${source.user.lastName}` : 'non-user update',
    },
  };
}

export const COLUMN_DEFINITIONS: ReadonlyArray<
  ColumnDefinition<MeetingCategoryColumnKey, MeetingCategoryColumnKey, MeetingCategoryFullModel>
> = [
  {
    key: MeetingCategoryColumnKey.ID,
    title: 'ID',
    width: '15%',
    extractCellContents: record => ({ primary: { text: record.id } }),
  },
  {
    key: MeetingCategoryColumnKey.Name,
    title: 'Name',
    width: '45%',
    extractCellContents: record => ({
      primary: { text: record.name },
      secondary: { text: record.description || '' },
    }),
  },
  {
    key: MeetingCategoryColumnKey.MeetingsCount,
    title: 'Meetings',
    width: '10%',
    extractCellContents: record => ({ primary: { text: record.meetingsCount.toString() } }),
  },
  {
    key: MeetingCategoryColumnKey.CreatedDate,
    title: 'Created',
    width: '15%',
    extractCellContents: record => extractColumnValueForSource(record.created),
  },
  {
    key: MeetingCategoryColumnKey.UpdatedDate,
    title: 'Updated',
    width: '15%',
    extractCellContents: record =>
      record.created.timestamp !== record.updated.timestamp
        ? extractColumnValueForSource(record.updated)
        : { primary: { text: 'N/A' } },
  },
];
