import { useCallback } from 'react';
import { clearMeetingCategoryToDelete } from 'slices/program';
import { showError, showSuccess } from 'slices/snack';
import { useDispatch, useSelector } from 'stores';

import type { RemoveMeetingCategoryFromProgramInput } from 'services/models/program.model';
import { useRemoveMeetingCategoryFromProgramMutation } from 'services/roster';

import { ConfirmationDialog } from 'components/ConfirmationDialog';

export function RemoveMeetingCategoryDialog() {
  const dispatch = useDispatch();
  const { meetingCategoryToDelete } = useSelector(store => ({
    meetingCategoryToDelete: store.program.meetingCategoryToDelete,
  }));

  const onSuccess = useCallback(() => {
    dispatch(clearMeetingCategoryToDelete());
    dispatch(showSuccess({ message: 'Meeting Category Successfully Removed' }));
  }, [dispatch]);

  const onError = useCallback(() => {
    dispatch(clearMeetingCategoryToDelete());
    dispatch(showError({ message: 'An error occurred while removing this Meeting Category.' }));
  }, [dispatch]);

  const { isLoading, removeMeetingCategoryFromProgram } = useRemoveMeetingCategoryFromProgramMutation({
    onSuccess,
    onError,
  });

  const onCancel = () => {
    dispatch(clearMeetingCategoryToDelete());
  };

  const onConfirm = () => {
    removeMeetingCategoryFromProgram(meetingCategoryToDelete as RemoveMeetingCategoryFromProgramInput);
  };

  return (
    <ConfirmationDialog
      isOpen={Boolean(meetingCategoryToDelete)}
      isDisabled={isLoading}
      message='Are you sure you want to remove this Meeting Category?'
      handleCancel={onCancel}
      handleConfirm={onConfirm}
    />
  );
}
