import moment from 'moment';

import type { StaffModel } from 'services/models/meetingTimeEntry.model';

import { getMinDate } from 'utils/helpers/displayDate';
import {
  dateValidationRule,
  functionValidationRule,
  futureTimeValidationRule,
  maxDateValidationRule,
  maxLengthValidationRule,
  maxNumberValidationRule,
  minDateValidationRule,
  minNumberValidationRule,
  notNegativeValidationRule,
  numberValidationRule,
  requiredValidationRule,
} from 'utils/validation/general-validation-rules';
import { GeneralValidator } from 'utils/validation/general-validator';

export const createPracticeMeetingTimeEntryValidator = new GeneralValidator({
  meetingCategoryId: [requiredValidationRule()],
  otherCategory: [maxLengthValidationRule(50)],
  meetingDate: [
    dateValidationRule('Meeting Date is invalid'),
    requiredValidationRule(),
    maxDateValidationRule(moment().add(1, 'days').format('YYYY-MM-DD'), 'Meeting Date cannot be in the future'),
    minDateValidationRule(
      getMinDate(),
      `You cannot select a meeting date that is more than ${moment().diff(getMinDate(), 'days')} days from today`,
    ),
  ],
  startTime: [requiredValidationRule(), futureTimeValidationRule(true, 'Start time cannot be in the future.')],
  endTime: [requiredValidationRule(), futureTimeValidationRule(true, 'End time cannot be in the future.')],
  practiceId: [requiredValidationRule()],
  duration: [
    minNumberValidationRule(1),
    maxNumberValidationRule(1440, 'Duration cannot exceed 1,440 minutes'),
    notNegativeValidationRule(),
    numberValidationRule('Duration should be integer.'),
    requiredValidationRule(),
  ],
  attendees: [
    functionValidationRule((value: StaffModel[]) => Boolean(value && value.length), 'Attendee should be selected.'),
  ],
  note: [maxLengthValidationRule(256)],
});
